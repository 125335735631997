import { createAsyncThunk } from '@reduxjs/toolkit';
import promptGroupService from '../../../api/services/promptGroup';

export const fetchPromptGroups = createAsyncThunk('prompt-group/fetchPromptGroups', async ({ page, search }) => {
  return await promptGroupService.fetchPromptGroups(page, search);
});

export const editPromptGroup = createAsyncThunk('prompt-group/editPromptGroup', async requestedData => {
  const { promptGroupId, name, prompt } = requestedData;
  return await promptGroupService.editPromptGroup(promptGroupId, name, prompt);
});

export const createPromptGroup = createAsyncThunk('prompt-group/createPromptGroup', async requestedData => {
  const { name, prompt } = requestedData;
  return await promptGroupService.createPromptGroup(name, prompt);
});

export const deletePromptGroup = createAsyncThunk('prompt-group/deletePromptGroup', async requestedData => {
  const { promptGroupId } = requestedData;
  return await promptGroupService.deletePromptGroup(promptGroupId);
});
