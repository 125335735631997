import styled from 'styled-components';
import { Text16Bold } from '../../components/utils/texts/text.styles';
import { MainButton } from '../../components/utils/buttons/Button/Button';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

export const Title = styled(Text16Bold)`
  font-size: 2.8rem;
  margin-left: 1rem;
`;

export const Container = styled.div`
  margin-left: 1rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Content = styled.div`
  flex-grow: 1;
  width: 42rem;

  padding-top: 2.4rem;
  display: flex;
  flex-direction: column;
`;

export const Button = styled(MainButton)`
  width: 42rem;
`;
