import styled from 'styled-components';
import { Text14Regular } from '../../utils/texts/text.styles';

export const Container = styled.div`
  padding: 1.6rem 0;
  height: calc(100% - 22rem);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.2rem;
  margin-bottom: 1.6rem;
`;

export const RowItem = styled.div`
  padding: 1.2rem 0.8rem 1.2rem 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaecf1;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 4.8rem);
`;

export const Spinner = styled.img`
  width: 20rem;
  height: 20rem;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: flex-end;
  margin-right: 1.2rem;
  margin-top: 1.2rem;
`;

export const ListContainer = styled.div`
  overflow: auto;
  height: calc(100% - 7.6rem);
`;

export const LoaderMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfiniteScrollMarker = styled.div`
  height: 1rem;
`;

export const InputWrapper = styled.div`
  padding-inline: 1.2rem;
`;

export const AddNewFactButton = styled.button`
  border-radius: 10px;
  border: none;
  background: #f4edff;
  padding: 0.8rem 1.2rem;
  width: 15rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const ButtonText = styled(Text14Regular)`
  color: #5f2ccb;
  letter-spacing: -0.14px;
`;

export const ActionContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

export const ActionButton = styled.button`
  border-radius: 12px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: inherit;
  height: 3.2rem;
  width: 3.2rem;
  background: #f7f7f7;
`;
