import React from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { selectCreateAgencyPending } from '../../../store/slices/agency/slice';
import { createAgency } from '../../../store/slices/agency/asyncThunks';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { FormInput } from '../../../forms/form.styles';

export const createAgencyFormSchema = yup.object({ name: yup.string().trim().required('Name is required') }).required();

const CreateAgencyFormModal = ({ modalRef, onSuccess }) => {
  const createAgencyPending = useSelector(selectCreateAgencyPending);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
    delayError: 300,
    resolver: yupResolver(createAgencyFormSchema),
  });

  const onSubmit = data => {
    dispatch(createAgency(data))
      .unwrap()
      .then(_ => {
        reset();
        modalRef.current.hide();
        notifyInfo('Invitation code created successfully!');
        onSuccess();
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <FormModal
      ref={modalRef}
      title="Create Agency"
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText="Submit"
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={createAgencyPending}
      shouldCloseOnOverlayClick={false}>
      <form>
        <FormInput
          label="Agency Name"
          placeholder="Enter agency name"
          {...register('name')}
          error={errors.name?.message}
        />
      </form>
    </FormModal>
  );
};

export default CreateAgencyFormModal;
