import { createAsyncThunk } from '@reduxjs/toolkit';
import interactionService from '../../../api/services/interaction';

export const fetchInteractions = createAsyncThunk(
  'influencer/fetchInteractions',
  async ({ userInfluencerId, page, direction }, { getState }) => {
    const userInfluencer = getState().userInfo.selectedUserInfluencerInfo.userInfluencer;
    const interactions = await interactionService.fetchInteractions(userInfluencerId, page);
    return { userInfluencer, interactions: { ...interactions, page, direction } };
  },
);

export const searchActivityInChat = createAsyncThunk(
  'influencer/searchActivityInChat',
  async ({ userInfluencerId, page, totalPages }, { getState }) => {
    const userInfluencer = getState().userInfo.selectedUserInfluencerInfo.userInfluencer;
    const interactions = await interactionService.searchActivityInChat(userInfluencerId, page, totalPages);
    const { topPage, bottomPage, ...interactionsData } = interactions;
    return { userInfluencer, interactions: { ...interactionsData, page }, topPage, bottomPage };
  },
);
