import styled from 'styled-components';
import { Text16Bold } from '../../../components/utils/texts/text.styles';
import Input from '../../../components/utils/inputs/Input/Input';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
  margin-bottom: 2rem;
`;

export const Title = styled(Text16Bold)`
  font-size: 2.8rem;
  margin-left: 1rem;
`;

export const SearchInput = styled(Input)`
  width: 100%;
  max-width: 30rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
`;

export const SwitchPageButton = styled.button`
  min-height: 4.8rem;
  width: fit-content;
  border-radius: 12px;
  border: 1px solid rgba(73, 67, 93, 0.2);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(14, 24, 41, 0.05);
  min-width: 9rem;
  padding: 0.8rem 1.2rem;
  gap: 0.8rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  flex-wrap: wrap;
`;

export const Switch = styled.div`
  border-radius: 100px;
  background: #e9d8ff;
  display: flex;
  width: 3.6rem;
  padding: 2px;
  align-items: center;
  height: 2rem;
`;

export const Circle = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.8rem;
  background-color: #fff;
`;
