import { createSlice } from '@reduxjs/toolkit';
import { fetchUserInfluencersBuilder } from './builder';
import { LOGOUT } from '../../../utils/constants';

const INIT_STATE = {
  fetchUserInfluencersInfo: {
    pending: false,
    data: [],
    total: 0,
  },
};

export const userInfluencerSlice = createSlice({
  name: 'userInfluencer',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    fetchUserInfluencersBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectFetchUserInfluencersInfo = state => state.userInfluencerInfo.fetchUserInfluencersInfo;

export default userInfluencerSlice.reducer;
