import React, { useEffect, useState } from 'react';
import { FlexMain } from '../../../../components/utils/utils.styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectSendInstantMassDMPending } from '../../../../store/slices/influencer/slice';
import {
  Container,
  InstantDMContainer,
  StyledButton,
  StyledInput,
  Title,
  StyledNumberInput,
} from './InfluencerMassDmTab.styles';
import { useConfirmationModal } from '../../../../components/utils/modals/ConfirmationModal/ConfirmationModal';
import { notifyError, notifyInfo } from '../../../../utils/notify';
import { sendInstantMassDM } from '../../../../store/slices/influencer/asyncThunks';
import Select from '../../../../components/utils/selects/Select/Select';
import { MASS_DM_TYPE } from '../../../../utils/constants';

const MASS_DM_TYPE_TITLE = {
  [MASS_DM_TYPE.TEXT]: 'Text',
  [MASS_DM_TYPE.FREE_PHOTO]: 'Free photo',
  [MASS_DM_TYPE.PREMIUM_PHOTO]: 'Premium photo',
  [MASS_DM_TYPE.VIDEO]: 'Video',
  [MASS_DM_TYPE.BUNDLE]: 'Bundle',
};

const InfluencerMassDmTab = () => {
  const sendInstantMassDMPending = useSelector(selectSendInstantMassDMPending);

  const [openModal, closeModal, setConfirmationModalLoader] = useConfirmationModal();
  const [massDMType, setMassDMType] = useState({
    value: MASS_DM_TYPE.TEXT,
    label: MASS_DM_TYPE_TITLE[MASS_DM_TYPE.TEXT],
  });
  const [instantMessage, setInstantMessage] = useState('');
  const [fromAgo, setFromAgo] = useState(47);
  const [untilAgo, setUntilAgo] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    setConfirmationModalLoader(sendInstantMassDMPending);
    // eslint-disable-next-line
  }, [sendInstantMassDMPending]);

  const validateNumber = value => {
    const parsed = parseInt(value, 10);
    if (!isNaN(parsed) && parsed >= 1 && parsed <= 720) {
      return parsed;
    }
    return null;
  };

  const onSendInstantMassDM = () => {
    openModal(
      'Send Instant Mass DM',
      `Are you sure you want to send instant mass DM?`,
      'Submit',
      () => {
        dispatch(sendInstantMassDM({ massDMType: massDMType.value, text: instantMessage, fromAgo, untilAgo }))
          .unwrap()
          .then(() => {
            notifyInfo('Mass DM sent successfully!');
            closeModal();
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Close',
      () => {
        closeModal();
      },
    );
  };

  return (
    <FlexMain>
      <InstantDMContainer>
        <Title>Instant Mass DM</Title>

        <Container>
          <Select
            label="Album Media Type"
            selectedOption={massDMType}
            handleChange={setMassDMType}
            options={Object.values(MASS_DM_TYPE).map(type => ({
              value: type,
              label: MASS_DM_TYPE_TITLE[type],
            }))}
          />

          {massDMType.value === MASS_DM_TYPE.TEXT && (
            <StyledInput
              label="Message"
              placeholder="Enter Message"
              type="textarea"
              onChange={e => setInstantMessage(e.target.value)}
              value={instantMessage}
            />
          )}

          <StyledNumberInput
            label="From Ago (hours)"
            type="number"
            placeholder="From Ago (hours)"
            value={fromAgo}
            onChange={e => {
              const validValue = validateNumber(e.target.value);
              if (validValue !== null) setFromAgo(validValue);
            }}
          />

          <StyledNumberInput
            label="Until Ago (hours)"
            type="number"
            placeholder="Until Ago (hours)"
            value={untilAgo}
            onChange={e => {
              const validValue = validateNumber(e.target.value);
              if (validValue !== null) setUntilAgo(validValue);
            }}
          />
        </Container>

        <StyledButton title="Send Message" onClick={onSendInstantMassDM} />
      </InstantDMContainer>
    </FlexMain>
  );
};

export default InfluencerMassDmTab;
