import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/a/agencies';

class AgencyService {
  fetchAgencies = async (page, search) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching agencies.');
    }
  };

  fetchAgencyById = async agencyId => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${agencyId}`);
      throwErrorIfWrongStatus(response, 200);
      const { agency } = response.data.data;
      return { agency };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching agency.');
    }
  };

  createAgency = async name => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}`, { name });
      throwErrorIfWrongStatus(response, 201);
    } catch (err) {
      throw handleError(err, 'Error occurred while creating agency.');
    }
  };

  deleteAgency = async agencyId => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${agencyId}`);
      throwErrorIfWrongStatus(response, 200);
      return { agencyId };
    } catch (err) {
      throw handleError(err, 'Error occurred while deleting agency.');
    }
  };

  createAgencyMember = async (agencyId, name, email, password) => {
    try {
      const body = {
        agencyId,
        name,
        email,
        password,
      };
      const response = await axiosApiClient.post(`${BASE_URL}/agency-member`, body);
      throwErrorIfWrongStatus(response, 201);
      const { agencyMember } = response.data.data;
      return { agencyMember };
    } catch (err) {
      throw handleError(err, 'Error occurred while creating agency member.');
    }
  };

  deleteAgencyMember = async agencyMemberId => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/agency-member/${agencyMemberId}`);
      throwErrorIfWrongStatus(response, 200);
      return { agencyMemberId };
    } catch (err) {
      throw handleError(err, 'Error occurred while deleting agency member.');
    }
  };

  fetchAllInfluencers = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/influencers/all`);
      throwErrorIfWrongStatus(response, 200);
      const { influencers } = response.data.data;
      return { influencers };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencers.');
    }
  };

  addInfluencerToAgency = async (agencyId, influencerIds) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/${agencyId}/influencer`, { influencerIds });
      throwErrorIfWrongStatus(response, 200);
      const { influencers } = response.data.data;
      return { influencers };
    } catch (err) {
      throw handleError(err, 'Error occurred while adding influencer to agency.');
    }
  };

  deleteInfluencerFromAgency = async (agencyId, influencerId) => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${agencyId}/influencer/${influencerId}`);
      throwErrorIfWrongStatus(response, 200);
      return { influencerId };
    } catch (err) {
      throw handleError(err, 'Error occurred while deleting influencer from agency.');
    }
  };
}

const agencyService = new AgencyService();
export default agencyService;
