import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/a/interactions';

class InteractionService {
  fetchInteractions = async (userInfluencerId, page) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${userInfluencerId}?page=${page}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencers.');
    }
  };

  searchActivityInChat = async (userInfluencerId, page, totalPages) => {
    try {
      const data = [];
      let topPage = page;
      let bottomPage = page;

      if (page >= 1 && page < totalPages) {
        const nextPageResponse = await axiosApiClient.get(`${BASE_URL}/${userInfluencerId}?page=${page + 1}`);
        throwErrorIfWrongStatus(nextPageResponse, 200);
        const { data: nextData } = nextPageResponse.data.data;
        data.push(...nextData);
        topPage = page + 1;
      }

      const response = await axiosApiClient.get(`${BASE_URL}/${userInfluencerId}?page=${page}`);
      throwErrorIfWrongStatus(response, 200);
      const { data: currentData } = response.data.data;
      data.push(...currentData);

      if (page <= totalPages) {
        const previousPageResponse = await axiosApiClient.get(`${BASE_URL}/${userInfluencerId}?page=${page - 1}`);
        throwErrorIfWrongStatus(previousPageResponse, 200);
        const { data: previousData } = previousPageResponse.data.data;
        data.push(...previousData);
        bottomPage = page - 1;
      }

      const { total } = response.data.data;
      return { data, total, topPage, bottomPage };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencers.');
    }
  };
}

const interactionService = new InteractionService();
export default interactionService;
