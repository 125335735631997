import { MEDIA_TYPE } from '../../../../utils/constants';
import { CostOverlay } from '../UserInfluencerDetailsPage.styles';
import { BundleContainer, BundleItem, BundlePhoto, BundleVideo } from './BundleDisplay.styles';

export const BundleDisplay = ({ id, media, cost }) => {
  const rnd = 2 + Math.round(Math.random() * (media.length - 2));
  media = media.slice(0, rnd);
  const columns = media.length >= 6 ? 3 : 2;
  const rows = Math.ceil(media.length / columns);
  return (
    <BundleContainer $columns={columns} rows={rows} id={id}>
      {media.map(({ url, type }, index) => (
        <BundleItem key={index}>
          {type === MEDIA_TYPE.PHOTO ? (
            <BundlePhoto src={url} alt={`Bundle media ${index + 1}`} />
          ) : (
            <BundleVideo src={url} alt={`Bundle media ${index + 1}`} controls />
          )}
          {index === 0 && cost && <CostOverlay>{cost} ⭐️</CostOverlay>}
        </BundleItem>
      ))}
    </BundleContainer>
  );
};
