import React, { forwardRef } from 'react';
import DatePickerInput from 'react-datepicker';
import ErrorContainer from '../../ErrorContainer/ErrorContainer';
import { StyledLabel, StyledLabelRow } from './DatePicker.styles';
import { Text16Regular } from '../../texts/text.styles';

const DatePicker = forwardRef(
  (
    {
      value,
      onChange,
      error,
      hideErrorMessage,
      label,
      className,
      disabled,
      placeholder,
      dateFormat = 'dd/MM/yyyy',
      ...inputProps
    },
    ref,
  ) => {
    return (
      <StyledLabel disabled={disabled} className={className} $showTimeSelect={inputProps.showTimeSelect}>
        {label && <StyledLabelRow>{label && <Text16Regular>{label}</Text16Regular>}</StyledLabelRow>}
        <DatePickerInput
          ref={ref}
          wrapperClassName="datePicker"
          selected={value}
          onChange={onChange}
          dateFormat={dateFormat}
          disabled={disabled}
          placeholderText={placeholder}
          shouldCloseOnSelect
          {...inputProps}
        />
        {!hideErrorMessage && <ErrorContainer errorText={error} />}
      </StyledLabel>
    );
  },
);

export default DatePicker;
