import React from 'react';
import {
  Container,
  Content,
  EditButton,
  InfluencerInfoContainer,
  InfluencerText,
  LeftSide,
  NameContainer,
  RightSide,
  Title,
} from './InfluencerFactsPage.styles';
import { Text20Bold } from '../../components/utils/texts/text.styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InfluencerFactRequestsList from '../../components/facts/InfluencerFactRequestsList/InfluencerFactRequestsList';
import ExistingFactsList from '../../components/facts/ExistingFactsList/ExistingFactsList';

const InfluencerFactsPage = () => {
  const { id: influencerId } = useParams();
  const { state } = useLocation();
  const influencerName = state?.influencerName;
  const navigate = useNavigate();

  return (
    <Container>
      <Title>Facts</Title>
      <InfluencerInfoContainer>
        <NameContainer>
          <InfluencerText>Influencer</InfluencerText>
          <Text20Bold>{influencerName}</Text20Bold>
        </NameContainer>
        <EditButton onClick={() => navigate('/facts')}>Edit</EditButton>
      </InfluencerInfoContainer>
      <Content>
        <LeftSide>
          <InfluencerFactRequestsList influencerId={influencerId} />
        </LeftSide>
        <RightSide>
          <ExistingFactsList influencerId={influencerId} />
        </RightSide>
      </Content>
    </Container>
  );
};

export default InfluencerFactsPage;
