import styled from 'styled-components';
import { Text16Light } from '../../utils/texts/text.styles';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const Label = styled(Text16Light)`
  letter-spacing: -0.42px;
  opacity: 0.7;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  button {
    width: 15rem;
  }
`;
