import styled from 'styled-components';
import { MainButton } from '../../utils/buttons/Button/Button';
import { Text12Bold } from '../../utils/texts/text.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const RowItem = styled.div`
  padding: 1.2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #eaecf1;
`;

export const ActionContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

export const AcceptButton = styled.button`
  border-radius: 12px;
  border: 1px solid #843efe;
  background: linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #843efe;
  box-shadow: 0px 2px 2px 0px rgba(132, 62, 254, 0.23);
  padding: 0.5rem;
  display: flex;
  color: white;
  cursor: inherit;
`;

export const TestButton = styled.button`
  border-radius: 12px;
  border: 1px solid #843efe;
  background: #f7f7f7;
  box-shadow: 0px 2px 2px 0px rgba(132, 62, 254, 0.23);
  padding: 0.5rem;
  display: flex;
  color: white;
  cursor: inherit
`;

export const AcceptAllButton = styled(MainButton)`
  padding: 0.5rem 1.2rem;
  color: white;
  cursor: pointer;
  height: 3.6rem;
  width: 10.4rem;
  font-size: 1.4rem;
  border-radius: 12px;
  border: 1px solid #843efe;
  background: linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #843efe;
  box-shadow: 0px 2px 2px 0px rgba(132, 62, 254, 0.23);
`;

export const DeclineButton = styled(AcceptButton)`
  border: 1px solid #ffd2d3;
  background: #ffebec;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const LoaderMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Spinner = styled.img`
  width: 20rem;
  height: 20rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.2rem;
  margin-bottom: 1.6rem;
`;

export const ListContainer = styled.div`
  overflow: auto;
  height: 100%;
`;

export const RowContent = styled.div``;

export const TextLabel = styled(Text12Bold)`
  opacity: 0.5;
  margin-bottom: 0.4rem;
`;

export const Gap = styled.div`
  height: 1.2rem;
`;

export const InfiniteScrollMarker = styled.div`
  height: 1rem;
`;

export const InputWrapper = styled.div`
  padding-inline: 1.2rem;
`;
