import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import Table, { ORDER } from '../../../components/Table/Table';
import {
  AppliedFilterContainer,
  Circle,
  FilterButton,
  FilterContainer,
  Header,
  IconWrapper,
  SearchInput,
  Switch,
  Title,
  TitleContainer,
} from './UserInfluencersPage.styles';
import { selectFetchUserInfluencersInfo } from '../../../store/slices/userInfluencer/slice';
import UserInfluencerTableItem from '../../../components/Table/items/UserInfluencerTableItem/UserInfluencerTableItem';
import { fetchUserInfluencers } from '../../../store/slices/userInfluencer/asyncThunks';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter-lines.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Text12Light, Text14Regular } from '../../../components/utils/texts/text.styles';
import UserInfluencerFiltersModal, {
  FILTER_TITLES,
} from '../../../components/modals/UserInfluencerFiltersModal/UserInfluencerFiltersModal';
import localStorageService from '../../../services/localStorageService';
import {
  USER_INFLUENCERS_QUERY_LAST_N_DAYS_FILTER_KEYS,
  USER_INFLUENCERS_QUERY_RANGE_FILTER_KEYS,
} from '../../../utils/constants';

const UserInfluencersPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get('page') || 1;
  const search = searchParams.get('search');

  const [searchValue, setSearchValue] = useState(() => search ?? '');
  const [sortOptions, setSortOptions] = useState({
    sortBy: '',
    order: ORDER.ASC,
  });
  const [filters, setFilters] = useState(JSON.parse(localStorageService.getItem('userInfluencersFilter') || '{}'));

  const { data, total, pending } = useSelector(selectFetchUserInfluencersInfo);
  const modalRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tableHeader = [
    { label: 'Name' },
    { label: 'Influencer' },
    { label: 'Total spent', sortable: true, key: 'totalSpent' },
    { label: 'Total messages', sortable: true, key: 'totalMessages' },
    { label: 'Created At', sortable: true, key: 'createdAt' },
  ];

  const changeHandler = useCallback(
    text => {
      searchParams.set('search', text);
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  const setPage = page => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (page > 0) {
      let newFilters = {};

      Object.entries(filters).forEach(([key, value]) => {
        if ((value.min || value.max) && USER_INFLUENCERS_QUERY_RANGE_FILTER_KEYS.includes(key)) {
          newFilters[key] = { min: value.min || 0, max: value.max || Number.MAX_SAFE_INTEGER };
        } else if (value && USER_INFLUENCERS_QUERY_LAST_N_DAYS_FILTER_KEYS.includes(key)) {
          newFilters[key] = +value;
        }
      });

      dispatch(
        fetchUserInfluencers({
          page,
          search: search ?? '',
          sortBy: sortOptions.sortBy,
          order: sortOptions.order,
          filters: newFilters,
        }),
      );
    }
  }, [dispatch, page, search, sortOptions, filters]);

  return (
    <>
      <Header>
        <TitleContainer>
          <Title>User Influencers</Title>
          <SearchInput
            value={searchValue}
            onChange={event => {
              setSearchValue(event.target.value);
              debouncedChangeHandler(event.target.value);
            }}
            placeholder="Search"
            hideErrorMessage
          />
        </TitleContainer>
        <FilterContainer>
          <FilterButton onClick={() => navigate('/users')}>
            <Text14Regular>Influencers</Text14Regular>
            <Switch>
              <Circle />
            </Switch>
          </FilterButton>
          <FilterButton onClick={() => modalRef.current.show()}>
            <FilterIcon />
            {Object.keys(filters).length > 0 ? (
              Object.entries(filters).map(([key, value]) => {
                return (
                  <AppliedFilterContainer key={key}>
                    <Text12Light>
                      {USER_INFLUENCERS_QUERY_RANGE_FILTER_KEYS.includes(key)
                        ? `${FILTER_TITLES[key]}: ${value.min || '0'} - ${value.max || '*'}`
                        : `${FILTER_TITLES[key]}: ${value}`}
                    </Text12Light>
                    <IconWrapper
                      onClick={e => {
                        const newFilters = { ...filters };
                        delete newFilters[key];
                        setFilters(newFilters);
                        localStorageService.setItem('userInfluencersFilter', newFilters);
                        e.stopPropagation();
                      }}>
                      <CloseIcon width={16} height={16} />
                    </IconWrapper>
                  </AppliedFilterContainer>
                );
              })
            ) : (
              <Text14Regular>Filters</Text14Regular>
            )}
          </FilterButton>
        </FilterContainer>
      </Header>
      <Table
        headerInformation={tableHeader}
        dataLoading={pending}
        totalCount={total}
        currentPage={page}
        perPage={10}
        setPage={setPage}
        emptyDataText={'No data'}
        sortOptions={sortOptions}
        setSortOptions={setSortOptions}
        fullHeight>
        {data.map(userInfluencer => (
          <UserInfluencerTableItem
            key={userInfluencer._id}
            userName={userInfluencer.userName}
            influencerName={userInfluencer.influencerName}
            influencerProfilePhoto={userInfluencer.influencerProfilePhoto}
            totalMessages={userInfluencer.totalMessages}
            totalSpent={userInfluencer.totalSpent}
            createdAt={userInfluencer.createdAt}
            onDetailsClicked={() => {
              navigate(`/users/${userInfluencer.userId}/influencer/${userInfluencer._id}`);
            }}
          />
        ))}
      </Table>
      <UserInfluencerFiltersModal modalRef={modalRef} setFilters={setFilters} filters={filters} />
    </>
  );
};

export default UserInfluencersPage;
