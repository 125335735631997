import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { fetchUserById } from '../../../store/slices/user/asyncThunks';
import { selectSelectedUserInfo, setSelectedUser } from '../../../store/slices/user/slice';
import {
  Container,
  UserInfoSection,
  InfluencersList,
  InfluencerItem,
  InfluencerPhoto,
  InfluencerName,
  InfluencerSpent,
  InfluencerLeft,
} from './UserDetailsPage.styles';
import MainLoader from '../../../components/utils/loaders/MainLoader/MainLoader';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';

function UserDetailsPage() {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user: selectedUser, pending: userPending } = useSelector(selectSelectedUserInfo);

  useEffect(() => {
    dispatch(fetchUserById(userId));
    return () => {
      dispatch(setSelectedUser(null));
    };
  }, [dispatch, userId]);

  const handleInfluencerClick = userInfluencer => {
    navigate(`/users/${userId}/influencer/${userInfluencer.id}`);
  };

  if (userPending || !selectedUser) {
    return (
      <Container>
        <MainLoader />
      </Container>
    );
  }
  return (
    <Container>
      <UserInfoSection>
        <h2>{selectedUser.name}</h2>
        <p>
          Total Spent: $
          {`${selectedUser.totalSpent} ⭐️ = $${(selectedUser.totalSpent * VALUE_OF_ONE_STAR_IN_USD).toFixed(1)} `}
        </p>
        <InfluencersList>
          {selectedUser.userInfluencers.map(userInfluencer => (
            <InfluencerItem key={userInfluencer.id} onClick={() => handleInfluencerClick(userInfluencer)}>
              <InfluencerLeft>
                <InfluencerPhoto src={userInfluencer.influencer.profilePhoto} alt={userInfluencer.influencer.name} />
                <InfluencerName>{userInfluencer.influencer.name}</InfluencerName>
              </InfluencerLeft>
              <InfluencerSpent>{`${userInfluencer.balance.totalSpent} ⭐️`}</InfluencerSpent>
            </InfluencerItem>
          ))}
        </InfluencersList>
      </UserInfoSection>
      <Outlet />
    </Container>
  );
}

export default UserDetailsPage;
