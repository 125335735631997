import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import {
  fetchInfluencerBundleAlbumPhotos,
  fetchInfluencerBundleAlbumVideos,
} from '../../../store/slices/influencer/asyncThunks';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFetchInfluencerBundleAlbumPhotosInfo,
  selectFetchInfluencerBundleAlbumVideosInfo,
} from '../../../store/slices/influencer/slice';
import {
  LoaderContainer,
  MediaCardContainer,
  ModalContainer,
  PhotosContainer,
  PhotosContent,
  Ribbon,
  Spinner,
  VideosContainer,
  VideosContent,
  TabContainer,
  TabButton,
  InfiniteScrollMarker,
} from './SelectMediaModal.styles';
import { MEDIA_TYPE } from '../../../utils/constants';
import Image from '../../utils/Image/Image';
import Video from '../../utils/Video/Video';
import SpinnerGIF from '../../../assets/images/spinner.gif';

const SelectMediaModal = ({ modalRef, onSelect, chosenMedia }) => {
  const ALBUM_MEDIA_PAGE_SIZE = 10;
  const dispatch = useDispatch();
  const {
    data: photosData,
    pending: photosPending,
    total: photosTotal,
  } = useSelector(selectFetchInfluencerBundleAlbumPhotosInfo);
  const {
    data: videosData,
    pending: videosPending,
    total: videosTotal,
  } = useSelector(selectFetchInfluencerBundleAlbumVideosInfo);

  const [isOpen, setIsOpen] = useState(false);
  const [photosPage, setPhotosPage] = useState(1);
  const [videosPage, setVideosPage] = useState(1);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [activeTab, setActiveTab] = useState(MEDIA_TYPE.PHOTO);

  const loadMorePhotosRef = useRef(null);
  const loadMoreVideosRef = useRef(null);

  useEffect(() => {
    dispatch(fetchInfluencerBundleAlbumPhotos({ page: photosPage }))
  }, [photosPage, dispatch]);

  useEffect(() => {
    dispatch(fetchInfluencerBundleAlbumVideos({ page: videosPage }))
  }, [videosPage, dispatch]);

  const [photos, videos] = useMemo(() => {
    if (photosData && videosData) {
      const filteredPhotos = photosData.filter(photo => !chosenMedia.some(chosen => chosen.id === photo.id));
      const filteredVideos = videosData.filter(video => !chosenMedia.some(chosen => chosen.id === video.id));
      return [filteredPhotos, filteredVideos];
    }
    return [[], []];
  }, [videosData, photosData, chosenMedia]);

  const handleIntersectionPhotos = useCallback(
    entries => {
      if (!isOpen) return;
      if (activeTab !== MEDIA_TYPE.PHOTO) return;
      const [entry] = entries;
      if (
        entry.isIntersecting &&
        !photosPending &&
        photosTotal > photosPage * ALBUM_MEDIA_PAGE_SIZE
      ) {
        setPhotosPage(prev => prev + 1);
      }
    },
    [photosPending, photosTotal, photosPage, isOpen, activeTab],
  );

  const handleIntersectionVideos = useCallback(
    entries => {
      if (!isOpen) return;
      if (activeTab !== MEDIA_TYPE.VIDEO) return;
      const [entry] = entries;
      if (
        entry.isIntersecting &&
        !videosPending &&
        videosTotal > videosPage * ALBUM_MEDIA_PAGE_SIZE
      ) {
        setVideosPage(prev => prev + 1);
      }
    },
    [videosPending, videosTotal, videosPage, isOpen, activeTab],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersectionPhotos, { root: null, threshold: 1.0 });
    const target = loadMorePhotosRef.current;
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [handleIntersectionPhotos]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersectionVideos, { root: null, threshold: 1.0 });
    const target = loadMoreVideosRef.current;
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [handleIntersectionVideos]);

  const onMediaClick = (media, type) => {
    const foundMedia = selectedMedia.find(selected => selected.id === media.id);
    if (foundMedia) {
      setSelectedMedia(selectedMedia.filter(selected => selected.id !== media.id));
    } else {
      setSelectedMedia([
        ...selectedMedia,
        {
          id: media.id,
          thumbnailUrl: media.thumbnailUrl ? media.thumbnailUrl : null,
          url: media.url,
          type,
        },
      ]);
    }
  };

  return (
    <FormModal
      ref={modalRef}
      title="Select Media"
      onClose={() => {
        modalRef.current.hide();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText="Select"
      onRightButtonClick={() => {
        onSelect(selectedMedia);

        modalRef.current.hide();
      }}
      onAfterClose={() => {
        setSelectedMedia([]);
        setIsOpen(false);
      }}
      onAfterOpen={() => setIsOpen(true)}
      increasedWidth
      rightButtonDisabled={selectedMedia.length === 0}
      shouldCloseOnOverlayClick={false}>
      <ModalContainer>
        <TabContainer>
          <TabButton $active={activeTab === MEDIA_TYPE.PHOTO} onClick={() => setActiveTab(MEDIA_TYPE.PHOTO)}>
            Photos
          </TabButton>
          <TabButton $active={activeTab === MEDIA_TYPE.VIDEO} onClick={() => setActiveTab(MEDIA_TYPE.VIDEO)}>
            Videos
          </TabButton>
        </TabContainer>

        {activeTab === MEDIA_TYPE.PHOTO && (
          <PhotosContainer>
            <PhotosContent>
              {photos.map(photo => (
                <MediaCard
                  key={photo.id}
                  item={photo}
                  isInBundle={photo.isInBundle}
                  type={MEDIA_TYPE.PHOTO}
                  onClick={() => onMediaClick(photo, MEDIA_TYPE.PHOTO)}
                  selected={selectedMedia.some(selected => selected.id === photo.id)}
                />
              ))}
            </PhotosContent>

            {photosPending && (
              <LoaderContainer>
                <Spinner src={SpinnerGIF} />
              </LoaderContainer>
            )}
            <InfiniteScrollMarker ref={loadMorePhotosRef} />
          </PhotosContainer>
        )}

        {activeTab === MEDIA_TYPE.VIDEO && (
          <VideosContainer>
            <VideosContent>
              {videos.map(video => (
                <MediaCard
                  key={video.id}
                  item={video}
                  isInBundle={video.isInBundle}
                  type={MEDIA_TYPE.VIDEO}
                  onClick={() => onMediaClick(video, MEDIA_TYPE.VIDEO)}
                  selected={selectedMedia.some(selected => selected.id === video.id)}
                />
              ))}
            </VideosContent>
            {videosPending && (
              <LoaderContainer>
                <Spinner src={SpinnerGIF} />
              </LoaderContainer>
            )}
            <InfiniteScrollMarker ref={loadMoreVideosRef} />
          </VideosContainer>
        )}
      </ModalContainer>
    </FormModal>
  );
};

const MediaCard = ({ item, type, selected, onClick, disabled, isInBundle }) => {
  return (
    <MediaCardContainer
      $selected={selected}
      $disabled={disabled}
      $isInBundle={isInBundle}
      onClick={() => !disabled && onClick()}>
      {type === MEDIA_TYPE.PHOTO ? (
        <Image src={item.thumbnailUrl || item.url} alt="selected-premium-photo-media" />
      ) : item.thumbnailUrl ? (
        <Image src={item.thumbnailUrl} alt="selected-premium-video-thumbnail" />
      ) : (
        <Video src={item.url} />
      )}
      {isInBundle && <Ribbon>In Bundle</Ribbon>}
    </MediaCardContainer>
  );
};

export default SelectMediaModal;
