import styled from 'styled-components';
import { Text18Bold } from '../../utils/texts/text.styles';

export const Container = styled.div`
  min-height: 50rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InputContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

export const ClearAllText = styled(Text18Bold)`
  cursor: pointer;
  color: #5a4ef2;
  text-decoration: underline;
  margin-bottom: 1rem;
`;
