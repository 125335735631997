import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { addInfluencerToAgency, fetchAllInfluencers } from '../../../store/slices/agency/asyncThunks';
import { Controller } from 'react-hook-form';
import { FormSelect } from '../../../forms/form.styles';
import FormModal from '../../utils/modals/FormModal/FormModal';
import {
  selectAddInfluencerToAgencyPending,
  selectAgency,
  selectInfluencerOptions,
} from '../../../store/slices/agency/slice';
import { Form } from './AddInfluencerToAgencyModal.styles';

export const addInfluencerToAgencySchema = yup
  .object({ influencers: yup.array().min(1, 'At least one influencer is required') })
  .required();

const AddInfluencerToAgencyModal = ({ modalRef }) => {
  const dispatch = useDispatch();
  const addInfluencerToAgencyPending = useSelector(selectAddInfluencerToAgencyPending);
  const influencerOptions = useSelector(selectInfluencerOptions);
  const agency = useSelector(selectAgency);

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      influencers: [],
    },
    delayError: 300,
    resolver: yupResolver(addInfluencerToAgencySchema),
  });

  useEffect(() => {
    dispatch(fetchAllInfluencers());
  }, [dispatch]);

  const onSubmit = data => {
    const { influencers } = data;
    dispatch(addInfluencerToAgency({ influencerIds: influencers.map(i => i.value), agencyId: agency.id }))
      .unwrap()
      .then(() => {
        dispatch(fetchAllInfluencers());
        reset();
        modalRef.current.hide();
        notifyInfo('Influencer added to agency successfully');
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <FormModal
      ref={modalRef}
      title="Add Influencer to Agency"
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText="Submit"
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={addInfluencerToAgencyPending}
      shouldCloseOnOverlayClick={false}>
      <Form>
        <Controller
          name="influencers"
          control={control}
          render={({ field }) => (
            <FormSelect
              placeholder="Select influencer"
              label="Influencer"
              options={influencerOptions}
              selectedOption={field.value}
              handleChange={value => field.onChange(value)}
              error={errors.influencers?.message}
              multiSelection
            />
          )}
        />
      </Form>
    </FormModal>
  );
};

export default AddInfluencerToAgencyModal;
