import {
  acceptInfluencerFactRequest,
  createInfluencerFact,
  deleteInfluencerFact,
  editInfluencerFact,
  fetchFactRequestsByInfluencer,
  fetchInfluencerFactRequests,
  fetchInfluencerFacts,
  rejectInfluencerFactRequest,
  testInfluencerFactRequest,
} from './asyncThunks';

export const fetchInfluencerFactRequestsBuilder = builder => {
  builder.addCase(fetchInfluencerFactRequests.pending, (state, { meta }) => {
    const page = meta.arg.page;
    if (page === 1) {
      state.fetchInfluencerFactRequestsInfo.pending = true;
    } else {
      state.fetchInfluencerFactRequestsInfo.fetchMorePending = true;
    }
  });
  builder.addCase(fetchInfluencerFactRequests.fulfilled, (state, { payload }) => {
    const { data, maxReached, page } = payload;
    if (page === 1) {
      state.fetchInfluencerFactRequestsInfo.data = data;
      state.fetchInfluencerFactRequestsInfo.pending = false;
    } else {
      state.fetchInfluencerFactRequestsInfo.fetchMorePending = false;
      state.fetchInfluencerFactRequestsInfo.data = [...state.fetchInfluencerFactRequestsInfo.data, ...data];
    }
    state.fetchInfluencerFactRequestsInfo.maxReached = maxReached;
  });
  builder.addCase(fetchInfluencerFactRequests.rejected, state => {
    state.fetchInfluencerFactRequestsInfo.pending = false;
    state.fetchInfluencerFactRequestsInfo.fetchMorePending = false;
  });
};

export const fetchFactRequestsByInfluencerBuilder = builder => {
  builder.addCase(fetchFactRequestsByInfluencer.pending, (state, { meta }) => {
    const page = meta.arg.page;
    if (page === 1) {
      state.fetchFactRequestsByInfluencerInfo.pending = true;
    } else {
      state.fetchFactRequestsByInfluencerInfo.fetchMorePending = true;
    }
  });
  builder.addCase(fetchFactRequestsByInfluencer.fulfilled, (state, { payload }) => {
    const { data, maxReached, page } = payload;
    if (page === 1) {
      state.fetchFactRequestsByInfluencerInfo.data = data;
      state.fetchFactRequestsByInfluencerInfo.pending = false;
    } else {
      state.fetchFactRequestsByInfluencerInfo.fetchMorePending = false;
      state.fetchFactRequestsByInfluencerInfo.data = [...state.fetchFactRequestsByInfluencerInfo.data, ...data];
    }
    state.fetchFactRequestsByInfluencerInfo.maxReached = maxReached;
  });
  builder.addCase(fetchFactRequestsByInfluencer.rejected, state => {
    state.fetchFactRequestsByInfluencerInfo.pending = false;
    state.fetchFactRequestsByInfluencerInfo.fetchMorePending = false;
  });
};

export const fetchInfluencerFactsBuilder = builder => {
  builder.addCase(fetchInfluencerFacts.pending, (state, { meta }) => {
    const page = meta.arg.page;
    if (page === 1) {
      state.fetchInfluencerFactsInfo.pending = true;
    } else {
      state.fetchInfluencerFactsInfo.fetchMorePending = true;
    }
  });
  builder.addCase(fetchInfluencerFacts.fulfilled, (state, { payload }) => {
    const { data, maxReached, page } = payload;
    if (page === 1) {
      state.fetchInfluencerFactsInfo.data = data;
      state.fetchInfluencerFactsInfo.pending = false;
    } else {
      state.fetchInfluencerFactsInfo.fetchMorePending = false;
      state.fetchInfluencerFactsInfo.data = [...state.fetchInfluencerFactsInfo.data, ...data];
    }
    state.fetchInfluencerFactsInfo.maxReached = maxReached;
  });
  builder.addCase(fetchInfluencerFacts.rejected, state => {
    state.fetchInfluencerFactsInfo.pending = false;
    state.fetchInfluencerFactsInfo.fetchMorePending = false;
  });
};

export const createInfluencerFactBuilder = builder => {
  builder.addCase(createInfluencerFact.pending, state => {
    state.pending.createInfluencerFact = true;
  });
  builder.addCase(createInfluencerFact.fulfilled, (state, { payload }) => {
    const { influencerFact } = payload;
    state.pending.createInfluencerFact = false;
    state.fetchInfluencerFactsInfo.data = [influencerFact, ...state.fetchInfluencerFactsInfo.data];
  });
  builder.addCase(createInfluencerFact.rejected, state => {
    state.pending.createInfluencerFact = false;
  });
};

export const acceptInfluencerFactRequestBuilder = builder => {
  builder.addCase(acceptInfluencerFactRequest.pending, state => {
    state.pending.acceptInfluencerFactRequest = true;
  });
  builder.addCase(acceptInfluencerFactRequest.fulfilled, (state, { payload }) => {
    const { influencerFactRequestIds } = payload;
    state.pending.acceptInfluencerFactRequest = false;
    state.fetchFactRequestsByInfluencerInfo.data = state.fetchFactRequestsByInfluencerInfo.data.filter(
      d => !influencerFactRequestIds.includes(d.id),
    );
  });
  builder.addCase(acceptInfluencerFactRequest.rejected, state => {
    state.pending.acceptInfluencerFactRequest = false;
  });
};

export const rejectInfluencerFactRequestBuilder = builder => {
  builder.addCase(rejectInfluencerFactRequest.pending, state => {
    state.pending.rejectInfluencerFactRequest = true;
  });
  builder.addCase(rejectInfluencerFactRequest.fulfilled, (state, { payload }) => {
    const { influencerFactRequestId } = payload;
    state.pending.rejectInfluencerFactRequest = false;
    state.fetchFactRequestsByInfluencerInfo.data = state.fetchFactRequestsByInfluencerInfo.data.filter(
      d => d.id !== influencerFactRequestId,
    );
  });
  builder.addCase(rejectInfluencerFactRequest.rejected, state => {
    state.pending.rejectInfluencerFactRequest = false;
  });
};

export const testInfluencerFactRequestBuilder = builder => {
  builder.addCase(testInfluencerFactRequest.pending, state => {
    state.pending.testInfluencerFactRequest = true;
  });
  builder.addCase(testInfluencerFactRequest.fulfilled, state => {
    state.pending.testInfluencerFactRequest = false;
  });
  builder.addCase(testInfluencerFactRequest.rejected, state => {
    state.pending.testInfluencerFactRequest = false;
  })
}

export const deleteInfluencerFactBuilder = builder => {
  builder.addCase(deleteInfluencerFact.pending, state => {
    state.pending.deleteInfluencerFact = true;
  });
  builder.addCase(deleteInfluencerFact.fulfilled, (state, { payload }) => {
    const { influencerFactId } = payload;
    state.pending.deleteInfluencerFact = false;
    state.fetchInfluencerFactsInfo.data = state.fetchInfluencerFactsInfo.data.filter(d => d.id !== influencerFactId);
  });
  builder.addCase(deleteInfluencerFact.rejected, state => {
    state.pending.deleteInfluencerFact = false;
  });
};

export const editInfluencerFactBuilder = builder => {
  builder.addCase(editInfluencerFact.pending, state => {
    state.pending.editInfluencerFact = true;
  });
  builder.addCase(editInfluencerFact.fulfilled, (state, { payload }) => {
    const { influencerFact } = payload;
    state.pending.editInfluencerFact = false;
    state.fetchInfluencerFactsInfo.data = state.fetchInfluencerFactsInfo.data.map(d =>
      d.id === influencerFact.id ? influencerFact : d,
    );
  });
  builder.addCase(editInfluencerFact.rejected, state => {
    state.pending.editInfluencerFact = false;
  });
};
