import styled from 'styled-components';
import { Text12Bold } from '../../../components/utils/texts/text.styles';

export const Container = styled.div`
  display: flex;
  height: 100%;
  gap: 2rem;
  background-color: #f9f9f9;
  padding: 2rem;
`;

export const ChatSection = styled.div`
  flex: 2; /* Make the chat section take twice the space of purchases */
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 1.5rem;
  border-radius: 1.4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
`;

export const ChatMessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #f2f0f7;
  border-radius: 1rem;
  background: #fbfafc;
`;

export const ChatHeader = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #161320;
`;

export const InfiniteScrollTopMarker = styled.div`
  min-height: 1rem;
`;

export const InfiniteScrollBottomMarker = styled.div`
  min-height: 1rem;
`;

export const DateDivider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #843efe;
  background: #f9f9f9;
  text-align: center;
  border-bottom: 2px solid;
`;

export const MainContent = styled.div`
  display: flex;
  gap: 2rem;
  height: 100%; /* Ensure the container takes the full height */
  width: 100%; /* Ensure the container takes the full width */
`;

export const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #fbfafc;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;

  h4 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
    color: #161320;
  }
`;
export const AttachedMediaWrapper = styled.div`
  min-height: 40rem;
  position: relative; /* Container for positioning the overlay */
`;

export const CostOverlay = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  font-size: 1.4rem;
  font-weight: bold;
`;

export const ChatActivitiesContainer = styled.div`
  flex: 1; /* Make the chat section take twice the space of purchases */
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 1.5rem;
  border-radius: 1.4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
`;

export const ChatActivitiesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
`;

export const ChatActivity = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1rem;
  border: 1px solid ${({ $selected }) => ($selected ? '#843efe' : '#f2f0f7')};
  border-radius: 1rem;
  background: '#f0f0f0';
  cursor: pointer;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1.6rem;
`;

export const Timestamp = styled(Text12Bold)`
  margin-left: auto;
  opacity: 0.5;
`;

export const Spinner = styled.img`
  width: 30rem;
  height: 30rem;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const FetchMoreSpinner = styled.img`
  width: 5rem;
  height: 5rem;
`;

export const FetchMoreSpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
