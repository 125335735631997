import {
  addInfluencerToAgency,
  createAgency,
  createAgencyMember,
  deleteAgency,
  deleteAgencyMember,
  deleteInfluencerFromAgency,
  fetchAgencies,
  fetchAgencyById,
  fetchAllInfluencers,
} from './asyncThunks';

export const fetchAgenciesBuilder = builder => {
  builder.addCase(fetchAgencies.pending, state => {
    state.fetchAgenciesInfo.pending = true;
  });
  builder.addCase(fetchAgencies.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchAgenciesInfo.pending = false;
    state.fetchAgenciesInfo.data = data;
    state.fetchAgenciesInfo.total = total;
  });
  builder.addCase(fetchAgencies.rejected, state => {
    state.fetchAgenciesInfo.pending = false;
  });
};

export const fetchAgencyByIdBuilder = builder => {
  builder.addCase(fetchAgencyById.pending, state => {
    state.pending.fetchAgencyById = true;
  });
  builder.addCase(fetchAgencyById.fulfilled, (state, { payload }) => {
    const { agency } = payload;
    state.pending.fetchAgencyById = false;
    state.agency = agency;
  });
  builder.addCase(fetchAgencyById.rejected, state => {
    state.pending.fetchAgencyById = false;
  });
};

export const createAgencyBuilder = builder => {
  builder.addCase(createAgency.pending, state => {
    state.pending.createAgency = true;
  });
  builder.addCase(createAgency.fulfilled, state => {
    state.pending.createAgency = false;
  });
  builder.addCase(createAgency.rejected, state => {
    state.pending.createAgency = false;
  });
};

export const deleteAgencyBuilder = builder => {
  builder.addCase(deleteAgency.pending, state => {
    state.pending.deleteAgency = true;
  });
  builder.addCase(deleteAgency.fulfilled, (state, { payload }) => {
    const { agencyId } = payload;
    state.pending.deleteAgency = false;
    state.fetchAgenciesInfo.data = state.fetchAgenciesInfo.data.filter(agency => agency.id !== agencyId);
    state.fetchAgenciesInfo.total -= 1;
  });
  builder.addCase(deleteAgency.rejected, state => {
    state.pending.deleteAgency = false;
  });
};

export const addInfluencerToAgencyBuilder = builder => {
  builder.addCase(addInfluencerToAgency.pending, state => {
    state.pending.addInfluencerToAgency = true;
  });
  builder.addCase(addInfluencerToAgency.fulfilled, (state, { payload }) => {
    const { influencers } = payload;
    state.pending.addInfluencerToAgency = false;
    state.agency.influencers.push(...influencers);
  });
  builder.addCase(addInfluencerToAgency.rejected, state => {
    state.pending.addInfluencerToAgency = false;
  });
};

export const deleteInfluencerFromAgencyBuilder = builder => {
  builder.addCase(deleteInfluencerFromAgency.pending, state => {
    state.pending.deleteInfluencerFromAgency = true;
  });
  builder.addCase(deleteInfluencerFromAgency.fulfilled, (state, { payload }) => {
    const { influencerId } = payload;
    state.pending.deleteInfluencerFromAgency = false;
    state.agency.influencers = state.agency.influencers.filter(influencer => influencer.id !== influencerId);
  });
  builder.addCase(deleteInfluencerFromAgency.rejected, state => {
    state.pending.deleteInfluencerFromAgency = false;
  });
};

export const createAgencyMemberBuilder = builder => {
  builder.addCase(createAgencyMember.pending, state => {
    state.pending.createAgencyMember = true;
  });
  builder.addCase(createAgencyMember.fulfilled, (state, { payload }) => {
    const { agencyMember } = payload;
    state.pending.createAgencyMember = false;
    state.agency.agencyMembers.push(agencyMember);
  });
  builder.addCase(createAgencyMember.rejected, state => {
    state.pending.createAgencyMember = false;
  });
};

export const deleteAgencyMemberBuilder = builder => {
  builder.addCase(deleteAgencyMember.pending, state => {
    state.pending.deleteAgencyMember = true;
  });
  builder.addCase(deleteAgencyMember.fulfilled, (state, { payload }) => {
    const { agencyMemberId } = payload;
    state.pending.deleteAgencyMember = false;
    state.agency.agencyMembers = state.agency.agencyMembers.filter(agencyMember => agencyMember.id !== agencyMemberId);
  });
  builder.addCase(deleteAgencyMember.rejected, state => {
    state.pending.deleteAgencyMember = false;
  });
};

export const fetchAllInfluencersBuilder = builder => {
  builder.addCase(fetchAllInfluencers.fulfilled, (state, { payload }) => {
    const { influencers } = payload;
    state.influencerOptions = influencers.map(influencer => ({
      label: `${influencer.name} (${influencer.username})`,
      value: influencer.id,
    }));
  });
};
