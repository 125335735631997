import { isWithinInterval, max, min, parseISO } from 'date-fns';
import {
  CHAT_ACTIVITY_FORMAT,
  CHAT_ACTIVITY_SENDER,
  CHAT_ACTIVITY_TARGET,
  CHAT_ACTIVITY_TYPE,
  MEDIA_TYPE,
} from './constants';

/**
 * Find the signed URL of a photo by ID.
 */
const findPhotoUrlById = (userInfluencer, photoId) => {
  return (
    userInfluencer.influencer.albumMedia.photos.premium.find(p => p.id === photoId)?.signedUrl ||
    userInfluencer.influencer.albumMedia.photos.free.find(p => p.id === photoId)?.signedUrl ||
    userInfluencer.influencer.albumMedia.photos.bundle.find(p => p.id === photoId)?.signedUrl ||
    null
  );
};
/**
 * Find the signed URL of a video by ID.
 */
const findVideoUrlById = (userInfluencer, videoId) =>
  userInfluencer.influencer.albumMedia.videos.premium.find(v => v.id === videoId)?.signedUrl ||
  userInfluencer.influencer.albumMedia.videos.free.find(v => v.id === videoId)?.signedUrl ||
  userInfluencer.influencer.albumMedia.videos.bundle.find(v => v.id === videoId)?.signedUrl ||
  null;

/**
 * Retrieve the media array for a bundle by ID.
 */
const findBundleMediaById = (userInfluencer, bundleId) => {
  const bundle = userInfluencer.influencer.albumMedia.bundles.find(b => b.id === bundleId);
  if (!bundle) return null;

  return bundle.mediaGroup.map(media => {
    const url =
      media.type === MEDIA_TYPE.PHOTO
        ? findPhotoUrlById(userInfluencer, media.mediaId)
        : media.type === MEDIA_TYPE.VIDEO
        ? findVideoUrlById(userInfluencer, media.mediaId)
        : null;

    return { type: media.type, url };
  });
};

/**
 * Prepare interaction messages from sent and received data.
 */
const prepareMessages = data =>
  data.flatMap(interaction => {
    const messages = [];

    if (interaction.sentMessage) {
      messages.push({
        id: `sent-${interaction._id}`,
        type: CHAT_ACTIVITY_TYPE.MESSAGE,
        sender: CHAT_ACTIVITY_SENDER.USER,
        message: interaction.sentMessage,
        timestamp: interaction.sentAt,
        format: CHAT_ACTIVITY_FORMAT.TEXT,
      });
    }

    messages.push({
      id: `received-${interaction._id}`,
      type: CHAT_ACTIVITY_TYPE.MESSAGE,
      sender: CHAT_ACTIVITY_SENDER.INFLUENCER,
      message: interaction.receivedMessage,
      timestamp: interaction.receivedAt,
      format: interaction.responseFormat,
    });

    return messages;
  });

/**
 * Prepare chat activities filtered by a timestamp range.
 */
const prepareActivities = (userInfluencer, start, end) =>
  userInfluencer.chatActivities
    .filter(activity => isWithinInterval(activity.timestamp, { start, end }))
    .map(activity => ({
      id: activity._id,
      url:
        activity.target === CHAT_ACTIVITY_TARGET.FREE_PHOTO || activity.target === CHAT_ACTIVITY_TARGET.PREMIUM_PHOTO
          ? findPhotoUrlById(userInfluencer, activity.metadata.photoId)
          : activity.target === CHAT_ACTIVITY_TARGET.FREE_VIDEO ||
            activity.target === CHAT_ACTIVITY_TARGET.PREMIUM_VIDEO
          ? findVideoUrlById(userInfluencer, activity.metadata.videoId)
          : null,
      media:
        activity.target === CHAT_ACTIVITY_TARGET.BUNDLE
          ? findBundleMediaById(userInfluencer, activity.metadata.bundleId)
          : null,
      type: activity.type,
      target: activity.target,
      metadata: activity.metadata,
      cost: activity.cost,
      timestamp: activity.timestamp,
    }));

/**
 * Combine messages and activities, and sort them by timestamp.
 */
export const prepareInteractionTimeline = (userInfluencer, data) => {
  const messages = prepareMessages(data);

  const dates = messages.map(({ timestamp }) => parseISO(timestamp));
  const earliestMessageTimestamp = min(dates);
  const latestMessageTimestamp = max(dates);

  const activities = prepareActivities(userInfluencer, earliestMessageTimestamp, latestMessageTimestamp);

  return [...messages, ...activities].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
};
