import React, { Fragment } from 'react';
import { TableBodyItem } from '../../Table.styles';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../../../utils/constants';
export const STATUS = {
  APPROVED: 'Approved',
  REQUESTED: 'Requested',
  DECLINED: 'Declined',
};

const WithdrawalItem = ({ status, amount, createdAt, influencerName, withdrawalMethodType }) => {
  return (
    <Fragment>
      <TableBodyItem>
        <p>{influencerName}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>${amount * VALUE_OF_ONE_STAR_IN_USD}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{STATUS[status]}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{withdrawalMethodType}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{new Date(createdAt).toLocaleString()}</p>
      </TableBodyItem>
    </Fragment>
  );
};

export default WithdrawalItem;
