import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import {
  acceptWithdrawalBuilder,
  createWithdrawalBuilder,
  declineWithdrawalBuilder,
  fetchWithdrawalsBuilder,
} from './builder';

const INIT_STATE = {
  fetchWithdrawalsInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  pending: {
    createWithdrawal: false,
    declineWithdrawal: false,
    acceptWithdrawal: false,
  },
};

export const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    fetchWithdrawalsBuilder(builder);
    createWithdrawalBuilder(builder);
    declineWithdrawalBuilder(builder);
    acceptWithdrawalBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectFetchWithdrawalsInfo = state => state.withdrawalInfo.fetchWithdrawalsInfo;
export const selectCreateWithdrawalPending = state => state.withdrawalInfo.pending.createWithdrawal;
export const selectDeclineWithdrawalPending = state => state.withdrawalInfo.pending.declineWithdrawal;
export const selectAcceptWithdrawalPending = state => state.withdrawalInfo.pending.acceptWithdrawal;

export default withdrawalSlice.reducer;
