import React, { useCallback } from 'react';
import { Shadow } from '../../../../components/navigations/PrivateNavigation/PrivateNavigation.styles';
import { BackContainer, Row, Sidebar, StepsContainer, Title } from './CreateInfluencerSidebar.styles';
import { ReactComponent as BackIcon } from '../../../../assets/icons/back.svg';
import { useNavigate } from 'react-router-dom';
import FormStep, { FORM_STEP_STATUS } from '../../../../components/FormStep/FormStep';

const STEPS = [
  {
    id: 0,
    num: 1,
    title: 'Basic Info',
    subtitle: 'STEP 1',
  },
  {
    id: 1,
    num: 2,
    title: 'Prompts',
    subtitle: 'STEP 2',
  },
];

function CreateInfluencerSidebar({ currentStep, selectedStep, onChangeStep }) {
  const navigate = useNavigate();

  const defineStepStatus = useCallback((step, currentStep) => {
    if (step < currentStep) {
      return FORM_STEP_STATUS.COMPLETED;
    } else if (step === currentStep) {
      return FORM_STEP_STATUS.CURRENT;
    } else {
      return FORM_STEP_STATUS.UNCOMPLETED;
    }
  }, []);

  return (
    <Sidebar>
      <Shadow />
      <Row>
        <BackContainer onClick={() => navigate(-1)}>
          <BackIcon fill="#fff" />
        </BackContainer>
        <Title>Create Influencer</Title>
      </Row>
      <StepsContainer>
        {STEPS.map((step, index) => {
          const stepStatus = defineStepStatus(step.id, currentStep);
          return (
            <FormStep
              key={step.id}
              number={step.num}
              title={step.title}
              subtitle={step.subtitle}
              status={stepStatus}
              selected={step.id === selectedStep}
              onClick={stepStatus !== FORM_STEP_STATUS.UNCOMPLETED ? () => onChangeStep(step.id) : null}
            />
          );
        })}
      </StepsContainer>
    </Sidebar>
  );
}

export default CreateInfluencerSidebar;
