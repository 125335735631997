import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/a/user-influencers';

class UserInfluencersService {
  fetchUserInfluencers = async (page, search, sortBy, order, filters) => {
    try {
      const response = await axiosApiClient.post(
        `${BASE_URL}?page=${page}&search=${search}&sortBy=${sortBy}&order=${order}`,
        { filters },
      );
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching user influencers.');
    }
  };
}

const userInfluencersService = new UserInfluencersService();
export default userInfluencersService;
