import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AcceptAllButton,
  AcceptButton,
  ActionContainer,
  Container,
  DeclineButton,
  Header,
  InfiniteScrollMarker,
  InputWrapper,
  ListContainer,
  LoaderContainer,
  LoaderMoreContainer,
  RowContent,
  RowItem,
  Spinner,
  TestButton,
  TextLabel,
} from './InfluencerFactRequestsList.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptInfluencerFactRequest,
  fetchFactRequestsByInfluencer,
  rejectInfluencerFactRequest,
} from '../../../store/slices/influencerFact/asyncThunks';
import {
  selectAcceptInfluencerFactRequestPending,
  selectFetchFactRequestsByInfluencerInfo,
  selectRejectInfluencerFactRequestPending,
} from '../../../store/slices/influencerFact/slice';
import { Text14Bold, Text20Bold } from '../../utils/texts/text.styles';
import { ReactComponent as XCircleIcon } from '../../../assets/icons/x-circle.svg';
import { ReactComponent as CheckCircleIcon } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as TestCircleIcon } from '../../../assets/icons/test-circle.svg';
import SpinnerGIF from '../../../assets/images/spinner.gif';
import { notifyError, notifyInfo } from '../../../utils/notify';
import debounce from 'lodash.debounce';
import Input from '../../utils/inputs/Input/Input';
import InfluencerFactScoresModal from '../../modals/InfluencerFactScoresModal/InfluencerFactScoresModal';

const InfluencerFactRequestsList = ({ influencerId }) => {
  const dispatch = useDispatch();
  const loadMoreRef = useRef(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { data, maxReached, pending, fetchMorePending } = useSelector(selectFetchFactRequestsByInfluencerInfo);
  const acceptInfluencerFactRequestPending = useSelector(selectAcceptInfluencerFactRequestPending);
  const rejectInfluencerFactRequestPending = useSelector(selectRejectInfluencerFactRequestPending);

  const [selectedInfluencerFactRequestId, setSelectedInfluencerFactRequestId] = useState(null);
  const influencerFactScoresModalRef = useRef();
  const [acceptAll, setAcceptAll] = useState(false);

  const disabledActions = acceptInfluencerFactRequestPending || rejectInfluencerFactRequestPending;

  const changeHandler = useCallback(text => {
    setSearch(text);
  }, []);

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  useEffect(() => {
    setPage(1);
    dispatch(fetchFactRequestsByInfluencer({ page: 1, influencerId, search }));
  }, [dispatch, influencerId, search]);

  const handleIntersection = useCallback(
    entries => {
      const [entry] = entries;
      if (entry.isIntersecting && !pending && !fetchMorePending && !maxReached) {
        setPage(prev => prev + 1);
        dispatch(fetchFactRequestsByInfluencer({ page: page + 1, influencerId, search }));
      }
    },
    [pending, maxReached, fetchMorePending, page, dispatch, influencerId, search],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, { root: null, threshold: 1.0 });
    const target = loadMoreRef.current;
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [handleIntersection]);

  const rejectFactRequestClick = influencerFactRequestId => {
    if (!disabledActions) {
      dispatch(rejectInfluencerFactRequest({ influencerFactRequestId }))
        .unwrap()
        .then(() => {
          if (data.length === 1) {
            dispatch(fetchFactRequestsByInfluencer({ page: 1, influencerId }));
          }

          notifyInfo('Influencer fact request rejected successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };

  const acceptFactRequestClick = influencerFactRequestId => {
    if (!disabledActions) {
      dispatch(acceptInfluencerFactRequest({ influencerFactRequestIds: [influencerFactRequestId] }))
        .unwrap()
        .then(() => {
          if (data.length === 1) {
            dispatch(fetchFactRequestsByInfluencer({ page: 1, influencerId }));
          }

          notifyInfo('Influencer fact request accepted successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };

  const acceptAllFactRequests = () => {
    setAcceptAll(true);
    dispatch(acceptInfluencerFactRequest({ influencerFactRequestIds: data.map(i => i.id) }))
      .unwrap()
      .then(() => {
        setAcceptAll(false);
        dispatch(fetchFactRequestsByInfluencer({ page: 1, influencerId }));
        notifyInfo('Influencer fact requests accepted successfully!');
      })
      .catch(err => {
        setAcceptAll(false);
        notifyError(err.message);
      });
  };


  const testFactRequestClick = influencerFactRequestId => {
    if (!disabledActions) {
      setSelectedInfluencerFactRequestId(influencerFactRequestId);
      influencerFactScoresModalRef.current.show();
    }
  };

  return (
    <Container>
      <Header>
        <Text20Bold>Requests</Text20Bold>
        {data.length > 1 && (
          <AcceptAllButton
            title="Accept All"
            onClick={acceptAllFactRequests}
            isLoading={acceptAll && acceptInfluencerFactRequestPending}
            disabled={acceptInfluencerFactRequestPending || rejectInfluencerFactRequestPending}
          />
        )}
      </Header>
      <InputWrapper>
        <Input
          placeholder="Search Fact Requests"
          onChange={e => {
            debouncedChangeHandler(e.target.value);
          }}
        />
      </InputWrapper>
      {pending ? (
        <LoaderContainer>
          <Spinner src={SpinnerGIF} />
        </LoaderContainer>
      ) : (
        <ListContainer>
          {data.map(factRequest => {
            return (
              <RowItem key={factRequest.id}>
                <RowContent>
                  <TextLabel>User Input</TextLabel>
                  <Text14Bold>{factRequest.userInput}</Text14Bold>
                </RowContent>
                <ActionContainer $disabled={disabledActions}>
                  <DeclineButton onClick={() => rejectFactRequestClick(factRequest.id)}>
                    <XCircleIcon />
                  </DeclineButton>
                  <AcceptButton onClick={() => acceptFactRequestClick(factRequest.id)}>
                    <CheckCircleIcon />
                  </AcceptButton>
                  <TestButton onClick={() => testFactRequestClick(factRequest.id)}>
                    <TestCircleIcon />
                  </TestButton>
                </ActionContainer>
              </RowItem>
            );
          })}
          {fetchMorePending && (
            <LoaderMoreContainer>
              <Spinner src={SpinnerGIF} />
            </LoaderMoreContainer>
          )}
          <InfiniteScrollMarker ref={loadMoreRef} />
        </ListContainer>
      )}

      <InfluencerFactScoresModal
        modalRef={influencerFactScoresModalRef}
        influencerFactRequestId={selectedInfluencerFactRequestId}
        setSelectedInfluencerFactRequestId={setSelectedInfluencerFactRequestId}
      />
    </Container>
    
  );
};

export default InfluencerFactRequestsList;
