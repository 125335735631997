import React from 'react';
import { TableBodyItem, TableItemImage } from '../../Table.styles';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../../../utils/constants';

const UserInfluencerTableItem = ({
  userName,
  influencerName,
  influencerProfilePhoto,
  totalSpent,
  totalMessages,
  createdAt,
}) => {
  return (
    <>
      <TableBodyItem>
        <p>{userName || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <TableItemImage src={influencerProfilePhoto} alt="influencer-profile-photo" />
        <p>{influencerName || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{`${totalSpent} ⭐️ = $${(totalSpent * VALUE_OF_ONE_STAR_IN_USD).toFixed(1)} ` || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{totalMessages || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <p>{new Date(createdAt).toLocaleString() || '-'}</p>
      </TableBodyItem>
    </>
  );
};

export default UserInfluencerTableItem;
