import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditAlbumMediaPending,
  selectFetchInfluencerVariablesInfoData,
  selectPingAdminsWithMediaPending,
  selectUploadAlbumMediaPending,
} from '../../../store/slices/influencer/slice';
import { notifyError, notifyInfo } from '../../../utils/notify';
import FileInput from '../../utils/inputs/FileInput/FileInput';
import {
  MEDIA_CATEGORY,
  MEDIA_TYPE,
  UPLOAD_FILE_CONSTRAINTS,
  VALUE_OF_ONE_STAR_IN_USD,
} from '../../../utils/constants';
import {
  editInfluencerAlbumMedia,
  pingAdminsWithMedia,
  uploadInfluencerAlbumMedia,
} from '../../../store/slices/influencer/asyncThunks';
import { uploadPremiumAlbumMediaFormSchema } from '../../../forms/schemas/albumMedia.schema';
import { FormInput, FormNumericInput, FormSelect } from '../../../forms/form.styles';
import { Actions, GenerateText, PriceInUsd, Row, Spinner } from './UploadPremiumPhotoFormModal.styles';
import GetCaptionRecommendationsModal from '../GetCaptionRecommendationsModal/GetCaptionRecommendationsModal';
import SpinnerGIF from '../../../assets/images/spinnerrrr.gif';
import PreviewAlbumMediaModal from '../PreviewAlbumMediaModal/PreviewAlbumMediaModal';

const UploadPremiumPhotoFormModal = ({ modalRef, selectedAlbumMedia, onAfterClose, customOnSubmit }) => {
  const influencerVariables = useSelector(selectFetchInfluencerVariablesInfoData);
  const uploadAlbumMediaPending = useSelector(selectUploadAlbumMediaPending);
  const editAlbumMediaPending = useSelector(selectEditAlbumMediaPending);
  const pingAdminsWithMediaPending = useSelector(selectPingAdminsWithMediaPending);
  const getCaptionRecommendationsModalRef = useRef();
  const previewAlbumMediaModalRef = useRef();
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [selectedMediaId, setSelectedMediaId] = useState(null);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      albumMedia: null,
      representativeAlbumMedia: null,
      cost: '',
      aiDescription: '',
      caption: '',
      fileId: '',
      tag: null,
      representativeFileId: '',
    },
    delayError: 300,
    resolver: yupResolver(uploadPremiumAlbumMediaFormSchema),
  });

  const watchCost = watch('cost');
  const watchRepresentativeAlbumMedia = watch('representativeAlbumMedia');

  useEffect(() => {
    if (selectedAlbumMedia && influencerVariables?.albumMediaTags) {
      const albumMediaTag = influencerVariables.albumMediaTags.find(tag => {
        if (selectedAlbumMedia.tag) {
          if (typeof selectedAlbumMedia.tag === 'string') {
            return tag.id === selectedAlbumMedia.tag;
          } else {
            return tag.id === selectedAlbumMedia.tag.value;
          }
        } else {
          return false;
        }
      });
      const {
        aiDescription,
        caption,
        cost,
        url,
        representativeUrl,
        albumMedia,
        representativeAlbumMedia,
        fileId,
        representativeFileId,
      } = selectedAlbumMedia;

      setValue('albumMedia', url ? url : albumMedia);
      if (representativeUrl || representativeAlbumMedia) {
        setValue('representativeAlbumMedia', representativeUrl ? representativeUrl : representativeAlbumMedia);
      }
      setValue('cost', cost);
      setValue('aiDescription', aiDescription);
      setValue('caption', caption);
      setValue('fileId', fileId);
      if (albumMediaTag) {
        setValue('tag', { value: albumMediaTag.id, label: albumMediaTag.name });
      }
      if (representativeFileId) {
        setValue('representativeFileId', representativeFileId);
      }
    } else {
      reset();
    }
  }, [selectedAlbumMedia, setValue, reset, influencerVariables?.albumMediaTags]);

  const onGenerateClick = () => {
    setSelectedMediaId(selectedAlbumMedia.id);
    getCaptionRecommendationsModalRef.current.show();
  };

  const onPingClick = notify => {
    const fileId = getValues('fileId');
    dispatch(pingAdminsWithMedia({ fileId, mediaType: MEDIA_TYPE.PHOTO, notify }))
      .unwrap()
      .then(({ fileLink }) => {
        setSelectedUrl(fileLink);
        previewAlbumMediaModalRef.current.show();
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  const onSubmit = data => {
    if (!!customOnSubmit) {
      customOnSubmit(data);
      modalRef.current.hide();
      return;
    }

    let fileExtension = null;
    let representativeFileType = null;

    if (data.representativeAlbumMedia) {
      const mediaSource =
        typeof data.representativeAlbumMedia === 'string'
          ? data.representativeAlbumMedia
          : data.representativeAlbumMedia.file.name;

      const match = mediaSource.match(/\.([a-zA-Z0-9]+)(?=\?|$)/);
      fileExtension = match ? match[1] : null;
    }

    // Determine representative file type based on the file extension
    if (UPLOAD_FILE_CONSTRAINTS.PHOTO.ALLOWED_EXTENSIONS.includes(fileExtension)) {
      representativeFileType = UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO;
    } else if (UPLOAD_FILE_CONSTRAINTS.VIDEO.ALLOWED_EXTENSIONS.includes(fileExtension)) {
      representativeFileType = UPLOAD_FILE_CONSTRAINTS.TYPE.VIDEO;
    }

    if (selectedAlbumMedia) {
      dispatch(
        editInfluencerAlbumMedia({
          ...data,
          albumMediaId: selectedAlbumMedia.id,
          mediaType: MEDIA_TYPE.PHOTO,
          mediaCategory: MEDIA_CATEGORY.PREMIUM,
          tag: data.tag.value,
          representativeFileType,
        }),
      )
        .unwrap()
        .then(_ => {
          modalRef.current.hide();
          notifyInfo('Premium photo edited successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    } else {
      dispatch(
        uploadInfluencerAlbumMedia({
          ...data,
          mediaType: MEDIA_TYPE.PHOTO,
          mediaCategory: MEDIA_CATEGORY.PREMIUM,
          tag: data.tag.value,
          representativeFileType,
        }),
      )
        .unwrap()
        .then(_ => {
          modalRef.current.hide();
          notifyInfo('Premium photo uploaded successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };

  return (
    <FormModal
      ref={modalRef}
      title={selectedAlbumMedia ? 'Edit Album Media (Premium Photo)' : 'Upload Album Media (Premium Photo)'}
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
        onAfterClose();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText={selectedAlbumMedia ? 'Edit' : 'Submit'}
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={uploadAlbumMediaPending || editAlbumMediaPending}
      shouldCloseOnOverlayClick={false}>
      <form>
        <Row>
          <Controller
            name="albumMedia"
            control={control}
            render={({ field }) => {
              return (
                <FileInput
                  disabled={!customOnSubmit && selectedAlbumMedia}
                  label="Album media"
                  value={field.value}
                  onChange={field.onChange}
                  type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
                  error={errors.albumMedia?.message}
                  increasedFileHeight
                />
              );
            }}
          />

          <Controller
            name="representativeAlbumMedia"
            control={control}
            render={({ field }) => {
              const onDeleteRepresentativeFile = () => {
                setValue('representativeFileId', '');
                field.onChange(null);
              };
              
              return (
                <FileInput
                  label="Representative media"
                  value={field.value}
                  onChange={field.onChange}
                  type={UPLOAD_FILE_CONSTRAINTS.TYPE.REPRESENTATIVE_MEDIA}
                  error={errors.representativeAlbumMedia?.message}
                  increasedFileHeight
                  onDeleteClick={field.value ? onDeleteRepresentativeFile : null}
                />
              );
            }}
          />
        </Row>
        <Row>
          <FormInput
            label="File ID"
            placeholder="Enter file ID"
            {...register('fileId')}
            error={errors.fileId?.message}
          />
          {selectedAlbumMedia &&
            (pingAdminsWithMediaPending ? (
              <Actions>
                <Spinner src={SpinnerGIF} />
              </Actions>
            ) : (
              <Actions>
                <GenerateText onClick={() => onPingClick(true)}>Ping</GenerateText>
                <GenerateText onClick={() => onPingClick(false)}>Show</GenerateText>
              </Actions>
            ))}
        </Row>
        <FormInput
          label="Representative File ID"
          placeholder="Enter representative file ID"
          {...register('representativeFileId')}
          error={errors.representativeFileId?.message}
          disabled={!watchRepresentativeAlbumMedia}
        />
        <Controller
          name="tag"
          control={control}
          render={({ field }) => (
            <FormSelect
              placeholder="Select tag"
              label="Tag"
              options={
                influencerVariables?.albumMediaTags
                  ? influencerVariables.albumMediaTags.map(tag => ({ value: tag.id, label: tag.name }))
                  : []
              }
              selectedOption={field.value}
              handleChange={value => field.onChange(value)}
              error={errors.tag?.message}
            />
          )}
        />
        <Row>
          <FormNumericInput
            label="Cost (stars)"
            {...register('cost', {
              valueAsNumber: true,
            })}
            error={errors.cost?.message}
          />
          <PriceInUsd>= ${((watchCost || 0) * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}</PriceInUsd>
        </Row>
        <FormInput
          label="AI description"
          placeholder="Enter AI description"
          {...register('aiDescription')}
          error={errors.aiDescription?.message}
        />
        <Row>
          <FormInput
            label="Caption"
            placeholder="Enter caption"
            {...register('caption')}
            error={errors.caption?.message}
          />
          {selectedAlbumMedia && <GenerateText onClick={onGenerateClick}>Generate</GenerateText>}
        </Row>
      </form>
      <GetCaptionRecommendationsModal
        modalRef={getCaptionRecommendationsModalRef}
        mediaId={selectedMediaId}
        setMediaId={setSelectedMediaId}
        setValue={setValue}
        mediaType={MEDIA_TYPE.PHOTO}
      />
      <PreviewAlbumMediaModal
        modalRef={previewAlbumMediaModalRef}
        type={MEDIA_TYPE.PHOTO}
        url={selectedUrl}
        setSelectedUrl={setSelectedUrl}
      />
    </FormModal>
  );
};

export default UploadPremiumPhotoFormModal;
