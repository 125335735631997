import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditAlbumMediaPending, selectUploadAlbumMediaPending } from '../../../store/slices/influencer/slice';
import { notifyError, notifyInfo } from '../../../utils/notify';
import FileInput from '../../utils/inputs/FileInput/FileInput';
import { MEDIA_CATEGORY, MEDIA_TYPE, UPLOAD_FILE_CONSTRAINTS } from '../../../utils/constants';
import { editInfluencerAlbumMedia, uploadInfluencerAlbumMedia } from '../../../store/slices/influencer/asyncThunks';
import { uploadBundleAlbumMediumFormSchema } from '../../../forms/schemas/albumMedia.schema';
import { FormInput } from '../../../forms/form.styles';

const UploadBundlePhotoFormModal = ({ modalRef, selectedAlbumMedia, onAfterClose, customOnSubmit }) => {
  const uploadAlbumMediaPending = useSelector(selectUploadAlbumMediaPending);
  const editAlbumMediaPending = useSelector(selectEditAlbumMediaPending);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      albumMedia: null,
      fileId: '',
    },
    delayError: 300,
    resolver: yupResolver(uploadBundleAlbumMediumFormSchema),
  });

  useEffect(() => {
    if (selectedAlbumMedia) {
      const { url, albumMedia, fileId } = selectedAlbumMedia;
      setValue('albumMedia', url ? url : albumMedia);
      setValue('fileId', fileId);
    } else {
      reset();
    }
  }, [selectedAlbumMedia, setValue, reset]);

  const onSubmit = data => {
    if (!!customOnSubmit) {
      customOnSubmit(data);
      modalRef.current.hide();
      return;
    }

    if (selectedAlbumMedia) {
      dispatch(
        editInfluencerAlbumMedia({
          ...data,
          albumMediaId: selectedAlbumMedia.id,
          mediaType: MEDIA_TYPE.PHOTO,
          mediaCategory: MEDIA_CATEGORY.BUNDLE,
        }),
      )
        .unwrap()
        .then(_ => {
          modalRef.current.hide();
          notifyInfo('Bundle photo edited successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    } else {
      dispatch(
        uploadInfluencerAlbumMedia({ ...data, mediaType: MEDIA_TYPE.PHOTO, mediaCategory: MEDIA_CATEGORY.BUNDLE }),
      )
        .unwrap()
        .then(_ => {
          modalRef.current.hide();
          notifyInfo('Bundle photo uploaded successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };

  return (
    <FormModal
      ref={modalRef}
      title={selectedAlbumMedia ? 'Edit Album Media (Bundle Photo)' : 'Upload Album Media (Bundle Photo)'}
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
        onAfterClose();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText={selectedAlbumMedia ? 'Edit' : 'Submit'}
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={uploadAlbumMediaPending || editAlbumMediaPending}
      shouldCloseOnOverlayClick={false}>
      <form>
        <Controller
          name="albumMedia"
          control={control}
          render={({ field }) => {
            return (
              <FileInput
                disabled={!customOnSubmit && selectedAlbumMedia}
                label="Album media"
                value={field.value}
                onChange={field.onChange}
                type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
                error={errors.albumMedia?.message}
                increasedFileHeight
              />
            );
          }}
        />
        <FormInput label="File ID" placeholder="Enter file ID" {...register('fileId')} error={errors.fileId?.message} />
      </form>
    </FormModal>
  );
};

export default UploadBundlePhotoFormModal;
