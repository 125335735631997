import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, UserTableItem } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchUsersInfo } from '../../../store/slices/user/slice';
import { fetchUsers } from '../../../store/slices/user/asyncThunks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { Circle, Header, SearchInput, Switch, SwitchPageButton, Title, TitleContainer } from './UsersPage.styles';
import { Text14Regular } from '../../../components/utils/texts/text.styles';

function UsersPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get('page') || 1;
  const search = searchParams.get('search');
  const { data, total, pending } = useSelector(selectFetchUsersInfo);
  const [searchValue, setSearchValue] = useState(() => search ?? '');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tableHeader = useMemo(() => [{ label: 'Name' }, { label: 'Influencers' }, { label: 'Total spent' }], []);

  const changeHandler = useCallback(
    text => {
      searchParams.set('search', text);
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  useEffect(() => {
    if (page > 0) {
      dispatch(fetchUsers({ page, search: search ?? '' }));
    }
  }, [dispatch, page, search]);

  const setPage = page => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  return (
    <>
      <Header>
        <TitleContainer>
          <Title>Users</Title>
          <SearchInput
            value={searchValue}
            onChange={event => {
              setSearchValue(event.target.value);
              debouncedChangeHandler(event.target.value);
            }}
            placeholder="Search"
            hideErrorMessage
          />
        </TitleContainer>
        <SwitchPageButton onClick={() => navigate('/users/influencers')}>
          <Text14Regular>Influencers</Text14Regular>
          <Switch>
            <Circle />
          </Switch>
        </SwitchPageButton>
      </Header>
      <Table
        headerInformation={tableHeader}
        dataLoading={pending}
        totalCount={total}
        currentPage={page}
        perPage={10}
        setPage={setPage}
        emptyDataText={'No data'}
        fullHeight>
        {data.map(user => (
          <UserTableItem
            key={user.id}
            userId={user.id}
            name={user.name}
            influencers={user.influencers}
            totalSpent={user.totalSpent}
            onDetailsClicked={() => {
              navigate(`/users/${user.id}`);
            }}
          />
        ))}
      </Table>
    </>
  );
}

export default UsersPage;
