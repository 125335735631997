import React, { useState } from 'react';
import { AttachedMediaWrapper, CostOverlay } from '../UserInfluencerDetailsPage.styles';
import SpinnerGIF from '../../../../assets/images/spinner.gif';
import { AttachedVideo, LoaderContainer, Spinner } from './VideoDisplay.styles';

const VideoDisplay = ({ id, src, cost }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <AttachedMediaWrapper id={id}>
      {!loaded && (
        <LoaderContainer>
          <Spinner src={SpinnerGIF} />
        </LoaderContainer>
      )}
      <AttachedVideo
        src={src}
        onLoadedData={() => {
          setLoaded(true);
        }}
        style={{ display: loaded ? 'block' : 'none' }} // Toggle visibility
        controls
      />
      {cost && <CostOverlay>{cost} ⭐️</CostOverlay>}
    </AttachedMediaWrapper>
  );
};

export default VideoDisplay;
