import { acceptWithdrawal, createWithdrawal, declineWithdrawal, fetchWithdrawals } from './asyncThunks';

export const fetchWithdrawalsBuilder = builder => {
  builder.addCase(fetchWithdrawals.pending, state => {
    state.fetchWithdrawalsInfo.pending = true;
  });
  builder.addCase(fetchWithdrawals.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchWithdrawalsInfo.pending = false;
    state.fetchWithdrawalsInfo.data = data;
    state.fetchWithdrawalsInfo.total = total;
  });
  builder.addCase(fetchWithdrawals.rejected, state => {
    state.fetchWithdrawalsInfo.pending = false;
  });
};

export const createWithdrawalBuilder = builder => {
  builder.addCase(createWithdrawal.pending, state => {
    state.pending.createWithdrawal = true;
  });
  builder.addCase(createWithdrawal.fulfilled, state => {
    state.pending.createWithdrawal = false;
  });
  builder.addCase(createWithdrawal.rejected, state => {
    state.pending.createWithdrawal = false;
  });
};

export const declineWithdrawalBuilder = builder => {
  builder.addCase(declineWithdrawal.pending, state => {
    state.pending.declineWithdrawal = true;
  });
  builder.addCase(declineWithdrawal.fulfilled, state => {
    state.pending.declineWithdrawal = false;
  });
  builder.addCase(declineWithdrawal.rejected, state => {
    state.pending.declineWithdrawal = false;
  });
};

export const acceptWithdrawalBuilder = builder => {
  builder.addCase(acceptWithdrawal.pending, state => {
    state.pending.acceptWithdrawal = true;
  });
  builder.addCase(acceptWithdrawal.fulfilled, state => {
    state.pending.acceptWithdrawal = false;
  });
  builder.addCase(acceptWithdrawal.rejected, state => {
    state.pending.acceptWithdrawal = false;
  });
};
