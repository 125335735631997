import { fetchUserInfluencers } from './asyncThunks';

export const fetchUserInfluencersBuilder = builder => {
  builder.addCase(fetchUserInfluencers.pending, state => {
    state.fetchUserInfluencersInfo.pending = true;
  });
  builder.addCase(fetchUserInfluencers.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchUserInfluencersInfo.pending = false;
    state.fetchUserInfluencersInfo.data = data;
    state.fetchUserInfluencersInfo.total = total;
  });
  builder.addCase(fetchUserInfluencers.rejected, state => {
    state.fetchUserInfluencersInfo.pending = false;
  });
};
