import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectDeleteAgencyPending, selectFetchAgenciesInfo } from '../../../store/slices/agency/slice';
import debounce from 'lodash.debounce';
import { deleteAgency, fetchAgencies } from '../../../store/slices/agency/asyncThunks';
import { AddAgencyButton, Header, Row, SearchInput, Title } from './AgenciesListPage.styles';
import { Table } from '../../../components';
import AgencyItem from '../../../components/Table/items/AgencyItem/AgencyItem';
import CreateAgencyFormModal from '../../../components/modals/CreateAgencyFormModal/CreateAgencyFormModal';
import { useConfirmationModal } from '../../../components/utils/modals/ConfirmationModal/ConfirmationModal';
import { notifyError, notifyInfo } from '../../../utils/notify';

const AgenciesListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get('page') || 1;
  const search = searchParams.get('search');
  const [searchValue, setSearchValue] = useState(() => search ?? '');
  const { data, total, pending } = useSelector(selectFetchAgenciesInfo);
  const deleteAgencyPending = useSelector(selectDeleteAgencyPending);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createAgencyModalRef = useRef();
  const [openModal, closeModal, setConfirmationModalLoader] = useConfirmationModal();

  useEffect(() => {
    setConfirmationModalLoader(deleteAgencyPending);
  }, [deleteAgencyPending, setConfirmationModalLoader]);

  const changeHandler = useCallback(
    text => {
      searchParams.set('search', text);
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  useEffect(() => {
    if (page > 0) {
      dispatch(fetchAgencies({ page, search: search ?? '' }));
    }
  }, [dispatch, page, search]);

  const setPage = page => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  const tableHeader = [{ label: 'Name' }, { label: 'Actions', type: 'action' }];

  const onDeleteAgencyClick = (e, agencyId) => {
    openModal(
      'Delete Agency',
      `Are you sure you want to delete agency?`,
      'Delete',
      () => {
        dispatch(deleteAgency({ agencyId }))
          .unwrap()
          .then(() => {
            notifyInfo('Agency deleted successfully!');
            closeModal();
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Cancel',
      () => {
        closeModal();
      },
    );
    e.stopPropagation();
  };

  return (
    <>
      <Header>
        <Title>Agencies</Title>
        <Row>
          <SearchInput
            value={searchValue}
            onChange={event => {
              setSearchValue(event.target.value);
              debouncedChangeHandler(event.target.value);
            }}
            placeholder="Search"
            hideErrorMessage
          />
          <AddAgencyButton title="Create Agency" onClick={() => createAgencyModalRef.current.show()} />
        </Row>
      </Header>
      <Table
        headerInformation={tableHeader}
        dataLoading={pending}
        totalCount={total}
        currentPage={page}
        perPage={10}
        setPage={setPage}
        emptyDataText={'No data'}
        fullHeight>
        {data.map(agency => (
          <AgencyItem
            key={agency.id}
            name={agency.name}
            onDetailsClicked={() => {
              navigate(`/agencies/${agency.id}`);
            }}
            onDelete={e => onDeleteAgencyClick(e, agency.id)}
          />
        ))}
      </Table>
      <CreateAgencyFormModal
        modalRef={createAgencyModalRef}
        onSuccess={() => {
          if (page > 0) {
            dispatch(fetchAgencies({ page, search: search ?? '' }));
          }
        }}
      />
    </>
  );
};

export default AgenciesListPage;
