import React, { Fragment } from 'react';
import { ActionTableBodyItem, TableBodyItem } from '../../Table.styles';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-dark.svg';
import { EditIconWrapper } from './AgencyItem.styles';

const AgencyItem = ({ name, onDelete }) => {
  return (
    <Fragment>
      <TableBodyItem>
        <p>{name}</p>
      </TableBodyItem>
      <ActionTableBodyItem>
        <DeleteIcon onClick={onDelete} />
        <EditIconWrapper>
          <EditIcon />
        </EditIconWrapper>
      </ActionTableBodyItem>
    </Fragment>
  );
};

export default AgencyItem;
