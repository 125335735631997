import { SCROLL_DIRECTION } from '../../../pages/users/UserInfluencerDetailsPage/UserInfluencerDetailsPage';
import { prepareInteractionTimeline } from '../../../utils/userInfluencer';
import { fetchInteractions, searchActivityInChat } from './asyncThunks';

export const fetchInteractionsBuilder = builder => {
  builder.addCase(fetchInteractions.pending, (state, action) => {
    const direction = action.meta.arg.direction;
    if (direction === SCROLL_DIRECTION.TOP) {
      state.fetchInteractionsInfo.fetchMoreTopPending = true;
    } else if (direction === SCROLL_DIRECTION.BOTTOM) {
      state.fetchInteractionsInfo.fetchMoreBottomPending = true;
    } else {
      state.fetchInteractionsInfo.pending = true;
    }
  });
  builder.addCase(fetchInteractions.fulfilled, (state, { payload }) => {
    const { userInfluencer } = payload;
    const { data, total, direction } = payload.interactions;
    const timeline = prepareInteractionTimeline(userInfluencer, data);
    if (direction === SCROLL_DIRECTION.TOP) {
      state.fetchInteractionsInfo.fetchMoreTopPending = false;
      state.fetchInteractionsInfo.data = [...timeline, ...state.fetchInteractionsInfo.data];
    } else if (direction === SCROLL_DIRECTION.BOTTOM) {
      state.fetchInteractionsInfo.fetchMoreBottomPending = false;
      state.fetchInteractionsInfo.data = [...state.fetchInteractionsInfo.data, ...timeline];
    } else {
      state.fetchInteractionsInfo.data = timeline;
      state.fetchInteractionsInfo.pending = false;
    }

    state.fetchInteractionsInfo.total = total;
  });
  builder.addCase(fetchInteractions.rejected, state => {
    state.fetchInteractionsInfo.pending = false;
    state.fetchInteractionsInfo.fetchMoreTopPending = false;
    state.fetchInteractionsInfo.fetchMoreBottomPending = false;
  });
};

export const searchActivityInChatBuilder = builder => {
  builder.addCase(searchActivityInChat.pending, state => {
    state.pending.searchActivityInChat = true;
  });
  builder.addCase(searchActivityInChat.fulfilled, (state, { payload }) => {
    const { userInfluencer } = payload;
    const { data, total } = payload.interactions;

    const timeline = prepareInteractionTimeline(userInfluencer, data);

    state.pending.searchActivityInChat = false;
    state.fetchInteractionsInfo.data = timeline;
    state.fetchInteractionsInfo.total = total;
  });
  builder.addCase(searchActivityInChat.rejected, state => {
    state.pending.searchActivityInChat = false;
  });
};
