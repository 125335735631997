import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import {
  createPromptGroupBuilder,
  deletePromptGroupBuilder,
  editPromptGroupBuilder,
  fetchPromptGroupsBuilder,
} from './builder';

const INIT_STATE = {
  fetchPromptGroupInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  pending: {
    editPromptGroup: false,
    createPromptGroup: false,
    deletePromptGroup: false,
  },
};

export const promptGroupSlice = createSlice({
  name: 'promptGroup',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    fetchPromptGroupsBuilder(builder);
    editPromptGroupBuilder(builder);
    deletePromptGroupBuilder(builder);
    createPromptGroupBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectFetchPromptGroupInfo = state => state.promptGroupInfo.fetchPromptGroupInfo;
export const selectPromptGroupActionsPending = state => state.promptGroupInfo.pending;

export default promptGroupSlice.reducer;
