import styled from 'styled-components';
import { Text16Regular } from '../utils/texts/text.styles';

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.4rem;
`;

export const SwitchButton = styled.div`
  position: relative;
  cursor: pointer;
  width: 4.6rem;
  height: 2.6rem;
  border-radius: 12px;
  border: 1px solid #f5f5f5;
  background: #dfdfdf;
  display: flex;
  align-items: center;
  padding-inline: 2px;
`;

export const SwitchCircle = styled.span`
  height: 2rem;
  width: 2rem;
  background-color: ${({ $rightValueSelected }) => ($rightValueSelected ? '#39FF14' : 'gray')};
  border-radius: 10px;
  border: 1px solid white;
  position: absolute;
  transform: ${({ $rightValueSelected }) => $rightValueSelected && 'translateX(100%)'};
  transition: all 0.5s ease-in-out;
`;

export const SwitchText = styled(Text16Regular)``;
