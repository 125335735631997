import styled from 'styled-components';

export const Card = styled.div`
  min-height: 10rem;
  width: 17rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.6rem 2.3rem;
  gap: 1.6rem;
  position: relative;
  border-radius: 12px;
  border: 1px solid #ecd8ff;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
  box-shadow: 0px 1px 2px 0px rgba(14, 24, 41, 0.05);
  cursor: pointer;
`;

export const Avatar = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background: #edd7ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarText = styled.p`
  font-size: 1.2rem;
  font-weight: 700;
  color: #7023db;
  letter-spacing: -0.36px;
`;

export const Background = styled.div`
  position: absolute;
  height: 4.8rem;
  opacity: 0.1;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    color(display-p3 0.4392 0.2392 0.8745) 0%,
    color(display-p3 0.4392 0.2392 0.8745 / 0) 100%
  );
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const LeftIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const RightIcon = styled.div`
  position: absolute;
  top: -14px;
  right: -2px;
  transform: rotate(20.712deg);
`;

export const Circle = styled.div`
  display: flex;
  min-width: 1.6rem;
  height: 1.6rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  background: #7b3aee;
  color: white;
  font-size: 1rem;
  position: absolute;
  top: -0.8rem;
  right: -1.5rem;
  padding: 0 0.2rem;
`;

export const TitleContainer = styled.div`
  position: relative;
`;
