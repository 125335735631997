import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { editPromptGroupFormSchema } from '../../../forms/schemas/prompts.schema';
import { FormInput } from '../../../forms/form.styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectPromptGroupActionsPending } from '../../../store/slices/promptGroup/slice';
import { notifyError, notifyInfo } from '../../../utils/notify';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { createPromptGroup, editPromptGroup } from '../../../store/slices/promptGroup/asyncThunks';

const EditPromptGroupModal = ({ modalRef, selectedPromptGroup, setSelectedPromptGroup }) => {
  const dispatch = useDispatch();
  const { editPromptGroup: editPromptGroupPending, createPromptGroup: createPromptGroupPending } = useSelector(
    selectPromptGroupActionsPending,
  );
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      prompt: {
        mainMessage: {
          base: {
            template: '',
          },
        },
      },
    },
    delayError: 300,
    resolver: yupResolver(editPromptGroupFormSchema),
  });

  useEffect(() => {
    if (selectedPromptGroup) {
      setValue('name', selectedPromptGroup.name);
      setValue('prompt.mainMessage.base.template', selectedPromptGroup.prompt.mainMessage.base.template);
    }
  }, [selectedPromptGroup, setValue]);

  const onSubmit = data => {
    if (selectedPromptGroup) {
      dispatch(editPromptGroup({ promptGroupId: selectedPromptGroup.id, ...data }))
        .unwrap()
        .then(_ => {
          reset();
          modalRef.current.hide();
          notifyInfo('Prompt group edited successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    } else {
      dispatch(createPromptGroup({ ...data }))
        .unwrap()
        .then(_ => {
          reset();
          modalRef.current.hide();
          notifyInfo('Prompt group created successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };

  return (
    <FormModal
      increasedWidth
      ref={modalRef}
      title="Edit Prompt Group"
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
        setSelectedPromptGroup(null);
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText="Submit"
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={editPromptGroupPending || createPromptGroupPending}
      shouldCloseOnOverlayClick={false}>
      <form>
        <FormInput label="Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
        <FormInput
          label="Main message template"
          placeholder="Enter main template"
          {...register('prompt.mainMessage.base.template')}
          error={errors.prompt?.mainMessage?.base?.template?.message}
          type="textarea"
          specificTextAreaHeight={46}
        />
      </form>
    </FormModal>
  );
};

export default EditPromptGroupModal;
