import * as yup from 'yup';

export const uploadFreeAlbumMediumFormSchema = yup
  .object({
    albumMedia: yup.mixed().required('Album media is required'),
    fileId: yup.string().trim().required('File ID is required'),
    aiDescription: yup.string().trim().required('AI Description is required'),
    caption: yup.string().trim().required('Caption is required'),
  })
  .required();

export const uploadBundleAlbumMediumFormSchema = yup
  .object({
    albumMedia: yup.mixed().required('Album media is required'),
    fileId: yup.string().trim().required('File ID is required'),
  })
  .required();

export const uploadPremiumAlbumMediaFormSchema = yup
  .object({
    albumMedia: yup.mixed().required('Album media is required'),
    representativeAlbumMedia: yup.mixed().nullable(),
    cost: yup
      .number()
      .integer()
      .typeError('Cost must be a whole number')
      .required('Cost is required')
      .positive('Cost must be positive'),
    aiDescription: yup.string().trim().required('AI Description is required'),
    caption: yup.string().trim().required('Caption is required'),
    fileId: yup.string().trim().required('File ID is required'),
    tag: yup.mixed().required('Please select tag'),
    representativeFileId: yup.mixed().when('representativeAlbumMedia', {
      is: (value) => value !== null,
      then: () => yup.string().trim().required('Representative File ID is required when Representative media is uploaded'),
      otherwise: () => yup.mixed().nullable(),
    }),
    representativeFileType: yup.mixed().nullable(),
  })
  .required();

export const createMediaBundleFormSchema = yup
  .object({
    caption: yup.string().trim().required('Caption is required'),
    cost: yup
      .number()
      .typeError('Cost must be a number')
      .required('Cost is required')
      .min(0, 'Cost must be positive number'),
    media: yup
      .array()
      .min(2, 'At least 2 media items are required')
      .max(10, 'Maximum of 10 media items are allowed')
      .of(
        yup.object({
          id: yup.string().required(),
          url: yup.string().required(),
          type: yup.string().required(),
        }),
      ),
    representativeAlbumMedia: yup.mixed().nullable(),
    aiDescription: yup.string().trim().required('AI Description is required'),
    representativeFileId: yup.mixed().when('representativeAlbumMedia', {
      is: (value) => !!value,
      then: () => yup.string().trim().required('Representative File ID is required when Representative media is uploaded'),
      otherwise: () => yup.mixed().nullable(),
    }),
    representativeFileType: yup.mixed().nullable(),
  })
  .required();
