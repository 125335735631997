import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/a/influencers';

class InfluencerService {
  fetchInfluencers = async (page, search) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencers.');
    }
  };

  fetchInfluencerById = async id => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${id}`);
      throwErrorIfWrongStatus(response, 200);
      const { influencer } = response.data.data;
      return influencer;
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencers.');
    }
  };

  createInfluencer = async formData => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(`${BASE_URL}`, formData, { headers });
      throwErrorIfWrongStatus(response, 201);
      const { businessConnectionId } = response.data.data;
      return businessConnectionId;
    } catch (err) {
      throw handleError(err, 'Error occurred while creating influencer.');
    }
  };

  editInfluencer = async (influencerId, formData) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.put(`${BASE_URL}/${influencerId}`, formData, { headers });
      throwErrorIfWrongStatus(response, 200);
      const { influencer } = response.data.data;
      return influencer;
    } catch (err) {
      throw handleError(err, 'Error occurred while editing influencer.');
    }
  };

  editPrompts = async (influencerId, body) => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/${influencerId}/prompts`, body);
      throwErrorIfWrongStatus(response, 200);
      const { influencer } = response.data.data;
      return influencer;
    } catch (err) {
      throw handleError(err, 'Error occurred while editing influencer prompts.');
    }
  };

  fetchInfluencerAlbumMedia = async (influencerId, mediaType, mediaCategory, page) => {
    try {
      const pageParam = page ? `&page=${page}` : '';
      const response = await axiosApiClient.get(
        `${BASE_URL}/album-media/${influencerId}?mediaType=${mediaType.toLowerCase()}&mediaCategory=${mediaCategory.toLowerCase()}${pageParam}`,
      );
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total, page };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencers.');
    }
  };

  uploadInfluencerAlbumMedia = async (influencerId, mediaCategory, formData, mediaType) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(
        `${BASE_URL}/${influencerId}/${mediaType.toLowerCase()}/${mediaCategory.toLowerCase()}`,
        formData,
        {
          headers,
        },
      );
      throwErrorIfWrongStatus(response, 200);
      const { media } = response.data.data;
      return { media, mediaCategory };
    } catch (err) {
      throw handleError(err, 'Error occurred while uploading album media');
    }
  };

  editInfluencerAlbumMedia = async (influencerId, albumMediaId, mediaCategory, formData, mediaType) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.put(
        `${BASE_URL}/${influencerId}/${mediaType.toLowerCase()}/${mediaCategory.toLowerCase()}/${albumMediaId}`,
        formData,
        {
          headers,
        },
      );
      throwErrorIfWrongStatus(response, 200);
      const { media } = response.data.data;
      return { media, mediaCategory };
    } catch (err) {
      throw handleError(err, 'Error occurred while editing album media');
    }
  };

  fetchInfluencerVariables = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/variables`);
      throwErrorIfWrongStatus(response, 200);
      const { businessConnections, albumMediaTags, promptGroups, timezones } = response.data.data;
      return { businessConnections, albumMediaTags, promptGroups, timezones };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencer variables');
    }
  };

  changeAlbumMediaPosition = async (influencerId, mediaType, mediaPositions) => {
    try {
      const body = { mediaType, mediaPositions };
      const response = await axiosApiClient.post(`${BASE_URL}/${influencerId}/media/change-position`, body);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while uploading album media');
    }
  };

  fetchInfluencerBundleAlbumPhotos = async (influencerId, page = 1) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/bundle-photos/${influencerId}?page=${page}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total, page };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching bundle album photos.');
    }
  };

  fetchInfluencerBundleAlbumVideos = async (influencerId, page = 1) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/bundle-videos/${influencerId}?page=${page}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total } = response.data.data;
      return { data, total, page };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching bundle album videos.');
    }
  };

  createMediaBundle = async (influencerId, formData) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };

      const response = await axiosApiClient.post(`${BASE_URL}/${influencerId}/media-bundle`, formData, {
        headers,
      });
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while creating bundle.');
    }
  };

  fetchInfluencerMediaBundles = async influencerId => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/media-bundle/${influencerId}`);
      throwErrorIfWrongStatus(response, 200);
      const { bundles } = response.data.data;
      return bundles;
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching bundles.');
    }
  };

  fetchMediaBundleById = async (influencerId, mediaBundleId) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${influencerId}/media-bundle/${mediaBundleId}`);
      throwErrorIfWrongStatus(response, 200);
      const { bundle } = response.data.data;
      return bundle;
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching bundle.');
    }
  };

  editMediaBundle = async (influencerId, mediaBundleId, formData) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };

      const response = await axiosApiClient.put(`${BASE_URL}/${influencerId}/media-bundle/${mediaBundleId}`, formData, {
        headers,
      });
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while editing bundle.');
    }
  };

  changeMediaBundlePosition = async (influencerId, mediaPositions) => {
    try {
      const body = { mediaPositions };
      const response = await axiosApiClient.post(`${BASE_URL}/${influencerId}/media-bundle/change-position`, body);
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while changing bundle position');
    }
  };

  deleteInfluencerAlbumMedia = async (influencerId, albumMediaId, mediaType, mediaCategory, mediaTag) => {
    try {
      const response = await axiosApiClient.delete(
        `${BASE_URL}/${influencerId}/${mediaType.toLowerCase()}/${mediaCategory.toLowerCase()}/${albumMediaId}`,
      );
      throwErrorIfWrongStatus(response, 200);
      return { albumMediaId, mediaCategory, mediaTag };
    } catch (err) {
      throw handleError(err, 'Error occurred while deleting album media');
    }
  };

  deleteMediaBundle = async (influencerId, mediaBundleId) => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${influencerId}/media-bundle/${mediaBundleId}`);
      throwErrorIfWrongStatus(response, 200);
      return { mediaBundleId };
    } catch (err) {
      throw handleError(err, 'Error occurred while deleting media bundle');
    }
  };

  fetchCaptionRecommendations = async (influencerId, mediaId, mediaType) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/${influencerId}/caption-recommendations/${mediaType.toLowerCase()}/${mediaId}`,
      );
      throwErrorIfWrongStatus(response, 200);
      const { captions } = response.data.data;
      return captions;
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching caption recommendations.');
    }
  };

  pingAdminsWithMedia = async (influencerId, fileId, mediaType, notify) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/${influencerId}/ping/${mediaType.toLowerCase()}/${fileId}?notify=${notify}`,
      );
      throwErrorIfWrongStatus(response, 200);
      const { fileLink } = response.data.data;
      return { fileLink };
    } catch (err) {
      throw handleError(err, 'Error occurred while pinging admins with media.');
    }
  };

  sendInstantMassDM = async (influencerId, {text, massDMType, fromAgo, untilAgo}) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/${influencerId}/trigger-instant-mass-dm`, {
        ...(text && { text }),
        ...(massDMType && { massDMType }),
        ...(fromAgo && { fromAgo }),
        ...(untilAgo && { untilAgo }),
      });
      throwErrorIfWrongStatus(response, 200);
    } catch (err) {
      throw handleError(err, 'Error occurred while sending instant mass dm');
    }
  };
}

const influencerService = new InfluencerService();
export default influencerService;
