import styled from 'styled-components';

export const AttachedVideo = styled.video`
  border-radius: 0.8rem; /* Rounded corners for aesthetics */
  width: auto;
  max-width: 50%;
  height: 100%;
  object-fit: contain;
  object-position: left center;
`;
export const LoaderContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: start;
`;

export const Spinner = styled.img`
  width: 20rem;
  height: 20rem;
`;
