import styled from 'styled-components';
import { Text14Light, Text16Bold } from '../../components/utils/texts/text.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled(Text16Bold)`
  font-size: 2.8rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
`;

export const InfluencerInfoContainer = styled.div`
  border-radius: 12px;
  border: 1px solid #d6bcff;
  background: #f7f3ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.6rem;
  width: 44%;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const InfluencerText = styled(Text14Light)`
  color: #1e1827;
  opacity: 0.5;
`;

export const EditButton = styled.button`
  border-radius: 12px;
  border: 1px solid #8e39ff;
  background: #f4efff;
  padding: 0.8rem 1.2rem;
  cursor: pointer;
  color: #793ae9;
  font-size: 1.6rem;
  font-weight: 500;
`;

export const Content = styled.div`
  background-color: white;
  width: 100%;
  height: calc(100% - 13.2rem);
  display: flex;
  border-radius: 12px;
  border: 1px solid #f4f0f9;
  margin-top: 1.8rem;
`;

export const LeftSide = styled.div`
  width: 44%;
  border-right: 1px solid #f4f0f9;
  padding: 1.6rem 0;
`;

export const RightSide = styled.div`
  width: 56%;
`;
