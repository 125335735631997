import { createSlice } from '@reduxjs/toolkit';
import { fetchInteractionsBuilder, searchActivityInChatBuilder } from './builder';
import { LOGOUT } from '../../../utils/constants';

const INIT_STATE = {
  fetchInteractionsInfo: {
    pending: false,
    data: [],
    total: 0,
    fetchMoreTopPending: false,
    fetchMorePendingPending: false,
  },
  pending: {
    searchActivityInChat: false,
  },
};

export const interactionSlice = createSlice({
  name: 'interaction',
  initialState: INIT_STATE,
  reducers: {
    resetInteractionsInfo: state => {
      state.fetchInteractionsInfo = { ...INIT_STATE.fetchInteractionsInfo };
    },
  },
  extraReducers: builder => {
    fetchInteractionsBuilder(builder);
    searchActivityInChatBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const { resetInteractionsInfo } = interactionSlice.actions;

export const selectFetchInteractionsInfo = state => state.interactionInfo.fetchInteractionsInfo;
export const selectSearchActivityInChatPending = state => state.interactionInfo.pending.searchActivityInChat;

export default interactionSlice.reducer;
