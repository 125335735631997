import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInfluencerById, fetchInfluencerVariables } from '../../../store/slices/influencer/asyncThunks';
import {
  selectFetchInfluencerVariablesInfoData,
  selectSelectedInfluencerInfo,
  setSelectedInfluencer,
} from '../../../store/slices/influencer/slice';
import {
  BasicDetailsContainer,
  BasicStatsRow,
  BasicStatsRowSeparator,
  Container,
  GalleryIcon,
  InfluencerEmail,
  InfoLabel,
  InfoValue,
  NavLinkText,
  NavigatorContent,
  NavigatorItem,
  ProfilePhoto,
  TitleRow,
  TabsContainer,
  TabsContentContainer,
  TabsHeaderContainer,
  Verified,
  VideoGalleryIcon,
  QuestionnaireSection,
  BundleGalleryIcon,
  TextLink,
} from './InfluencerDetailsPage.styles';
import { Subtitle, Text16Regular, Title } from '../../../components/utils/texts/text.styles';
import MainLoader from '../../../components/utils/loaders/MainLoader/MainLoader';
import { Divider } from '../../../components/utils/utils.styles';

function InfluencerDetailsPage() {
  const { id: influencerId } = useParams();
  const [questionnaire, setQuestionnaire] = useState([]);
  const { influencer: selectedInfluencer, pending } = useSelector(selectSelectedInfluencerInfo);
  const influencerVariables = useSelector(selectFetchInfluencerVariablesInfoData);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const promptsTabSelected = pathname.includes('prompts');

  useEffect(() => {
    if (selectedInfluencer) {
      const groupedByTitle = selectedInfluencer.questionnaire.reduce((acc, item) => {
        const { title, question, answer } = item;
        let group = acc.find(item => item.title === title);
        if (!group) {
          group = acc.push({ title, questions: [{ question, answer }] });
        } else {
          group.questions.push({ question, answer });
        }
        return acc;
      }, []);
      setQuestionnaire(groupedByTitle);
    }
  }, [selectedInfluencer]);

  useEffect(() => {
    dispatch(fetchInfluencerById(influencerId));
    return () => {
      dispatch(setSelectedInfluencer(null));
    };
  }, [dispatch, influencerId]);

  useEffect(() => {
    if (!influencerVariables) {
      dispatch(fetchInfluencerVariables());
    }
  }, [dispatch, influencerVariables]);

  if (pending || !selectedInfluencer) {
    return (
      <Container>
        <MainLoader />
      </Container>
    );
  }

  return (
    <Container>
      <BasicDetailsContainer>
        <ProfilePhoto src={selectedInfluencer.profilePhoto} />
        <TitleRow>
          <Title>{selectedInfluencer.name}</Title>
          {selectedInfluencer.isVerified && (
            <Verified src={require('../../../assets/images/verified.png')} alt="verified" />
          )}
        </TitleRow>
        <InfluencerEmail>{selectedInfluencer.email}</InfluencerEmail>
        <BasicStatsRow>
          <GalleryIcon />
          <Text16Regular>
            {selectedInfluencer.photosInfo.freePhotosSize +
              selectedInfluencer.photosInfo.bundlePhotosSize +
              selectedInfluencer.photosInfo.premiumPhotosSize}
          </Text16Regular>
          <BasicStatsRowSeparator />
          <VideoGalleryIcon />
          <Text16Regular>
            {selectedInfluencer.videosInfo.freeVideosSize +
              selectedInfluencer.videosInfo.bundleVideosSize +
              selectedInfluencer.videosInfo.premiumVideosSize}
          </Text16Regular>
          <BasicStatsRowSeparator />
          <BundleGalleryIcon />
          <Text16Regular>{selectedInfluencer.bundlesInfo.bundlesSize}</Text16Regular>
        </BasicStatsRow>
        <Divider />
        {promptsTabSelected && !!questionnaire.length ? (
          <>
            {questionnaire.map(({ title, questions }) => (
              <QuestionnaireSection key={title}>
                <Subtitle>{title}</Subtitle>
                {questions.map(({ question, answer }) => (
                  <div key={question}>
                    <InfoLabel>{question}</InfoLabel>
                    <InfoValue>{answer}</InfoValue>
                  </div>
                ))}
              </QuestionnaireSection>
            ))}
          </>
        ) : (
          <>
            <Subtitle>General Information</Subtitle>
            <InfoLabel>Username</InfoLabel>
            <InfoValue>{selectedInfluencer.username}</InfoValue>
            <InfoLabel>Nickname</InfoLabel>
            <InfoValue>{selectedInfluencer.nickname}</InfoValue>
            <InfoLabel>Date Of Birth</InfoLabel>
            <InfoValue>{new Date(selectedInfluencer.dateOfBirth).toLocaleDateString()}</InfoValue>
            <InfoLabel>Payment Ratio</InfoLabel>
            <InfoValue>{selectedInfluencer.paymentRatio}</InfoValue>
            <InfoLabel>Voice Response Ratio</InfoLabel>
            <InfoValue>{selectedInfluencer.voiceResponseRatio}</InfoValue>
            {selectedInfluencer.telegram.channelUrl && (
              <>
                <InfoLabel>Telegram Channel URL</InfoLabel>
                <InfoValue>{selectedInfluencer.telegram.channelUrl}</InfoValue>
              </>
            )}
            <TextLink
              onClick={() =>
                navigate(`/facts/${influencerId}`, { state: { influencerName: selectedInfluencer.name } })
              }>
              View Facts
            </TextLink>
          </>
        )}
      </BasicDetailsContainer>
      <TabsContainer>
        <TabsHeaderContainer>
          <NavigatorContent>
            <NavigatorItem>
              <NavLink to="prompts">
                <NavLinkText>{'Prompts'}</NavLinkText>
              </NavLink>
            </NavigatorItem>
            <NavigatorItem>
              <NavLink to="gallery">
                <NavLinkText>{'Gallery'}</NavLinkText>
              </NavLink>
            </NavigatorItem>
            <NavigatorItem>
              <NavLink to="bundles">
                <NavLinkText>{'Bundles'}</NavLinkText>
              </NavLink>
            </NavigatorItem>
            <NavigatorItem>
              <NavLink to="edit">
                <NavLinkText>{'Edit'}</NavLinkText>
              </NavLink>
            </NavigatorItem>
            <NavigatorItem>
              <NavLink to="mass-dm">
                <NavLinkText>{'Mass DM'}</NavLinkText>
              </NavLink>
            </NavigatorItem>
          </NavigatorContent>
        </TabsHeaderContainer>
        <TabsContentContainer>
          <Outlet />
        </TabsContentContainer>
      </TabsContainer>
    </Container>
  );
}

export default InfluencerDetailsPage;
