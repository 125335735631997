import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  deleteAgencyMember,
  deleteInfluencerFromAgency,
  fetchAgencyById,
  fetchAllInfluencers,
} from '../../../store/slices/agency/asyncThunks';
import {
  selectAgency,
  selectDeleteAgencyMemberPending,
  selectDeleteInfluencerFromAgencyPending,
  setAgency,
} from '../../../store/slices/agency/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Container,
  Content,
  Header,
  IconWrapper,
  InfluencersList,
  ListItem,
  MembersList,
  Row,
  ScrollableContent,
  Spinner,
  SpinnerContainer,
  Title,
} from './AgencyDetailsPage.styles';
import SpinnerGIF from '../../../assets/images/spinner.gif';
import { Text16Bold, Text18Bold } from '../../../components/utils/texts/text.styles';
import AddInfluencerToAgencyModal from '../../../components/modals/AddInfluencerToAgencyModal/AddInfluencerToAgencyModal';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { useConfirmationModal } from '../../../components/utils/modals/ConfirmationModal/ConfirmationModal';
import { notifyError, notifyInfo } from '../../../utils/notify';
import AddAgencyMemberFormModal from '../../../components/modals/AddAgencyMemberFormModal/AddAgencyMemberFormModal';

const AgencyDetailsPage = () => {
  const { id: agencyId } = useParams();
  const dispatch = useDispatch();
  const agency = useSelector(selectAgency);
  const addInfluencerToAgencyModalRef = useRef();
  const addAgencyMemberFormModalRef = useRef();
  const [openModal, closeModal, setConfirmationModalLoader] = useConfirmationModal();
  const deleteInfluencerFromAgencyPending = useSelector(selectDeleteInfluencerFromAgencyPending);
  const deleteAgencyMemberPending = useSelector(selectDeleteAgencyMemberPending);

  useEffect(() => {
    setConfirmationModalLoader(deleteAgencyMemberPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAgencyMemberPending]);

  useEffect(() => {
    setConfirmationModalLoader(deleteInfluencerFromAgencyPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteInfluencerFromAgencyPending]);

  useEffect(() => {
    dispatch(fetchAgencyById({ agencyId }));
    return () => {
      dispatch(setAgency(null));
    };
  }, [dispatch, agencyId]);

  if (!agency) {
    return (
      <SpinnerContainer>
        <Spinner src={SpinnerGIF} />
      </SpinnerContainer>
    );
  }

  const deleteInfluencerFromAgencyClick = influencerId => {
    openModal(
      'Delete Influencer from Agency',
      `Are you sure you want to delete influencer from agency?`,
      'Delete',
      () => {
        dispatch(deleteInfluencerFromAgency({ agencyId, influencerId }))
          .unwrap()
          .then(() => {
            dispatch(fetchAllInfluencers());
            notifyInfo('Influencer deleted from agency successfully');
            closeModal();
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Cancel',
      () => {
        closeModal();
      },
    );
  };

  const onDeleteAgencyMemberClick = agencyMemberId => {
    openModal(
      'Delete Agency Member',
      `Are you sure you want to delete agency member?`,
      'Delete',
      () => {
        dispatch(deleteAgencyMember({ agencyMemberId }))
          .unwrap()
          .then(() => {
            notifyInfo('Agency member deleted successfully');
            closeModal();
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Cancel',
      () => {
        closeModal();
      },
    );
  };

  return (
    <Container>
      <Header>
        <Title>Agency: {agency?.name}</Title>
      </Header>
      <Content>
        <InfluencersList>
          <Row>
            <Text18Bold>Influencers</Text18Bold>
            <Button title="Add Influencer" onClick={() => addInfluencerToAgencyModalRef.current.show()} />
          </Row>
          <ScrollableContent>
            {agency.influencers.map((influencer, index) => (
              <ListItem key={influencer.id}>
                <Text16Bold>{index + 1}.</Text16Bold>
                <Text16Bold>{`${influencer.name} (${influencer.username})`}</Text16Bold>
                <IconWrapper onClick={() => deleteInfluencerFromAgencyClick(influencer.id)}>
                  <DeleteIcon />
                </IconWrapper>
              </ListItem>
            ))}
          </ScrollableContent>
        </InfluencersList>
        <MembersList>
          <Row>
            <Text18Bold>Members</Text18Bold>
            <Button
              title="Add Member"
              onClick={() => {
                addAgencyMemberFormModalRef.current.show();
              }}
            />
          </Row>
          <ScrollableContent>
            {agency.agencyMembers.map((agencyMember, index) => (
              <ListItem key={agencyMember.id}>
                <Text16Bold>{index + 1}.</Text16Bold>
                <Text16Bold>{`${agencyMember.name} (${agencyMember.email})`}</Text16Bold>
                <IconWrapper onClick={() => onDeleteAgencyMemberClick(agencyMember.id)}>
                  <DeleteIcon />
                </IconWrapper>
              </ListItem>
            ))}
          </ScrollableContent>
        </MembersList>
      </Content>
      <AddInfluencerToAgencyModal modalRef={addInfluencerToAgencyModalRef} />
      <AddAgencyMemberFormModal modalRef={addAgencyMemberFormModalRef} />
    </Container>
  );
};

export default AgencyDetailsPage;
