import React, { useEffect, useRef } from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormInput, FormNumericInput } from '../../../forms/form.styles';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { Text16Regular } from '../../utils/texts/text.styles';
import {
  DeleteIconWrapper,
  MediaCardContainer,
  PriceInUsd,
  Row,
  SelectedMediaContainer,
  SelectMedia,
  SelectMediaContainer,
} from './CreateMediaBundleFormModal.styles';
import ErrorContainer from '../../utils/ErrorContainer/ErrorContainer';
import SelectMediaModal from '../SelectMediaModal/SelectMediaModal';
import { createMediaBundleFormSchema } from '../../../forms/schemas/albumMedia.schema';
import Video from '../../utils/Video/Video';
import Image from '../../utils/Image/Image';
import { MEDIA_TYPE, UPLOAD_FILE_CONSTRAINTS, VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import {
  selectCreateMediaBundlePending,
  selectEditMediaBundlePending,
  selectFetchMediaBundleByIdPending,
} from '../../../store/slices/influencer/slice';
import { createMediaBundle, editMediaBundle, fetchMediaBundleById } from '../../../store/slices/influencer/asyncThunks';
import MainLoader from '../../utils/loaders/MainLoader/MainLoader';
import FileInput from '../../utils/inputs/FileInput/FileInput';

const CreateMediaBundleFormModal = ({ modalRef, onSuccess, selectedMediaBundle, setSelectedMediaBundle }) => {
  const selectMediaModalRef = useRef();
  const createMediaBundlePending = useSelector(selectCreateMediaBundlePending);
  const editMediaBundlePending = useSelector(selectEditMediaBundlePending);
  const fetchMediaBundleByIdPending = useSelector(selectFetchMediaBundleByIdPending);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      media: [],
      representativeAlbumMedia: null,
      caption: '',
      aiDescription: '',
      cost: '',
      representativeFileId: '',
    },
    delayError: 300,
    resolver: yupResolver(createMediaBundleFormSchema),
  });

  const mediaWatch = watch('media');
  const bundlePriceInStars = watch('cost');
  const representativeFile = watch('representativeAlbumMedia');

  useEffect(() => {
    if (selectedMediaBundle) {
      dispatch(fetchMediaBundleById({ mediaBundleId: selectedMediaBundle }))
        .unwrap()
        .then(bundle => {
          setValue('media', bundle.media);
          setValue('caption', bundle.caption);
          setValue('aiDescription', bundle.aiDescription);
          setValue('cost', bundle.cost);
          setValue('representativeFileId', bundle.representativeFileId);
          if (bundle.representativeUrl || bundle.representativeAlbumMedia) {
            setValue(
              'representativeAlbumMedia',
              bundle.representativeUrl ? bundle.representativeUrl : bundle.representativeAlbumMedia,
            );
          }
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  }, [selectedMediaBundle, dispatch, setValue]);

  const onSubmit = data => {
    let fileExtension = null;
    let representativeFileType = null;

    if (data.representativeAlbumMedia) {
      const mediaSource =
        typeof data.representativeAlbumMedia === 'string'
          ? data.representativeAlbumMedia
          : data.representativeAlbumMedia.file.name;

      const match = mediaSource.match(/\.([a-zA-Z0-9]+)(?=\?|$)/);
      fileExtension = match ? match[1] : null;
    }

    // Determine representative file type based on the file extension
    if (UPLOAD_FILE_CONSTRAINTS.PHOTO.ALLOWED_EXTENSIONS.includes(fileExtension)) {
      representativeFileType = UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO;
    } else if (UPLOAD_FILE_CONSTRAINTS.VIDEO.ALLOWED_EXTENSIONS.includes(fileExtension)) {
      representativeFileType = UPLOAD_FILE_CONSTRAINTS.TYPE.VIDEO;
    }

    if (selectedMediaBundle) {
      dispatch(
        editMediaBundle({
          caption: data.caption,
          aiDescription: data.aiDescription,
          mediaBundleId: selectedMediaBundle,
          cost: data.cost,
          representativeAlbumMedia: data.representativeAlbumMedia,
          representativeFileId: data.representativeFileId,
          representativeFileType,
        }),
      )
        .unwrap()
        .then(() => {
          reset();
          modalRef.current.hide();
          notifyInfo('Bundle updated successfully!');
          onSuccess();
        })
        .catch(err => {
          notifyError(err.message);
        });
    } else {
      const mediaData = data.media.map(media => {
        return {
          mediaId: media.id,
          type: media.type,
        };
      });

      dispatch(
        createMediaBundle({
          cost: data.cost,
          caption: data.caption,
          aiDescription: data.aiDescription,
          media: mediaData,
          representativeAlbumMedia: data.representativeAlbumMedia,
          representativeFileId: data.representativeFileId,
          representativeFileType,
        }),
      )
        .unwrap()
        .then(() => {
          reset();
          modalRef.current.hide();
          notifyInfo('Bundle created successfully!');
          onSuccess();
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };

  const onSelectMediaClick = () => {
    selectMediaModalRef.current.show();
  };

  const onDeleteMedia = mediaId => {
    let data = [...mediaWatch];
    data = data.filter(media => media.id !== mediaId);
    setValue('media', data);
  };

  return (
    <FormModal
      ref={modalRef}
      title={selectedMediaBundle ? 'Edit Bundle' : 'Create Bundle'}
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
        setSelectedMediaBundle(null);
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText={selectedMediaBundle ? 'Save' : 'Create'}
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={createMediaBundlePending || editMediaBundlePending}
      shouldCloseOnOverlayClick={false}>
      {fetchMediaBundleByIdPending ? (
        <MainLoader />
      ) : (
        <form>
          <SelectMediaContainer>
            <Text16Regular>Media</Text16Regular>
            {!selectedMediaBundle && <SelectMedia onClick={onSelectMediaClick}>Select Media</SelectMedia>}
          </SelectMediaContainer>
          <SelectedMediaContainer>
            {mediaWatch.map(item => {
              return (
                <MediaCard
                  key={item.id}
                  item={item}
                  type={item.type}
                  {...(!selectedMediaBundle && { onDeleteClick: () => onDeleteMedia(item.id) })}
                />
              );
            })}
          </SelectedMediaContainer>
          <ErrorContainer errorText={errors.media?.message} />

          <Controller
            name="representativeAlbumMedia"
            control={control}
            render={({ field }) => {
              const onDeleteRepresentativeFile = () => {
                setValue('representativeFileId', '');
                field.onChange(null);
              };
              return (
                <FileInput
                  label="Representative media"
                  value={field.value}
                  onChange={field.onChange}
                  type={UPLOAD_FILE_CONSTRAINTS.TYPE.REPRESENTATIVE_MEDIA}
                  error={errors.representativeAlbumMedia?.message}
                  increasedFileHeight
                  onDeleteClick={field.value ? onDeleteRepresentativeFile : null}
                />
              );
            }}
          />

          <FormInput
            label="Representative File ID"
            placeholder="Enter representative file ID"
            {...register('representativeFileId')}
            error={errors.representativeFileId?.message}
            disabled={!representativeFile}
          />

          <FormInput
            label="AI description"
            placeholder="Enter AI description"
            {...register('aiDescription')}
            error={errors.aiDescription?.message}
          />
          <FormInput
            label="Caption"
            placeholder="Enter caption"
            {...register('caption')}
            error={errors.caption?.message}
          />
          <Row>
            <FormNumericInput
              label="Cost (⭐️)"
              {...register('cost', {
                valueAsNumber: true,
              })}
              error={errors.cost?.message}
            />
            <PriceInUsd>{`= $${((bundlePriceInStars || 0) * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}`}</PriceInUsd>
          </Row>
        </form>
      )}
      <SelectMediaModal
        modalRef={selectMediaModalRef}
        onSelect={media => setValue('media', [...mediaWatch, ...media], { shouldValidate: true })}
        chosenMedia={mediaWatch}
      />
    </FormModal>
  );
};

const MediaCard = ({ item, type, onDeleteClick }) => {
  return (
    <MediaCardContainer>
      {onDeleteClick && (
        <DeleteIconWrapper onClick={onDeleteClick}>
          <DeleteIcon width={20} height={20} fill="white" />
        </DeleteIconWrapper>
      )}
      {type === MEDIA_TYPE.PHOTO ? (
        <Image src={item.thumbnailUrl || item.url} alt="selected-premium-photo-media" />
      ) : (
        item.thumbnailUrl ? (
          <Image src={item.thumbnailUrl} alt="selected-premium-video-thumbnail" />
        ) : (
          <Video src={item.url} />
        )
      )}
    </MediaCardContainer>
  );
};

export default CreateMediaBundleFormModal;
