import { createSlice } from '@reduxjs/toolkit';
import {
  changePasswordBuilder,
  fetchUserByIdBuilder,
  fetchUserInfoBuilder,
  fetchUsersBuilder,
  fetchUsersByInfluencerBuilder,
  loginBuilder,
} from './builder';
import { LOGOUT } from '../../../utils/constants';

const INIT_STATE = {
  fetchUserInfoPending: false,
  user: {
    id: null,
    email: null,
    fullName: null,
    role: null,
    roleSpecificData: null,
  },
  fetchUsersInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  fetchUsersByInfluencerInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  selectedUserInfo: {
    pending: false,
    user: null,
  },
  selectedUserInfluencerInfo: {
    userInfluencer: null,
  },
  pending: {
    changePassword: false,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState: INIT_STATE,
  reducers: {
    setSelectedUser: (state, { payload }) => {
      state.selectedUserInfo.user = payload;
    },
    setSelectedUserInfluencer: (state, { payload }) => {
      state.selectedUserInfluencerInfo.userInfluencer = payload;
    },
    setRoleSpecificData: (state, { payload }) => {
      state.user.roleSpecificData = payload;
    },
  },
  extraReducers: builder => {
    fetchUserInfoBuilder(builder);
    loginBuilder(builder);
    fetchUsersBuilder(builder);
    fetchUserByIdBuilder(builder);
    fetchUsersByInfluencerBuilder(builder);
    changePasswordBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectFetchUserInfoPending = state => state.userInfo.fetchUserInfoPending;
export const selectUser = state => state.userInfo.user;
export const selectFetchUsersInfo = state => state.userInfo.fetchUsersInfo;
export const selectFetchUsersByInfluencerInfo = state => state.userInfo.fetchUsersByInfluencerInfo;
export const selectSelectedUserInfo = state => state.userInfo.selectedUserInfo;
export const selectSelectedUserInfluencerInfo = state => state.userInfo.selectedUserInfluencerInfo;
export const selectChangePasswordPending = state => state.userInfo.pending.changePassword;

export const { setRoleSpecificData, setSelectedUser, setSelectedUserInfluencer } = userSlice.actions;

export default userSlice.reducer;
