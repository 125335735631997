import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateInfluencerForm, Footer, FooterLeftButton, FooterRightButton } from '../../CreateInfluencerPage.styles';
import {
  AnswerText,
  Container,
  ContainerFooter,
  ContainerHeader,
  QuestionContainer,
  QuestionnaireContainer,
  QuestionnaireSection,
  QuestionnaireTitle,
  Row,
} from './CreateInfluencerPageStepPrompts.styles';
import { ReactComponent as SecretModeIcon } from '../../../../../assets/icons/secret-mode.svg';
import { Text15Bold, Text20Bold } from '../../../../../components/utils/texts/text.styles';
import ErrorContainer from '../../../../../components/utils/ErrorContainer/ErrorContainer';
import { ReactComponent as ForwardIcon } from '../../../../../assets/icons/forward.svg';
import { createPromptsAsAdminFormSchema } from '../../../../../forms/schemas/prompts.schema';
import { FormInput } from '../../../../../forms/form.styles';
import { useSelector } from 'react-redux';
import { selectCreateInfluencerPending } from '../../../../../store/slices/influencer/slice';

function CreateInfluencerPageStepPrompts({ step, onAfterSubmit, onBack, formData, influencerRequest }) {
  const createInfluencerPending = useSelector(selectCreateInfluencerPending);
  const [questionnaire, setQuestionnaire] = useState([]);

  useEffect(() => {
    if (influencerRequest) {
      const groupedByTitle = influencerRequest.questionnaire.reduce((acc, item) => {
        const { title, question, answer } = item;

        let group = acc.find(item => item.title === title);

        if (!group) {
          group = acc.push({ title, questions: [{ question, answer }] });
        } else {
          group.questions.push({ question, answer });
        }

        return acc;
      }, []);

      setQuestionnaire(groupedByTitle);
    }
  }, [influencerRequest]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      prompt: {
        male: {
          mainMessage: {
            base: {
              template: '',
            },
          },
        },
      },
    },
    delayError: 300,
    resolver: yupResolver(createPromptsAsAdminFormSchema),
  });

  useEffect(() => {
    if (formData.prompt) {
      setValue('prompt', formData.prompt.prompt);
    }
  }, [formData.prompt, setValue]);

  const onSubmit = data => {
    onAfterSubmit(data, step);
  };

  return (
    <>
      <CreateInfluencerForm>
        <Container>
          <ContainerHeader>
            <Row>
              <SecretModeIcon />
              <Text20Bold>Prompts</Text20Bold>
            </Row>
          </ContainerHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              label="Main message template"
              placeholder="Enter main template"
              {...register('prompt.male.mainMessage.base.template')}
              error={errors.prompt?.male?.mainMessage?.base?.template?.message}
              type="textarea"
              specificTextAreaHeight={46}
            />
          </form>
          <ContainerFooter>
            <ErrorContainer errorText={errors.prompt?.message} />
          </ContainerFooter>
        </Container>
        {!!questionnaire.length && (
          <QuestionnaireContainer>
            <Text20Bold>Questionnaire</Text20Bold>
            {questionnaire.map(({ title, questions }) => (
              <QuestionnaireSection key={title}>
                <QuestionnaireTitle>{title}</QuestionnaireTitle>
                {questions.map(({ question, answer }) => (
                  <QuestionContainer key={question}>
                    <Text15Bold>{question}</Text15Bold>
                    <AnswerText>{answer}</AnswerText>
                  </QuestionContainer>
                ))}
              </QuestionnaireSection>
            ))}
          </QuestionnaireContainer>
        )}
      </CreateInfluencerForm>
      <Footer>
        <FooterLeftButton title="Previous Step" onClick={() => onBack(getValues(), step)} />
        <FooterRightButton
          isLoading={createInfluencerPending}
          title="Create influencer"
          icon={<ForwardIcon fill="#fff" />}
          onClick={() => handleSubmit(onSubmit)()}
        />
      </Footer>
    </>
  );
}

export default CreateInfluencerPageStepPrompts;
