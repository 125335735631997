import axios from 'axios';
import { BASE_URL, LOGOUT } from '../utils/constants.js';
import { setAccessToken } from '../store/slices/auth/slice.js';

let store;

export const injectStore = _store => {
  store = _store;
};

const axiosApiFreeClient = axios.create({
  baseURL: `${BASE_URL}/auth`,
});

const axiosApiClient = axios.create({
  baseURL: `${BASE_URL}/api`,
});

let failedRequests = [];
let isTokenRefreshing = false;

axiosApiFreeClient.interceptors.request.use(
  async config => {
    config.headers['X-MY-CSRF'] = 'fjdafhsiheiqrjowerj';
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

axiosApiClient.interceptors.request.use(
  async config => {
    config.headers['X-MY-CSRF'] = 'jdafhsiheiqrjowerj';
    const token = store.getState().auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

axiosApiClient.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const status = error.response?.status;
    const originalRequestConfig = error.config;

    if (status !== 401) {
      return Promise.reject(error);
    }

    if (isTokenRefreshing) {
      return new Promise((resolve, reject) => {
        failedRequests.push({
          resolve,
          reject,
          config: originalRequestConfig,
          error: error,
        });
      });
    }

    isTokenRefreshing = true;

    try {
      const refreshResponse = await axiosApiFreeClient.post('/a/refresh-token', {}, { withCredentials: true });
      const { accessToken } = refreshResponse.data.data;
      store.dispatch(setAccessToken(accessToken));
      failedRequests.forEach(({ resolve, reject, config }) => {
        axiosApiClient(config)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    } catch (error) {
      failedRequests.forEach(({ reject, error }) => reject(error));
      store.dispatch({ type: LOGOUT });
      return Promise.reject(error);
    } finally {
      failedRequests = [];
      isTokenRefreshing = false;
    }

    return axiosApiClient(originalRequestConfig);
  },
);

export default axiosApiClient;
export { axiosApiFreeClient };
