import React, { Fragment, useEffect, useState } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { AuthLayout, PrivateLayout, PublicLayout } from '../../layouts';
import GlobalStyles from '../../styles/globalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from '../../store/slices/user/asyncThunks';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/fonts.css';
import { selectLoggedIn } from '../../store/slices/auth/slice';
import InfluencerDetailsPage from '../../pages/influencers/InfluencerDetailsPage/InfluencerDetailsPage';
import ForbiddenPage from '../../pages/util/ForbiddenPage/ForbiddenPage';
import { ADMIN_ROLES, MEDIA_CATEGORY, MEDIA_TYPE } from '../../utils/constants';
import PrivateOutlet from '../navigations/PrivateOutlet/PrivateOutlet';
import PaginationOutlet from '../navigations/PaginationOutlet/PaginationOutlet';
import InfluencerEditTab from '../../pages/influencers/tabs/InfluencerEditTab/InfluencerEditTab';
import InfluencerGalleriesListTab from '../../pages/influencers/tabs/InfluencerGalleryTab/InfluencerGalleriesListTab/InfluencerGalleriesListTab';
import LandingPage from '../../pages/LandingPage/LandingPage';
import LoginPage from '../../pages/auth/LoginPage/LoginPage';
import SplashPage from '../../pages/util/SplashPage/SplashPage';
import PageNotFound from '../../pages/util/PageNotFound/PageNotFound';
import InfluencersListPage from '../../pages/influencers/InfluencersListPage/InfluencersListPage';
import CreateInfluencerPage from '../../pages/influencers/CreateInfluencerPage/CreateInfluencerPage';
import InvitationCodesPage from '../../pages/InvitationCodesPage/InvitationCodesPage';
import InfluencerRequestsPage from '../../pages/InfluencerRequestsPage/InfluencerRequestsPage';
import InfluencerPromptsTab from '../../pages/influencers/tabs/InfluencerPromptsTab/InfluencerPromptsTab';
import InfluencerMediaBundlesTab from '../../pages/influencers/tabs/InfluencerMediaBundlesTab/InfluencerMediaBundlesTab';
import InfluencerMassDmTab from '../../pages/influencers/tabs/InfluencerMassDmTab/InfluencerMassDmTab';
import WithdrawalsPage from '../../pages/WithdrawalsPage/WithdrawalsPage';
import PromptGroupsPage from '../../pages/PromptGroupsPage/PromptGroupsPage';
import PageableGalleryTab from '../../pages/influencers/tabs/InfluencerGalleryTab/PageableGalleryTab/PageableGalleryTab';
import DragAndDropGalleryTab from '../../pages/influencers/tabs/InfluencerGalleryTab/DragAndDropGalleryTab/DragAndDropGalleryTab';
import UserDetailsPage from '../../pages/users/UserDetailsPage/UserDetailsPage';
import UsersPage from '../../pages/users/UsersPage/UsersPage';
import UserInfluencersPage from '../../pages/users/UserInfluencersPage/UserInfluencersPage';

import UserInfluencerDetailsPage from '../../pages/users/UserInfluencerDetailsPage/UserInfluencerDetailsPage';
import AgenciesListPage from '../../pages/agencies/AgenciesListPage/AgenciesListPage';
import AgencyDetailsPage from '../../pages/agencies/AgencyDetailsPage/AgencyDetailsPage';
import FactsPage from '../../pages/FactsPage/FactsPage';
import InfluencerFactsPage from '../../pages/InfluencerFactsPage/InfluencerFactsPage';
import SettingsPage from '../../pages/SettingsPage/SettingsPage';

function App() {
  const [minimumDurationPassed, setMinimumDurationPassed] = useState(false);
  const isAuthenticated = useSelector(selectLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => setMinimumDurationPassed(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  return (
    <Fragment>
      <GlobalStyles />
      {minimumDurationPassed && isAuthenticated !== null ? (
        <>
          <ToastContainer newestOnTop={true} autoClose={3500} position={toast.POSITION.TOP_RIGHT} />
          <Routes>
            <Route element={<PublicLayout />}>
              <Route path="/" element={<LandingPage />} />
            </Route>
            <Route element={<AuthLayout />}>
              <Route path="/login" element={<LoginPage />} />
            </Route>
            <Route element={<PrivateLayout />}>
              <Route element={<PrivateOutlet roles={ADMIN_ROLES} />}>
                <Route element={<PaginationOutlet />}>
                  <Route path="/influencers" element={<InfluencersListPage />} />
                  <Route path="/influencer-requests" element={<InfluencerRequestsPage />} />
                  <Route path="/withdrawals" element={<WithdrawalsPage />} />
                  <Route path="/prompt-groups" element={<PromptGroupsPage />} />
                  <Route path="/users" element={<UsersPage />} />
                  <Route path="/users/influencers" element={<UserInfluencersPage />} />
                  <Route path="/agencies" element={<AgenciesListPage />} />
                  <Route path="/settings" element={<SettingsPage />} />
                </Route>
                <Route path="/users/:userId" element={<UserDetailsPage />}>
                  <Route path="influencer/:userInfluencerId" element={<UserInfluencerDetailsPage />}></Route>
                </Route>
                <Route path="/agencies/:id" element={<AgencyDetailsPage />} />
                <Route path="/facts" element={<FactsPage />} />
                <Route path="/facts/:id" element={<InfluencerFactsPage />} />
                <Route path="/influencers/new" element={<CreateInfluencerPage />} />
                <Route path="/influencers/:id" element={<InfluencerDetailsPage />}>
                  <Route index element={<Navigate to={'prompts'} replace />} />
                  <Route path="gallery" element={<Outlet />}>
                    <Route index element={<Navigate to="list" replace />} />
                    <Route path="list" element={<InfluencerGalleriesListTab />} />
                    <Route
                      path="free-photo"
                      element={<PageableGalleryTab mediaType={MEDIA_TYPE.PHOTO} mediaCategory={MEDIA_CATEGORY.FREE} />}
                    />
                    <Route
                      path="bundle-photo"
                      element={
                        <PageableGalleryTab mediaType={MEDIA_TYPE.PHOTO} mediaCategory={MEDIA_CATEGORY.BUNDLE} />
                      }
                    />
                    <Route
                      path="premium-photo"
                      element={
                        <DragAndDropGalleryTab mediaType={MEDIA_TYPE.PHOTO} mediaCategory={MEDIA_CATEGORY.PREMIUM} />
                      }
                    />
                    <Route
                      path="free-video"
                      element={<PageableGalleryTab mediaType={MEDIA_TYPE.VIDEO} mediaCategory={MEDIA_CATEGORY.FREE} />}
                    />
                    <Route
                      path="bundle-video"
                      element={
                        <PageableGalleryTab mediaType={MEDIA_TYPE.VIDEO} mediaCategory={MEDIA_CATEGORY.BUNDLE} />
                      }
                    />
                    <Route
                      path="premium-video"
                      element={
                        <DragAndDropGalleryTab mediaType={MEDIA_TYPE.VIDEO} mediaCategory={MEDIA_CATEGORY.PREMIUM} />
                      }
                    />
                  </Route>
                  <Route path="prompts" element={<InfluencerPromptsTab />} />
                  <Route path="edit" element={<InfluencerEditTab />} />
                  <Route path="bundles" element={<InfluencerMediaBundlesTab />} />
                  <Route path="mass-dm" element={<InfluencerMassDmTab />} />
                </Route>
                <Route element={<PaginationOutlet />}>
                  <Route path="invitation-codes" element={<InvitationCodesPage />} />
                </Route>
              </Route>
            </Route>
            <Route path="/forbidden" element={<ForbiddenPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </>
      ) : (
        <SplashPage />
      )}
    </Fragment>
  );
}

export default App;
