import styled from 'styled-components';
import { Text16Bold } from '../../components/utils/texts/text.styles';
import Input from '../../components/utils/inputs/Input/Input';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

export const Title = styled(Text16Bold)`
  font-size: 2.8rem;
  margin-left: 1rem;
`;

export const SearchInput = styled(Input)`
  width: 100%;
  max-width: 36rem;
`;

export const Content = styled.div`
  display: flex;
  gap: 1.7rem;
  flex-wrap: wrap;
`;

export const Spinner = styled.img`
  height: 30rem;
  object-fit: contain;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const InfiniteScrollMarker = styled.div`
  height: 1rem;
  width: 1rem;
`;
