import React, { useCallback, useEffect } from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { ClearAllText, Container, InputContainer, Row } from './UserInfluencerFiltersModal.styles';
import { Text16Bold } from '../../utils/texts/text.styles';
import { FormNumericInput } from '../../../forms/form.styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import localStorageService from '../../../services/localStorageService';
import {
  USER_INFLUENCERS_QUERY_LAST_N_DAYS_FILTER_KEYS,
  USER_INFLUENCERS_QUERY_RANGE_FILTER_KEYS,
} from '../../../utils/constants';
import { filterFormSchema } from '../../../forms/schemas/userInfluncerFilter.schema';

export const FILTER_TITLES = {
  videoOffersCount: 'Offered Videos',
  photoOffersCount: 'Offered Photos',
  bundleOffersCount: 'Offered Bundles',
  subscriptionOffersCount: 'Offered Subscriptions',
  videoPurchaseCount: 'Purchased Videos',
  photoPurchaseCount: 'Purchased Photos',
  bundlePurchaseCount: 'Purchased Bundles',
  subscriptionPurchaseCount: 'Purchased Subscriptions',
  totalMessages: 'Total Messages',
  totalSpent: 'Total Spent',
  didNotPurchaseInLastNDays: 'Did not purchase in last N days',
  didNotSendMessageInLastNDays: 'Did not send message in last N days',
};

const UserInfluencerFiltersModal = ({ modalRef, setFilters, filters }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      videoOffersCount: { min: '', max: '' },
      photoOffersCount: { min: '', max: '' },
      bundleOffersCount: { min: '', max: '' },
      subscriptionOffersCount: { min: '', max: '' },
      videoPurchaseCount: { min: '', max: '' },
      photoPurchaseCount: { min: '', max: '' },
      bundlePurchaseCount: { min: '', max: '' },
      subscriptionPurchaseCount: { min: '', max: '' },
      totalMessages: { min: '', max: '' },
      totalSpent: { min: '', max: '' },
      didNotPurchaseInLastNDays: '',
      didNotSendMessageInLastNDays: '',
    },
    delayError: 300,
    resolver: yupResolver(filterFormSchema),
  });

  const onSubmit = data => {
    const filter = {};

    Object.entries(data).forEach(([key, value]) => {
      if ((value.min || value.max) && USER_INFLUENCERS_QUERY_RANGE_FILTER_KEYS.includes(key)) {
        filter[key] = { min: value.min, max: value.max };
      } else if (value && USER_INFLUENCERS_QUERY_LAST_N_DAYS_FILTER_KEYS.includes(key)) {
        filter[key] = +value;
      }
    });

    setFilters(filter);
    localStorageService.setItem('userInfluencersFilter', filter);
    modalRef.current.hide();
  };

  const setInitialValues = useCallback(() => {
    USER_INFLUENCERS_QUERY_RANGE_FILTER_KEYS.forEach(key => {
      if (!filters[key]) {
        setValue(`${key}.min`, '');
        setValue(`${key}.max`, '');
      } else {
        setValue(`${key}.min`, filters[key].min);
        setValue(`${key}.max`, filters[key].max);
      }
    });

    USER_INFLUENCERS_QUERY_LAST_N_DAYS_FILTER_KEYS.forEach(key => {
      if (!filters[key]) {
        setValue(`${key}`, '');
      } else {
        setValue(`${key}`, filters[key]);
      }
    });
  }, [filters, setValue]);

  useEffect(() => {
    setInitialValues(filters);
  }, [filters, setInitialValues]);

  return (
    <FormModal
      ref={modalRef}
      title="Filter"
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        setInitialValues(filters);
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText="Apply"
      onRightButtonClick={() => handleSubmit(onSubmit)()}>
      <Container>
        <ClearAllText
          onClick={() => {
            reset();
          }}>
          Clear All
        </ClearAllText>
        {USER_INFLUENCERS_QUERY_RANGE_FILTER_KEYS.map(key => {
          return (
            <Row key={key}>
              <Text16Bold>{FILTER_TITLES[key]}</Text16Bold>
              <InputContainer>
                <FormNumericInput placeholder="From" {...register(`${key}.min`)} error={errors[key]?.min?.message} />
                <FormNumericInput placeholder="To" {...register(`${key}.max`)} error={errors[key]?.max?.message} />
              </InputContainer>
            </Row>
          );
        })}
        {USER_INFLUENCERS_QUERY_LAST_N_DAYS_FILTER_KEYS.map(key => {
          return (
            <Row key={key}>
              <Text16Bold>{FILTER_TITLES[key]}</Text16Bold>
              <InputContainer>
                <FormNumericInput placeholder="Enter number of days" {...register(key)} error={errors[key]?.message} />
              </InputContainer>
            </Row>
          );
        })}
      </Container>
    </FormModal>
  );
};

export default UserInfluencerFiltersModal;
