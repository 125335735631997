import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import {
  fetchInfluencersBuilder,
  editInfluencerBuilder,
  fetchInfluencerByIdBuilder,
  fetchInfluencerAlbumMediaBuilder,
  createInfluencerBuilder,
  fetchInfluencerVariablesBuilder,
  editPromptsBuilder,
  createMediaBundleBuilder,
  fetchInfluencerMediaBundlesBuilder,
  fetchMediaBundleByIdBuilder,
  deleteInfluencerAlbumMediaBuilder,
  editInfluencerAlbumMediaBuilder,
  uploadInfluencerAlbumMediaBuilder,
  deleteMediaBundleBuilder,
  fetchCaptionRecommendationsBuilder,
  editMediaBundleBuilder,
  pingAdminsWithMediaBuilder,
  sendInstantMassDMBuilder,
  fetchInfluencerBundleAlbumPhotosBuilder,
  fetchInfluencerBundleAlbumVideosBuilder,
} from './builder';

const INIT_STATE = {
  fetchInfluencersInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  selectedInfluencerInfo: {
    pending: false,
    influencer: null,
  },
  fetchInfluencerAlbumMediaInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  fetchInfluencerBundleAlbumPhotosInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  fetchInfluencerBundleAlbumVideosInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  fetchInfluencerMediaBundlesInfo: {
    pending: false,
    data: [],
  },
  fetchInfluencerVariablesInfo: {
    pending: false,
    data: null,
  },
  pending: {
    editInfluencer: false,
    editPrompts: false,
    uploadAlbumMedia: false,
    createInfluencer: false,
    createMediaBundle: false,
    fetchMediaBundleById: false,
    editMediaBundle: false,
    deleteAlbumMedia: false,
    editAlbumMedia: false,
    deleteMediaBundle: false,
    fetchCaptionRecommendations: false,
    pingAdminsWithMedia: false,
    sendInstantMassDM: false,
  },
};

export const influencerSlice = createSlice({
  name: 'influencer',
  initialState: INIT_STATE,
  reducers: {
    setSelectedInfluencer: (state, { payload }) => {
      state.selectedInfluencerInfo.influencer = payload;
    },
    resetInfluencerAlbumMediaInfo: state => {
      state.fetchInfluencerAlbumMediaInfo = { ...INIT_STATE.fetchInfluencerAlbumMediaInfo };
    },
    setInfluencerAlbumMedia: (state, { payload }) => {
      state.fetchInfluencerAlbumMediaInfo.data[payload.key] = payload.updatedMedia;
    },
    setInfluencerMediaBundles: (state, { payload }) => {
      state.fetchInfluencerMediaBundlesInfo.data = payload;
    },
  },
  extraReducers: builder => {
    fetchInfluencersBuilder(builder);
    fetchInfluencerByIdBuilder(builder);
    editInfluencerBuilder(builder);
    editPromptsBuilder(builder);
    fetchInfluencerAlbumMediaBuilder(builder);
    createInfluencerBuilder(builder);
    fetchInfluencerVariablesBuilder(builder);
    fetchInfluencerBundleAlbumPhotosBuilder(builder);
    fetchInfluencerBundleAlbumVideosBuilder(builder);
    createMediaBundleBuilder(builder);
    fetchInfluencerMediaBundlesBuilder(builder);
    fetchMediaBundleByIdBuilder(builder);
    editMediaBundleBuilder(builder);
    deleteInfluencerAlbumMediaBuilder(builder);
    editInfluencerAlbumMediaBuilder(builder);
    uploadInfluencerAlbumMediaBuilder(builder);
    deleteMediaBundleBuilder(builder);
    fetchCaptionRecommendationsBuilder(builder);
    pingAdminsWithMediaBuilder(builder);
    sendInstantMassDMBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const {
  setSelectedInfluencer,
  resetInfluencerAlbumMediaInfo,
  setInfluencerAlbumMedia,
  setInfluencerMediaBundles,
} = influencerSlice.actions;

export const selectFetchInfluencersInfo = state => state.influencerInfo.fetchInfluencersInfo;
export const selectSelectedInfluencerInfo = state => state.influencerInfo.selectedInfluencerInfo;
export const selectSelectedInfluencer = state => state.influencerInfo.selectedInfluencerInfo.influencer;
export const selectCreateInfluencerPending = state => state.influencerInfo.pending.createInfluencer;
export const selectEditInfluencerPending = state => state.influencerInfo.pending.editInfluencer;
export const selectEditPromptsPending = state => state.influencerInfo.pending.editPrompts;
export const selectFetchInfluencerAlbumMediaInfo = state => state.influencerInfo.fetchInfluencerAlbumMediaInfo;
export const selectUploadAlbumMediaPending = state => state.influencerInfo.pending.uploadAlbumMedia;
export const selectFetchInfluencerVariablesInfo = state => state.influencerInfo.fetchInfluencerVariablesInfo;
export const selectFetchInfluencerVariablesInfoData = state => state.influencerInfo.fetchInfluencerVariablesInfo.data;
export const selectFetchInfluencerBundleAlbumPhotosInfo = state =>
  state.influencerInfo.fetchInfluencerBundleAlbumPhotosInfo;
export const selectFetchInfluencerBundleAlbumVideosInfo = state =>
  state.influencerInfo.fetchInfluencerBundleAlbumVideosInfo;
export const selectCreateMediaBundlePending = state => state.influencerInfo.pending.createMediaBundle;
export const selectFetchInfluencerMediaBundlesInfo = state => state.influencerInfo.fetchInfluencerMediaBundlesInfo;
export const selectFetchMediaBundleByIdPending = state => state.influencerInfo.pending.fetchMediaBundleById;
export const selectEditMediaBundlePending = state => state.influencerInfo.pending.editMediaBundle;
export const selectDeleteAlbumMediaPending = state => state.influencerInfo.pending.deleteAlbumMedia;
export const selectEditAlbumMediaPending = state => state.influencerInfo.pending.editAlbumMedia;
export const selectDeleteMediaBundlePending = state => state.influencerInfo.pending.deleteMediaBundle;
export const selectFetchCaptionRecommendationsPending = state =>
  state.influencerInfo.pending.fetchCaptionRecommendations;
export const selectPingAdminsWithMediaPending = state => state.influencerInfo.pending.pingAdminsWithMedia;
export const selectSendInstantMassDMPending = state => state.influencerInfo.pending.sendInstantMassDM;

export default influencerSlice.reducer;
