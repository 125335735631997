import { createAsyncThunk } from '@reduxjs/toolkit';
import agencyService from '../../../api/services/agency';

export const fetchAgencies = createAsyncThunk('agency/fetchAgencies', async ({ page, search }) => {
  return await agencyService.fetchAgencies(page, search);
});

export const fetchAgencyById = createAsyncThunk('agency/fetchAgencyById', async ({ agencyId }) => {
  return await agencyService.fetchAgencyById(agencyId);
});

export const createAgency = createAsyncThunk('agency/createAgency', async ({ name }) => {
  return await agencyService.createAgency(name);
});

export const deleteAgency = createAsyncThunk('agency/deleteAgency', async ({ agencyId }) => {
  return await agencyService.deleteAgency(agencyId);
});

export const fetchAllInfluencers = createAsyncThunk('agency/fetchAllInfluencers', async () => {
  return await agencyService.fetchAllInfluencers();
});

export const addInfluencerToAgency = createAsyncThunk(
  'agency/addInfluencerToAgency',
  async ({ agencyId, influencerIds }) => {
    return await agencyService.addInfluencerToAgency(agencyId, influencerIds);
  },
);

export const deleteInfluencerFromAgency = createAsyncThunk(
  'agency/deleteInfluencerFromAgency',
  async ({ agencyId, influencerId }) => {
    return await agencyService.deleteInfluencerFromAgency(agencyId, influencerId);
  },
);

export const createAgencyMember = createAsyncThunk(
  'agency/createAgencyMember',
  async ({ agencyId, name, email, password }) => {
    return await agencyService.createAgencyMember(agencyId, name, email, password);
  },
);

export const deleteAgencyMember = createAsyncThunk('agency/deleteAgencyMember', async ({ agencyMemberId }) => {
  return await agencyService.deleteAgencyMember(agencyMemberId);
});
