import React, { useEffect } from 'react';
import { FlexMain } from '../../../../components/utils/utils.styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormInput } from '../../../../forms/form.styles';
import { editPromptsFormSchema } from '../../../../forms/schemas/prompts.schema';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditPromptsPending, selectSelectedInfluencer } from '../../../../store/slices/influencer/slice';
import { MainButton } from '../../../../components';
import { editPrompts } from '../../../../store/slices/influencer/asyncThunks';
import { notifyError, notifyInfo } from '../../../../utils/notify';

const InfluencerPromptsTab = () => {
  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const editPromptsPending = useSelector(selectEditPromptsPending);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      prompt: {
        male: {
          mainMessage: {
            base: {
              template: '',
            },
          },
        },
      },
    },
    delayError: 300,
    resolver: yupResolver(editPromptsFormSchema),
  });

  useEffect(() => {
    if (selectedInfluencer) {
      setValue('prompt', selectedInfluencer.prompt);
    }
  }, [selectedInfluencer, setValue]);

  const onSubmit = data => {
    dispatch(editPrompts({ ...data }))
      .unwrap()
      .then(() => {
        notifyInfo('Prompts successfully edited!');
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <FlexMain>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Main message template"
          placeholder="Enter main template"
          {...register('prompt.male.mainMessage.base.template')}
          error={errors.prompt?.male?.mainMessage?.base?.template?.message}
          type="textarea"
          specificTextAreaHeight={46}
        />
        <MainButton title="Edit" type="submit" isLoading={editPromptsPending} />
      </form>
    </FlexMain>
  );
};

export default InfluencerPromptsTab;
