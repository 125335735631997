import * as yup from 'yup';

const rangeSchema = yup.object().shape({
  min: yup
    .number()
    .typeError('Min must be a number')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  // .test('min-required-if-max', 'Min is required if Max is entered', function (value) {
  //   const { max } = this.parent;
  //   if (max != null && value == null) {
  //     return false;
  //   }
  //   return true;
  // }),
  max: yup
    .number()
    .typeError('Max must be a number')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    // .test('max-required-if-min', 'Max is required if Min is entered', function (value) {
    //   const { min } = this.parent;
    //   if (min != null && value == null) {
    //     return false;
    //   }
    //   return true;
    // })
    .test('max-greater-than-min', 'Max should be greater than Min', function (value) {
      const { min } = this.parent;
      if (min != null && value != null) {
        return value > min;
      }
      return true;
    }),
});

export const filterFormSchema = yup.object().shape({
  videoOffersCount: rangeSchema,
  photoOffersCount: rangeSchema,
  bundleOffersCount: rangeSchema,
  subscriptionOffersCount: rangeSchema,
  videoPurchaseCount: rangeSchema,
  photoPurchaseCount: rangeSchema,
  bundlePurchaseCount: rangeSchema,
  subscriptionPurchaseCount: rangeSchema,
  totalMessages: rangeSchema,
  totalSpent: rangeSchema,
});
