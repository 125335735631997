import React from 'react';
import {
  Avatar,
  AvatarText,
  Background,
  Card,
  Circle,
  LeftIcon,
  RightIcon,
  TitleContainer,
} from './InfluencerFactsCard.styles';
import { Text16Bold } from '../utils/texts/text.styles';
import { ReactComponent as StarsIcon } from '../../assets/icons/stars.svg';

const InfluencerFactsCard = ({ name, totalFactRequests, onClick }) => {
  const influencerInitials = () => {
    const splittedFullName = name.split(' ');
    if (splittedFullName.length > 1) {
      return `${splittedFullName[0][0].toUpperCase()}${splittedFullName[1][0].toUpperCase()}`;
    } else {
      return name[0].toUpperCase();
    }
  };

  return (
    <Card onClick={onClick}>
      <Background />
      <LeftIcon>
        <StarsIcon />
      </LeftIcon>
      <RightIcon>
        <StarsIcon />
      </RightIcon>
      <Avatar>
        <AvatarText>{influencerInitials()}</AvatarText>
      </Avatar>
      <TitleContainer>
        <Text16Bold>{name}</Text16Bold>
        <Circle>{totalFactRequests}</Circle>
      </TitleContainer>
    </Card>
  );
};

export default InfluencerFactsCard;
