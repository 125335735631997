import { CHAT_ACTIVITY_TARGET, CHAT_ACTIVITY_TYPE } from '../../../../utils/constants';
import { Bubble, Tooltip } from './CenteredBubble.styles';

// CenteredBubble component
const CenteredBubble = ({ id, cost, url, target, type, metadata }) => {
  return (
    <Bubble id={id} $url={!!url} target={target}>
      {type === CHAT_ACTIVITY_TYPE.OFFER && (
        <>
          INFLUENCER offered {target} - {cost} ⭐️
        </>
      )}
      {type === CHAT_ACTIVITY_TYPE.PURCHASE && (
        <>
          USER purchased {target} - {cost} ⭐️
        </>
      )}
      {type === CHAT_ACTIVITY_TYPE.EVENT && <>EVENT - {target}</>}
      {(target === CHAT_ACTIVITY_TARGET.PREMIUM_PHOTO ||
        target === CHAT_ACTIVITY_TARGET.FREE_PHOTO ||
        target === CHAT_ACTIVITY_TARGET.PREMIUM_VIDEO ||
        target === CHAT_ACTIVITY_TARGET.FREE_VIDEO) &&
        url && (
          <Tooltip className="tooltip">
            {target === CHAT_ACTIVITY_TARGET.PREMIUM_PHOTO || target === CHAT_ACTIVITY_TARGET.FREE_PHOTO ? (
              <img src={url} alt="tooltip-preview" />
            ) : (
              <video src={url} controls muted />
            )}
          </Tooltip>
        )}
      {metadata && (
        <Tooltip className="tooltip">
          <pre>{JSON.stringify(metadata, null, 2)}</pre>
        </Tooltip>
      )}
    </Bubble>
  );
};

export default CenteredBubble;
