import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, InfluencerTableItem } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchInfluencersInfo } from '../../../store/slices/influencer/slice';
import { fetchInfluencers } from '../../../store/slices/influencer/asyncThunks';
// import { notifyInfo } from '../../../utils/notify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { AddInfluencerButton, Header, Row, SearchInput, Title } from './InfluencersListPage.styles';

function InfluencersListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get('page') || 1;
  const search = searchParams.get('search');
  const { data, total, pending } = useSelector(selectFetchInfluencersInfo);
  const [searchValue, setSearchValue] = useState(() => search ?? '');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tableHeader = useMemo(
    () => [
      { label: 'Picture & Name' },
      { label: 'Username' },
      { label: 'Email' },

      // { label: 'Actions', type: 'action' },
    ],
    [],
  );

  const changeHandler = useCallback(
    text => {
      searchParams.set('search', text);
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  useEffect(() => {
    if (page > 0) {
      dispatch(fetchInfluencers({ page, search: search ?? '' }));
    }
  }, [dispatch, page, search]);

  const setPage = page => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  return (
    <>
      <Header>
        <Title>Influencers</Title>
        <Row>
          <SearchInput
            value={searchValue}
            onChange={event => {
              setSearchValue(event.target.value);
              debouncedChangeHandler(event.target.value);
            }}
            placeholder="Search"
            hideErrorMessage
          />
          <AddInfluencerButton
            title="Add Influencer"
            onClick={() => {
              navigate(`/influencers/new`);
            }}
          />
        </Row>
      </Header>
      <Table
        headerInformation={tableHeader}
        dataLoading={pending}
        totalCount={total}
        currentPage={page}
        perPage={10}
        setPage={setPage}
        emptyDataText={'No data'}
        fullHeight>
        {data.map(influencer => (
          <InfluencerTableItem
            key={influencer.id}
            name={influencer.name}
            profilePhoto={influencer.profilePhoto}
            username={influencer.username}
            email={influencer.email}
            status={influencer.status}
            isDeleted={!!influencer.deletedAt}
            onDetailsClicked={() => {
              navigate(`/influencers/${influencer.id}`);
            }}
            // onCopyClicked={event => {
            //   navigator.clipboard.writeText(influencer.name);
            //   notifyInfo('Name copied successfully!');
            //   event.stopPropagation();
            // }}
          />
        ))}
      </Table>
    </>
  );
}

export default InfluencersListPage;
