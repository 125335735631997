import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCreateInfluencerFactPending,
  selectEditInfluencerFactPending,
} from '../../../store/slices/influencerFact/slice';
import { createInfluencerFact, editInfluencerFact } from '../../../store/slices/influencerFact/asyncThunks';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { FormInput } from '../../../forms/form.styles';

export const createInfluencerFactFormSchema = yup
  .object({ text: yup.string().trim().required('Text is required') })
  .required();

const NewInfluencerFactFormModal = ({ modalRef, influencerId, selectedFact, setSelectedFact }) => {
  const dispatch = useDispatch();
  const createInfluencerFactPending = useSelector(selectCreateInfluencerFactPending);
  const editInfluencerFactPending = useSelector(selectEditInfluencerFactPending);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      text: '',
    },
    delayError: 300,
    resolver: yupResolver(createInfluencerFactFormSchema),
  });

  useEffect(() => {
    if (selectedFact) {
      setValue('text', selectedFact.text);
    }
  }, [selectedFact, setValue]);

  const onSubmit = data => {
    const { text } = data;

    if (selectedFact) {
      dispatch(editInfluencerFact({ text, influencerFactId: selectedFact.id }))
        .unwrap()
        .then(() => {
          reset();
          modalRef.current.hide();
          notifyInfo('Fact updated successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    } else {
      dispatch(createInfluencerFact({ text, influencerId }))
        .unwrap()
        .then(() => {
          reset();
          modalRef.current.hide();
          notifyInfo('Fact created successfully!');
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  };

  return (
    <FormModal
      ref={modalRef}
      title="Add New Fact"
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
        setSelectedFact(null);
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText="Submit"
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={createInfluencerFactPending || editInfluencerFactPending}
      shouldCloseOnOverlayClick={false}>
      <form>
        <FormInput
          label="Description"
          placeholder="Enter description"
          {...register('text')}
          error={errors.text?.message}
          type="textarea"
          specificTextAreaHeight={10}
        />
      </form>
    </FormModal>
  );
};

export default NewInfluencerFactFormModal;
