import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import EditPromptGroupModal from '../../components/modals/EditPromptGroupModal/EditPromptGroupModal';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchPromptGroupInfo, selectPromptGroupActionsPending } from '../../store/slices/promptGroup/slice';
import debounce from 'lodash.debounce';
import { AddPromptGroupButton, Header, Row, SearchInput, Title } from './PromptGroupsPage.styles';
import { Table } from '../../components';
import { deletePromptGroup, fetchPromptGroups } from '../../store/slices/promptGroup/asyncThunks';
import PromptGroupItem from '../../components/Table/items/PromptGroupItem/PromptGroupItem';
import { notifyError, notifyInfo } from '../../utils/notify';
import { useConfirmationModal } from '../../components/utils/modals/ConfirmationModal/ConfirmationModal';

const PromptGroupPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get('page') || 1;
  const search = searchParams.get('search');
  const [searchValue, setSearchValue] = useState(() => search ?? '');
  const [selectedPromptGroup, setSelectedPromptGroup] = useState(null);
  const { data, total, pending } = useSelector(selectFetchPromptGroupInfo);
  const { deletePromptGroup: deletePromptGroupPending } = useSelector(selectPromptGroupActionsPending);

  const dispatch = useDispatch();
  const [openModal, closeModal, setConfirmationModalLoader] = useConfirmationModal();

  const editPromptGroupModalRef = useRef();

  useEffect(() => {
    setConfirmationModalLoader(deletePromptGroupPending);
  }, [setConfirmationModalLoader, deletePromptGroupPending]);

  const changeHandler = useCallback(
    text => {
      searchParams.set('search', text);
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  useEffect(() => {
    if (page > 0) {
      dispatch(fetchPromptGroups({ page, search: search ?? '' }));
    }
  }, [dispatch, page, search]);

  const setPage = page => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  const onDeletePromptGroupClick = (e, promptGroupId) => {
    openModal(
      'Delete Prompt Group',
      `Are you sure you want to delete prompt group?`,
      'Delete',
      () => {
        dispatch(deletePromptGroup({ promptGroupId }))
          .unwrap()
          .then(() => {
            closeModal();
            notifyInfo('Prompt group deleted successfully!');
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Cancel',
      () => {
        closeModal();
      },
    );
    e.stopPropagation();
  };

  const tableHeader = [{ label: 'Name' }, { label: 'Actions', type: 'action' }];

  return (
    <>
      <Header>
        <Title>Prompt Groups</Title>
        <Row>
          <SearchInput
            value={searchValue}
            onChange={event => {
              setSearchValue(event.target.value);
              debouncedChangeHandler(event.target.value);
            }}
            placeholder="Search"
            hideErrorMessage
          />
          <AddPromptGroupButton title="Create Prompt Group" onClick={() => editPromptGroupModalRef.current.show()} />
        </Row>
      </Header>
      <Table
        headerInformation={tableHeader}
        dataLoading={pending}
        totalCount={total}
        currentPage={page}
        perPage={10}
        setPage={setPage}
        emptyDataText={'No data'}
        fullHeight>
        {data.map(promptGroup => (
          <PromptGroupItem
            key={promptGroup.id}
            name={promptGroup.name}
            onDetailsClicked={() => {
              setSelectedPromptGroup(promptGroup);
              editPromptGroupModalRef.current.show();
            }}
            onDelete={e => onDeletePromptGroupClick(e, promptGroup.id)}
          />
        ))}
      </Table>
      <EditPromptGroupModal
        modalRef={editPromptGroupModalRef}
        selectedPromptGroup={selectedPromptGroup}
        setSelectedPromptGroup={setSelectedPromptGroup}
      />
    </>
  );
};

export default PromptGroupPage;
