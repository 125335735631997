import styled from 'styled-components';
import { Text16Bold } from '../../utils/texts/text.styles';

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
`;

export const StyledRow = styled(Row)`
  align-items: center;
  gap: 4rem;
`;

export const PriceInUsd = styled(Text16Bold)`
  width: 50%;
  margin-bottom: 3.8rem;
`;

export const GenerateText = styled(Text16Bold)`
  text-decoration: underline;
  color: #843efe;
  cursor: pointer;
  margin-bottom: 3.6rem;
  min-width: 3.6rem;
`;

export const Spinner = styled.img`
  width: 3.6rem;
  height: 2rem;
  margin-bottom: 3.6rem;
  margin-inline: auto;
`;

export const Actions = styled.div`
  display: flex;
  gap: 0.6rem;
  min-width: 8.5rem;
`;
