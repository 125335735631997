import { CHAT_ACTIVITY_FORMAT, CHAT_ACTIVITY_SENDER } from '../../../../utils/constants';
import { AudioLabel, BubbleContainer, BubbleContent, Timestamp } from './ChatBubble.styles';

export const ChatBubble = ({ sender, children, timestamp, responseFormat }) => {
  return (
    <BubbleContainer $sender={sender}>
      <BubbleContent $sender={sender}>{children}</BubbleContent>
      {sender === CHAT_ACTIVITY_SENDER.INFLUENCER && responseFormat === CHAT_ACTIVITY_FORMAT.VOICE && <AudioLabel />}

      <Timestamp title={new Date(timestamp).toLocaleString()}>
        {new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
      </Timestamp>
    </BubbleContainer>
  );
};
