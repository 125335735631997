import React from 'react';
import { Fragment } from 'react';
import { ActionTableBodyItem, TableBodyItem } from '../../Table.styles';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-dark.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { EditIconWrapper } from '../AgencyItem/AgencyItem.styles';

const PromptGroupItem = ({ name, onDelete }) => {
  return (
    <Fragment>
      <TableBodyItem>
        <p>{name || '-'}</p>
      </TableBodyItem>
      <ActionTableBodyItem>
        <DeleteIcon onClick={onDelete} />
        <EditIconWrapper>
          <EditIcon />
        </EditIconWrapper>
      </ActionTableBodyItem>
    </Fragment>
  );
};

export default PromptGroupItem;
