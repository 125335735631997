import styled from 'styled-components';

export const Bubble = styled.div`
  margin: 1rem auto;
  padding: 0.8rem 1.5rem;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 1.5rem;
  text-align: center;
  font-size: 1.2rem;
  color: #161320;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px;
  max-width: 60%;
  position: relative; /* Needed for positioning the tooltip */
  cursor: ${props => (props.$url ? 'pointer' : 'default')};

  &:hover .tooltip {
    display: block;
  }
`;

export const Tooltip = styled.div`
  display: none;
  position: absolute;
  bottom: 100%; /* Position above the bubble */
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem;
  border-radius: 0.5rem;
  white-space: nowrap;
  z-index: 10;

  max-width: 300px;
  overflow: scroll;

  text-align: left;
  background: white;
  border: 1px solid #cecece;

  img,
  video {
    max-width: 200px;
    max-height: 150px;
    border-radius: 0.5rem;
  }
`;
