import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth/slice';
import userSlice from './slices/user/slice';
import influencerSlice from './slices/influencer/slice';
import invitationCodeSlice from './slices/invitationCode/slice';
import influencerRequestSlice from './slices/influencerRequest/slice';
import withdrawalSlice from './slices/withdrawal/slice';
import promptGroupSlice from './slices/promptGroup/slice';
import interactionSlice from './slices/interaction/slice';
import agencySlice from './slices/agency/slice';
import influencerFactSlice from './slices/influencerFact/slice';
import userInfluencerSlice from './slices/userInfluencer/slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    userInfo: userSlice,
    influencerInfo: influencerSlice,
    interactionInfo: interactionSlice,
    invitationCodeInfo: invitationCodeSlice,
    influencerRequestInfo: influencerRequestSlice,
    withdrawalInfo: withdrawalSlice,
    promptGroupInfo: promptGroupSlice,
    agencyInfo: agencySlice,
    influencerFactInfo: influencerFactSlice,
    userInfluencerInfo: userInfluencerSlice,
  },
  devTools: false,
});
