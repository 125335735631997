import * as yup from 'yup';

const promptBaseFormSchema = yup
  .object()
  .shape({
    mainMessage: yup.object().shape({
      base: yup.object().shape({
        template: yup.string().trim().required('Prompt template is required'),
      }),
    }),
  })
  .required();

export const editPromptsFormSchema = yup
  .object({
    prompt: yup.object().shape({
      male: promptBaseFormSchema,
    }),
  })
  .required();

export const createPromptsAsAdminFormSchema = yup
  .object({
    prompt: yup.object().shape({
      male: promptBaseFormSchema,
    }),
  })
  .required();

export const editPromptGroupFormSchema = yup
  .object({
    name: yup.string().trim().required('Prompt group name is required'),
    prompt: promptBaseFormSchema,
  })
  .required();
