import styled from 'styled-components';
import { Text16Bold } from '../../../components/utils/texts/text.styles';
import { MainButton } from '../../../components/utils/buttons/Button/Button';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

export const Title = styled(Text16Bold)`
  font-size: 2.8rem;
  margin-left: 1rem;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Spinner = styled.img`
  width: 30rem;
  height: 30rem;
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 2rem;
  max-height: calc(100% - 5.5rem);
`;

export const MembersList = styled.div`
  flex: 1;
  max-height: 100%;
`;

export const InfluencersList = styled.div`
  flex: 1;
  border-right: 1px solid #e0e0e0;
  padding-inline-end: 2rem;
  max-height: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled(MainButton)`
  width: 14rem;
  height: 4rem;
  font-size: 1.4rem;
  padding: unset;
`;

export const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  margin-top: 2rem;
  height: calc(100% - 6rem);
`;

export const ListItem = styled.div`
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 1rem;
`;

export const IconWrapper = styled.div`
  margin-left: auto;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
