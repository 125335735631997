import React, { useMemo, useState } from 'react';
import { ReactComponent as AddPhotoIcon } from '../../../../assets/icons/add_photo.svg';
import { ReactComponent as AddVideoIcon } from '../../../../assets/icons/add_video.svg';
import { ReactComponent as VoiceIconSvg } from '../../../../assets/icons/voice.svg';
import { notifyError } from '../../../../utils/notify';
import {
  AllowedFileExtensionsText,
  UplaodFileText,
  UploadFileInputContainer,
  UploadFileInput,
  Image,
  Container,
  UploadFileHelperContainer,
  Video,
} from './FileInput.styles';
import ErrorContainer from '../../ErrorContainer/ErrorContainer';
import { InputLabel } from '../../texts/text.styles';
import { UPLOAD_FILE_CONSTRAINTS } from '../../../../utils/constants';
import { Row } from '../../utils.styles';
import { DeleteIconWrapper } from '../../../modals/CreateMediaBundleFormModal/CreateMediaBundleFormModal.styles';
import { DeleteIcon } from '../Input/Input.styles';

const FileInput = ({
  label,
  value,
  onChange,
  className,
  error,
  hideErrorMessage,
  increasedFileHeight,
  increasedFileWidth,
  type = UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO,
  disabled,
  onDeleteClick,
}) => {
  const [changedValue, setChangedValue] = useState(new Date());
  const validateFile = file => {
    const allowedExtensions = UPLOAD_FILE_CONSTRAINTS[type].ALLOWED_EXTENSIONS;
    const maxFileSizeInBytes = UPLOAD_FILE_CONSTRAINTS[type].MAX_SIZE_IN_BYTES;
    if (file.size < maxFileSizeInBytes) {
      const fileType = file.type.split('/')[1];
      if (allowedExtensions.includes(fileType)) {
        return file;
      } else {
        notifyError('Unsupported type');
      }
    } else {
      notifyError('File size error');
    }
  };

  const acceptedExtensionsText = useMemo(() => UPLOAD_FILE_CONSTRAINTS[type].ALLOWED_EXTENSIONS_TEXT, [type]);

  return (
    <Container className={className}>
      {label && <InputLabel>{label}</InputLabel>}

      <UploadFileInputContainer $increasedFileHeight={increasedFileHeight} $increasedFileWidth={increasedFileWidth}>
      {onDeleteClick && (
        <DeleteIconWrapper onClick={onDeleteClick}>
          <DeleteIcon width={20} height={20} fill="white" />
        </DeleteIconWrapper>
      )}
        <UploadFileInput
          type="file"
          title=""
          value=""
          disabled={disabled}
          onChange={e => {
            const { files: newFiles } = e.target;
            if (newFiles.length) {
              const file = validateFile(newFiles[0]);
              if (file) {
                const reader = new FileReader();
                reader.onload = function (event) {
                  onChange({ file, content: event.target.result });
                  setChangedValue(new Date());
                };
                reader.readAsDataURL(file);
              }
            }
          }}
          accept={acceptedExtensionsText}
        />
        {value ? (
          type === UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO ? (
            <Image src={typeof value === 'string' ? value : value.content} alt="influencer-image" />
          ) : type === UPLOAD_FILE_CONSTRAINTS.TYPE.VIDEO ? (
            <Video key={changedValue}>
              <source src={typeof value === 'string' ? value : value.content} type="video/mp4" />
            </Video>
          ) : type === UPLOAD_FILE_CONSTRAINTS.TYPE.REPRESENTATIVE_MEDIA ? (
            (() => {
              const getFileExtension = value => {
                const source = typeof value === 'string' ? value : value.file.name;
                const match = source.match(/\.([a-zA-Z0-9]+)(?=\?|$)/);
                return match ? match[1] : null;
              };

              const fileExtension = getFileExtension(value);

              if (UPLOAD_FILE_CONSTRAINTS.PHOTO.ALLOWED_EXTENSIONS.includes(fileExtension)) {
                return <Image src={typeof value === 'string' ? value : value.content} alt="influencer-image" />;
              }

              if (UPLOAD_FILE_CONSTRAINTS.VIDEO.ALLOWED_EXTENSIONS.includes(fileExtension)) {
                return (
                  <Video key={changedValue}>
                    <source src={typeof value === 'string' ? value : value.content} type="video/mp4" />
                  </Video>
                );
              }

              // Handle unsupported file types
              return <div>Unsupported file type</div>;
            })()
          ) : (
            <UploadFileHelperContainer>
              <VoiceIconSvg />
              <UplaodFileText>{value.file ? value.file.name : 'Voice Call - init message'}</UplaodFileText>
            </UploadFileHelperContainer>
          )
        ) : type === UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO ? (
          <UploadFileHelperContainer>
            <AddPhotoIcon />
            <UplaodFileText>Drag & Drop</UplaodFileText>
            <AllowedFileExtensionsText>Allowed file extensions .jpg, .png, .jpeg</AllowedFileExtensionsText>
          </UploadFileHelperContainer>
        ) : type === UPLOAD_FILE_CONSTRAINTS.TYPE.VIDEO ? (
          <UploadFileHelperContainer>
            <AddVideoIcon />
            <UplaodFileText>Drag & Drop</UplaodFileText>
            <AllowedFileExtensionsText>Allowed file extensions .mp4, .mov</AllowedFileExtensionsText>
          </UploadFileHelperContainer>
        ) : type === UPLOAD_FILE_CONSTRAINTS.TYPE.REPRESENTATIVE_MEDIA ? (
          <UploadFileHelperContainer>
            <Row>
              <AddPhotoIcon />
              <AddVideoIcon />
            </Row>
            <UplaodFileText>Drag & Drop</UplaodFileText>
            <AllowedFileExtensionsText>Allowed file extensions .jpg .png .jpeg .mp4 .mov</AllowedFileExtensionsText>
          </UploadFileHelperContainer>
        ) : (
          <UploadFileHelperContainer>
            <VoiceIconSvg />
            <UplaodFileText>Drag & Drop</UplaodFileText>
            <AllowedFileExtensionsText>Allowed file extensions .mp3, .wav, .m4a</AllowedFileExtensionsText>
          </UploadFileHelperContainer>
        )}
      </UploadFileInputContainer>
      {!hideErrorMessage && <ErrorContainer errorText={error} />}
    </Container>
  );
};

export default FileInput;
