import React from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgency, selectCreateAgencyMemberPending } from '../../../store/slices/agency/slice';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { FormInput } from '../../../forms/form.styles';
import { createAgencyMember } from '../../../store/slices/agency/asyncThunks';

export const createAgencyMemberFormSchema = yup
  .object({
    name: yup.string().trim().required('Name is required'),
    email: yup.string().trim().required('Email is required').email('Invalid email'),
    password: yup.string().trim().required('Password is required'),
  })
  .required();

const AddAgencyMemberFormModal = ({ modalRef }) => {
  const agency = useSelector(selectAgency);
  const createAgencyMemberPending = useSelector(selectCreateAgencyMemberPending);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      password: '',
    },
    delayError: 300,
    resolver: yupResolver(createAgencyMemberFormSchema),
  });

  const onSubmit = data => {
    dispatch(createAgencyMember({ agencyId: agency.id, ...data }))
      .unwrap()
      .then(_ => {
        reset();
        modalRef.current.hide();
        notifyInfo('Agency member created successfully');
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <FormModal
      ref={modalRef}
      title="Create Agency Member"
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        reset();
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      rightButtonText="Submit"
      onRightButtonClick={() => handleSubmit(onSubmit)()}
      rightButtonLoader={createAgencyMemberPending}
      shouldCloseOnOverlayClick={false}>
      <form>
        <FormInput label="Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
        <FormInput label="Email" placeholder="Enter email" {...register('email')} error={errors.email?.message} />
        <FormInput
          label="Password"
          type="password"
          placeholder="Enter password"
          {...register('password')}
          error={errors.password?.message}
        />
      </form>
    </FormModal>
  );
};

export default AddAgencyMemberFormModal;
