import { CheckBoxContent, StyledCheckboxWrapper, StyledHiddenInput, StyledLabel } from './CheckBox.styles';
import { ReactComponent as CheckBoxBlankIcon } from '../../../../assets/icons/check-box-blank.svg';
import { ReactComponent as CheckBoxIcon } from '../../../../assets/icons/check-box.svg';
import ErrorContainer from '../../ErrorContainer/ErrorContainer';
import { StyledLabelRow } from '../Input/Input.styles';
import { Text16Regular } from '../../texts/text.styles';

const Checkbox = ({ checked, disabled, onChange, error, hideErrorMessage, children, className, label }) => {
  return (
    <StyledLabel disabled={disabled} className={className}>
      {(label) && (
        <StyledLabelRow>
          {label && <Text16Regular>{label}</Text16Regular>}
        </StyledLabelRow>
      )}
      <StyledCheckboxWrapper>
        {checked ? <CheckBoxIcon /> : <CheckBoxBlankIcon />}
        <StyledHiddenInput type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
        <CheckBoxContent>{children}</CheckBoxContent>
      </StyledCheckboxWrapper>
      {!hideErrorMessage && <ErrorContainer errorText={error} />}
    </StyledLabel>
  );
};

export default Checkbox;
