import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AddToGalleryButton,
  AlbumMediaListContainer,
  InfiniteScrollMarker,
  LoaderContainer,
  Spinner,
  Title,
} from './PageableGalleryTab.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetInfluencerAlbumMediaInfo,
  selectDeleteAlbumMediaPending,
  selectFetchInfluencerAlbumMediaInfo,
  selectSelectedInfluencer,
} from '../../../../../store/slices/influencer/slice';
import {
  deleteInfluencerAlbumMedia,
  fetchInfluencerAlbumMedia,
} from '../../../../../store/slices/influencer/asyncThunks';
import { capitalizeFirstLetter } from '../../../../../utils/util';
import { FlexHeader, FlexMain } from '../../../../../components/utils/utils.styles';
import { MEDIA_CATEGORY, MEDIA_TYPE } from '../../../../../utils/constants';
import AlbumMedia from '../../../../../components/AlbumMedia/AlbumMedia';
import UploadFreeVideoFormModal from '../../../../../components/modals/UploadFreeVideoFormModal/UploadFreeVideoFormModal';
import UploadBundleVideoFormModal from '../../../../../components/modals/UploadBundleVideoFormModal/UploadBundleVideoFormModal';
import UploadFreePhotoFormModal from '../../../../../components/modals/UploadFreePhotoFormModal/UploadFreePhotoFormModal';
import UploadBundlePhotoFormModal from '../../../../../components/modals/UploadBundlePhotoFormModal/UploadBundlePhotoFormModal';
import { notifyError, notifyInfo } from '../../../../../utils/notify';
import { useConfirmationModal } from '../../../../../components/utils/modals/ConfirmationModal/ConfirmationModal';
import PreviewAlbumMediaModal from '../../../../../components/modals/PreviewAlbumMediaModal/PreviewAlbumMediaModal';
import SpinnerGIF from '../../../../../assets/images/spinner.gif';

function PageableGalleryTab({ mediaType, mediaCategory }) {
  const ALBUM_MEDIA_PAGE_SIZE = 10;

  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const { data, pending, total } = useSelector(selectFetchInfluencerAlbumMediaInfo);

  const [page, setPage] = useState(1);
  const deleteAlbumMediaPending = useSelector(selectDeleteAlbumMediaPending);
  const [selectedAlbumMedia, setSelectedAlbumMedia] = useState(null);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const uploadNewAlbumMediaRef = useRef();
  const previewAlbumMediaModalRef = useRef();
  const loadMoreRef = useRef(null);
  const dispatch = useDispatch();
  const [openModal, closeModal, setConfirmationModalLoader] = useConfirmationModal();

  useEffect(() => {
    dispatch(
      fetchInfluencerAlbumMedia({ influencerId: selectedInfluencer.id, mediaType, mediaCategory, page }),
    )
  }, [dispatch, selectedInfluencer.id, mediaType, mediaCategory, page]);

  useEffect(() => {
    setPage(1);
    return () => {
      dispatch(resetInfluencerAlbumMediaInfo());
    };
  }, [dispatch]);

  useEffect(() => {
    setConfirmationModalLoader(deleteAlbumMediaPending);
  }, [deleteAlbumMediaPending, setConfirmationModalLoader]);

  const handleIntersection = useCallback(
    entries => {
      const [entry] = entries;
      if (entry.isIntersecting && !pending && total > page * ALBUM_MEDIA_PAGE_SIZE) {
        setPage(prev => prev + 1);
      }
    },
    [pending, total, page],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, { root: null, threshold: 1.0 });
    const target = loadMoreRef.current;
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [handleIntersection]);

  const UploadAlbumMediaFormModal = useMemo(() => {
    switch (mediaType) {
      case MEDIA_TYPE.PHOTO:
        if (mediaCategory === MEDIA_CATEGORY.FREE) {
          return (
            <UploadFreePhotoFormModal
              modalRef={uploadNewAlbumMediaRef}
              selectedAlbumMedia={selectedAlbumMedia}
              onAfterClose={() => setSelectedAlbumMedia(null)}
            />
          );
        } else {
          return (
            <UploadBundlePhotoFormModal
              modalRef={uploadNewAlbumMediaRef}
              selectedAlbumMedia={selectedAlbumMedia}
              onAfterClose={() => setSelectedAlbumMedia(null)}
            />
          );
        }
      case MEDIA_TYPE.VIDEO:
        if (mediaCategory === MEDIA_CATEGORY.FREE) {
          return (
            <UploadFreeVideoFormModal
              modalRef={uploadNewAlbumMediaRef}
              selectedAlbumMedia={selectedAlbumMedia}
              onAfterClose={() => setSelectedAlbumMedia(null)}
            />
          );
        } else {
          return (
            <UploadBundleVideoFormModal
              modalRef={uploadNewAlbumMediaRef}
              selectedAlbumMedia={selectedAlbumMedia}
              onAfterClose={() => setSelectedAlbumMedia(null)}
            />
          );
        }

      default:
        return null;
    }
  }, [mediaType, mediaCategory, selectedAlbumMedia]);

  const onAddNewAlbumMedia = () => {
    uploadNewAlbumMediaRef.current.show();
  };

  const onEditAlbumMedia = useCallback(albumMedia => {
    setSelectedAlbumMedia(albumMedia);
    uploadNewAlbumMediaRef.current.show();
  }, []);

  const onDeleteAlbumMediaMedia = (event, albumMediaId, mediaType, mediaCategory, mediaTag) => {
    openModal(
      'Delete Album Media',
      `Are you sure you want to delete album media?`,
      'Delete',
      () => {
        dispatch(deleteInfluencerAlbumMedia({ albumMediaId, mediaType, mediaCategory, mediaTag }))
          .unwrap()
          .then(() => {
            notifyInfo('Album media deleted successfully!');
            closeModal();
            setPage(1);
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Cancel',
      () => {
        closeModal();
      },
    );
    event.stopPropagation();
  };

  const onAlbumMediaClick = albumMedia => {
    setSelectedUrl(albumMedia.url);
    previewAlbumMediaModalRef.current.show();
  };

  return (
    <>
      <FlexHeader>
        <Title>
          {capitalizeFirstLetter(mediaCategory)} {capitalizeFirstLetter(mediaType)} Gallery
        </Title>
        <AddToGalleryButton title="Add media" onClick={() => onAddNewAlbumMedia()} />
      </FlexHeader>
      <FlexMain>
        <AlbumMediaListContainer>
          {data.map((item, i) => (
            <AlbumMedia
              disableDragAndDrop
              sectionKey={mediaCategory}
              key={item.id ? item.id : i}
              index={i}
              src={item.thumbnailUrl ? item.thumbnailUrl : item.url}
              mediaType={item.thumbnailUrl ? MEDIA_TYPE.PHOTO : mediaType}
              onClick={() => onAlbumMediaClick(item)}
              item={item}
              alt="album-media-item"
              onEdit={() => onEditAlbumMedia(item)}
              onDelete={event => onDeleteAlbumMediaMedia(event, item.id, mediaType, mediaCategory)}
              hideCost
            />
          ))}
        </AlbumMediaListContainer>

        {pending && (
          <LoaderContainer>
            <Spinner src={SpinnerGIF} />
          </LoaderContainer>
        )}

        <InfiniteScrollMarker ref={loadMoreRef}/>
      </FlexMain>
      {UploadAlbumMediaFormModal}
      <PreviewAlbumMediaModal
        modalRef={previewAlbumMediaModalRef}
        type={mediaType}
        url={selectedUrl}
        setSelectedUrl={setSelectedUrl}
      />
    </>
  );
}

export default PageableGalleryTab;
