import React from 'react';
import { Button, Container, Content, Form, Header, Title } from './SettingsPage.styles';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '../../components';
import { notifyError, notifyInfo } from '../../utils/notify';
import { changePassword } from '../../store/slices/user/asyncThunks';
import { useDispatch, useSelector } from 'react-redux';
import { selectChangePasswordPending } from '../../store/slices/user/slice';

const passwordFormSchema = yup
  .object({
    currentPassword: yup
      .string()
      .trim()
      .required('Current password is required')
      .min(6, 'Must be at least 6 characters'),
    password: yup.string().trim().required('New password is required').min(6, 'Must be at least 6 characters'),
  })
  .required();

const SettingsPage = () => {
  const dispatch = useDispatch();
  const changePasswordPending = useSelector(selectChangePasswordPending);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      currentPassword: '',
      password: '',
    },
    delayError: 300,
    resolver: yupResolver(passwordFormSchema),
  });

  const onSubmit = data => {
    const { password, currentPassword } = data;
    dispatch(changePassword({ newPassword: password, currentPassword }))
      .unwrap()
      .then(() => {
        notifyInfo('Password changed successfully');
        reset();
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <>
      <Header>
        <Title>Change Password</Title>
      </Header>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Content>
            <Input
              label="Current Password"
              placeholder="Enter current password"
              {...register('currentPassword')}
              error={errors.currentPassword?.message}
              type="password"
            />
            <Input
              label="New Password"
              placeholder="Enter new password"
              {...register('password')}
              error={errors.password?.message}
              type="password"
            />
          </Content>
          <Button title="Save" isLoading={changePasswordPending} />
        </Form>
      </Container>
    </>
  );
};

export default SettingsPage;
