import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  gap: 2rem;
  background-color: #f9f9f9;
  padding: 2rem;
`;

export const UserInfoSection = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: #fff;
  padding: 1.5rem;
  border-radius: 1.4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  min-width: 30rem;
  max-width: 30rem;

  h2 {
    margin: 0;
    font-size: 1.8rem;
    color: #161320;
  }

  p {
    font-size: 1.4rem;
    color: #909090;
  }
`;

export const InfluencersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
`;

export const InfluencerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures photo and name are on the left, spent is on the right */
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #f2f0f7;
  border-radius: 1rem;
  background: ${props => (props.isSelected ? '#f0f0f0' : '#fbfafc')};
  cursor: pointer;
`;

export const InfluencerLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem; /* Keeps photo and name together */
`;

export const InfluencerPhoto = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const InfluencerName = styled.span`
  font-size: 1.4rem;
  color: #161320;
`;

export const InfluencerSpent = styled.span`
  font-size: 1.4rem;
  color: #666;
`;

export const MainContent = styled.div`
  flex: 1;
  display: flex;
  gap: 2rem;
`;
