import React, { useEffect, useState } from 'react';
import CreateInfluencerSidebar from './CreateInfluencerSidebar/CreateInfluencerSidebar';
import CreateInfluencerPageStepBasicInfo from './steps/CreateInfluencerPageStepBasicInfo/CreateInfluencerPageStepBasicInfo';
import CreateInfluencerPageStepPrompts from './steps/CreateInfluencerPageStepPrompts/CreateInfluencerPageStepPrompts';
import { useDispatch, useSelector } from 'react-redux';
import { createInfluencer } from '../../../store/slices/influencer/asyncThunks';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectFetchInfluencerRequestByIdPending } from '../../../store/slices/influencerRequest/slice';
import { fetchInfluencerRequestById } from '../../../store/slices/influencerRequest/asyncThunks';
import MainLoader from '../../../components/utils/loaders/MainLoader/MainLoader';

const FORM_INFO = {
  NUM_OF_STEPS: 2,
  PROP_NAME: {
    0: 'basicInfo',
    1: 'prompt',
  },
};

function CreateInfluencerPage() {
  const fetchInfluencerRequestByIdPending = useSelector(selectFetchInfluencerRequestByIdPending);
  const [formData, setFormData] = useState({
    basicInfo: null,
    additionalInfo: null,
    secretModes: null,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedStep, setSelectedStep] = useState(0);
  const [influencerRequest, setInfluencerRequest] = useState(null);
  const { state } = useLocation();
  const influencerRequestId = state?.influencerRequestId;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (influencerRequestId) {
      dispatch(fetchInfluencerRequestById({ influencerRequestId }))
        .unwrap()
        .then(payload => {
          setInfluencerRequest(payload.influencerRequest);
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  }, [influencerRequestId, dispatch]);

  const onSubmit = (data, step) => {
    // Update selected and current steps
    if (step !== FORM_INFO.NUM_OF_STEPS - 1) {
      setFormData(state => ({ ...state, [FORM_INFO.PROP_NAME[step]]: { ...data } }));
      setSelectedStep(step => step + 1);
      if (step === currentStep) {
        setCurrentStep(step => step + 1);
      }
      return;
    }

    // Submit the form
    dispatch(createInfluencer({ ...formData, [FORM_INFO.PROP_NAME[FORM_INFO.NUM_OF_STEPS - 1]]: data }))
      .unwrap()
      .then(_ => {
        navigate('/influencers');
        notifyInfo('Influencer created successfully!');
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  const onBack = (data, step) => {
    if (step === 0) return;
    setSelectedStep(step => step - 1);
    setFormData(state => ({ ...state, [FORM_INFO.PROP_NAME[step]]: { ...data } }));
  };

  const renderMainContent = () => {
    switch (selectedStep) {
      case 0:
        return (
          <CreateInfluencerPageStepBasicInfo
            step={0}
            onAfterSubmit={onSubmit}
            formData={formData}
            influencerRequest={influencerRequest}
          />
        );
      case 1:
        return (
          <CreateInfluencerPageStepPrompts
            step={1}
            formData={formData}
            onAfterSubmit={onSubmit}
            onBack={onBack}
            influencerRequest={influencerRequest}
          />
        );
      default:
        return null;
    }
  };

  if (fetchInfluencerRequestByIdPending) {
    return <MainLoader />;
  }

  return (
    <>
      <CreateInfluencerSidebar currentStep={currentStep} selectedStep={selectedStep} onChangeStep={setSelectedStep} />
      {renderMainContent()}
    </>
  );
}

export default CreateInfluencerPage;
