import React, { useEffect } from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { ButtonsContainer, Label, ModalContainer, Row } from './WithdrawalPreviewModal.styles';
import { Text16Bold, Text16Regular } from '../../utils/texts/text.styles';
import { VALUE_OF_ONE_STAR_IN_USD, WITHDRAWAL_STATUS } from '../../../utils/constants';
import { STATUS } from '../../Table/items/WithdrawalItem/WithdrawalItem';
import { MainButton, TransparentButton } from '../../utils/buttons/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { acceptWithdrawal, declineWithdrawal } from '../../../store/slices/withdrawal/asyncThunks';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { selectAcceptWithdrawalPending, selectDeclineWithdrawalPending } from '../../../store/slices/withdrawal/slice';
import { useConfirmationModal } from '../../utils/modals/ConfirmationModal/ConfirmationModal';
import { WITHDRAWAL_METHOD_TYPE_TITLE } from '../../../pages/WithdrawalsPage/WithdrawalsPage';

const METHOD_LABELS = {
  address: 'Address',
  network: 'Network',
  beneficiaryName: 'Name',
  beneficiaryAddress: 'Address',
  accountNumber: 'Account Number',
  routingNumber: 'Routing Number',
  bankName: 'Bank Name',
  beneficiaryIBAN: 'IBAN',
  swiftCode: 'SWIFT Code',
  ssn: 'Social Security Number',
  profileType: 'Profile Type',
  type: 'Type',
};

const WithdrawalPreviewModal = ({ modalRef, setSelectedWithdrawal, selectedWithdrawal, onSuccess }) => {
  const dispatch = useDispatch();
  const declineWithdrawalPending = useSelector(selectDeclineWithdrawalPending);
  const acceptWithdrawalPending = useSelector(selectAcceptWithdrawalPending);

  const [openModal, closeModal, setConfirmationModalLoader] = useConfirmationModal();

  useEffect(() => {
    setConfirmationModalLoader(acceptWithdrawalPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptWithdrawalPending]);

  useEffect(() => {
    setConfirmationModalLoader(declineWithdrawalPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [declineWithdrawalPending]);

  const handleDecline = () => {
    openModal(
      'Decline Withdrawal',
      `Are you sure you want to decline the withdrawal of ${selectedWithdrawal.influencer.name}?`,
      'Decline',
      () => {
        dispatch(declineWithdrawal({ withdrawalId: selectedWithdrawal.id }))
          .unwrap()
          .then(_ => {
            closeModal();
            modalRef.current.hide();
            notifyInfo('Withdrawal declined successfully!');
            onSuccess();
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Cancel',
      () => {
        closeModal();
      },
    );
  };

  const handleAccept = () => {
    openModal(
      'Accept Withdrawal',
      `Are you sure you want to accept the withdrawal of ${selectedWithdrawal.influencer.name}?`,
      'Accept',
      () => {
        dispatch(acceptWithdrawal({ withdrawalId: selectedWithdrawal.id }))
          .unwrap()
          .then(_ => {
            closeModal();
            modalRef.current.hide();
            notifyInfo('Withdrawal accepted successfully!');
            onSuccess();
          })
          .catch(err => {
            notifyError(err.message);
          });
      },
      'Cancel',
      () => {
        closeModal();
      },
    );
  };

  return (
    <FormModal
      ref={modalRef}
      onClose={() => {
        modalRef.current.hide();
      }}
      title="Withdrawal Preview"
      onAfterClose={() => setSelectedWithdrawal(null)}
      shouldCloseOnOverlayClick>
      <ModalContainer>
        <Row>
          <Label>Influencer:</Label>
          <Text16Regular>{selectedWithdrawal?.influencer.name}</Text16Regular>
        </Row>
        <Row>
          <Label>Amount:</Label>
          <Text16Regular>${(selectedWithdrawal?.amount * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}</Text16Regular>
        </Row>
        <Row>
          <Label>Status:</Label>
          <Text16Regular>{STATUS[selectedWithdrawal?.status]}</Text16Regular>
        </Row>
        <Row>
          <Label>Time:</Label>
          <Text16Regular>{new Date(selectedWithdrawal?.createdAt).toLocaleString()}</Text16Regular>
        </Row>
        <Text16Bold>Withdrawal Method:</Text16Bold>
        {Object.entries(selectedWithdrawal?.withdrawalMethod || {}).map(
          ([key, value]) =>
            value && (
              <Row key={key}>
                <Label>{METHOD_LABELS[key]}:</Label>
                <Text16Regular>{key === 'type' ? WITHDRAWAL_METHOD_TYPE_TITLE[value] : value}</Text16Regular>
              </Row>
            ),
        )}
        {selectedWithdrawal?.status === WITHDRAWAL_STATUS.REQUESTED && (
          <ButtonsContainer>
            <TransparentButton title="Decline" onClick={handleDecline} />
            <MainButton title="Accept" onClick={handleAccept} />
          </ButtonsContainer>
        )}
      </ModalContainer>
    </FormModal>
  );
};

export default WithdrawalPreviewModal;
