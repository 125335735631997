import styled from 'styled-components';
import { Text16Bold } from '../../utils/texts/text.styles';

export const Container = styled.div`
  min-height: 50rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const CaptionCard = styled.div`
  padding: 1.6rem;
  border-radius: 10px;
  background-color: #f5f5f5;
  box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.1);
`;

export const NoCaptionsText = styled(Text16Bold)`
  text-align: center;
  margin: auto;
`;
