import React, { Fragment, useCallback } from 'react';
import {
  CenteredContainer,
  ExpandableTableHeaderItem,
  NoDataText,
  Spinner,
  StyledTable,
  TableBody,
  TableBodyItem,
  TableBodyRow,
  TableFooter,
  TableHeader,
  TableHeaderItem,
  TableHeaderRow,
  TableWrapper,
} from './Table.styles';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';
import Pagination from './Pagination/Pagination';
import SpinnerGIF from '../../assets/images/spinner.gif';

export const ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const Table = ({
  children,
  currentPage,
  totalCount,
  perPage,
  setPage,
  headerInformation,
  sortOptions,
  setSortOptions,
  emptyDataText,
  expandable,
  dataLoading,
  fullHeight,
}) => {
  const onSort = key => {
    if (sortOptions.order === ORDER.ASC && !sortOptions.sortBy) {
      setSortOptions({ ...sortOptions, sortBy: key });
    } else if (key !== sortOptions.sortBy) {
      setSortOptions({ sortBy: key, order: ORDER.ASC });
    } else if (sortOptions.order === ORDER.ASC && sortOptions.sortBy) {
      setSortOptions({ sortBy: key, order: ORDER.DESC });
    } else {
      setSortOptions({ sortBy: '', order: ORDER.ASC });
    }
  };

  const renderTableBody = useCallback(() => {
    if (dataLoading) {
      return (
        <TableBodyRow>
          <TableBodyItem>
            <CenteredContainer>
              <Spinner src={SpinnerGIF} />
            </CenteredContainer>
          </TableBodyItem>
        </TableBodyRow>
      );
    }

    if (!children || !children.length) {
      return (
        <TableBodyRow>
          <TableBodyItem>
            <CenteredContainer>
              <NoDataText>{emptyDataText}</NoDataText>
            </CenteredContainer>
          </TableBodyItem>
        </TableBodyRow>
      );
    }

    return children.map((child, index) => {
      if (expandable) {
        return <Fragment key={index}>{child}</Fragment>;
      }
      const onClick = child?.props?.onDetailsClicked;
      return (
        <TableBodyRow key={index} $pointer={!!onClick} onClick={() => (onClick ? onClick() : null)}>
          {child}
        </TableBodyRow>
      );
    });
  }, [children, dataLoading, emptyDataText, expandable]);

  return (
    <TableWrapper $fullHeight={fullHeight}>
      <StyledTable>
        <TableHeader>
          <TableHeaderRow>
            {expandable && <ExpandableTableHeaderItem />}
            {headerInformation.map((item, index) => {
              return (
                <TableHeaderItem
                  key={index}
                  $sortable={item.key}
                  $sorted={item.key && item.key === sortOptions?.sortBy}
                  onClick={() => {
                    item.key && item.sortable && onSort(item.key);
                  }}
                  $isAction={item.type === 'action'}
                  $fixedWidth={item.fixedWidth}>
                  {item.label}
                  {item.key && item.sortable && (
                    <>
                      {sortOptions.order === ORDER.DESC && item.key === sortOptions.sortBy ? (
                        <ArrowUp fill="green" />
                      ) : (
                        <ArrowDown fill={item.key === sortOptions.sortBy ? 'green' : '#9bacd8'} />
                      )}
                    </>
                  )}
                </TableHeaderItem>
              );
            })}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>{renderTableBody()}</TableBody>
      </StyledTable>
      <TableFooter>
        {totalCount > perPage && (
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            perPage={perPage}
            onPageChange={page => setPage(page)}
          />
        )}
      </TableFooter>
    </TableWrapper>
  );
};

export default Table;
