import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Container,
  Content,
  Header,
  InfiniteScrollMarker,
  SearchInput,
  Spinner,
  SpinnerContainer,
  Title,
} from './FactsPage.styles';
import { InfluencerFactsCard } from '../../components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchInfluencerFactRequestsInfo } from '../../store/slices/influencerFact/slice';
import { fetchInfluencerFactRequests } from '../../store/slices/influencerFact/asyncThunks';
import debounce from 'lodash.debounce';
import SpinnerGIF from '../../assets/images/spinner.gif';

const FactsPage = () => {
  const dispatch = useDispatch();
  const loadMoreRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const search = searchParams.get('search');
  const [searchValue, setSearchValue] = useState(() => search ?? '');
  const { data, maxReached, pending, fetchMorePending } = useSelector(selectFetchInfluencerFactRequestsInfo);
  const navigate = useNavigate();

  const changeHandler = useCallback(
    text => {
      searchParams.set('search', text);
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  useEffect(() => {
    setPage(1);
    dispatch(fetchInfluencerFactRequests({ page: 1, search: search || '' }));
  }, [dispatch, search]);

  const handleIntersection = useCallback(
    entries => {
      const [entry] = entries;
      if (entry.isIntersecting && !pending && !fetchMorePending && !maxReached) {
        setPage(prev => prev + 1);
        dispatch(fetchInfluencerFactRequests({ page: page + 1, search: search || '' }));
      }
    },
    [pending, maxReached, fetchMorePending, page, dispatch, search],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, { root: null, threshold: 1.0 });
    const target = loadMoreRef.current;
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [handleIntersection]);

  return (
    <Container>
      <Header>
        <Title>Facts</Title>
        <SearchInput
          value={searchValue}
          onChange={event => {
            setSearchValue(event.target.value);
            debouncedChangeHandler(event.target.value);
          }}
          placeholder="Search"
          hideErrorMessage
        />
      </Header>
      <Content>
        {pending ? (
          <SpinnerContainer>
            <Spinner src={SpinnerGIF} />
          </SpinnerContainer>
        ) : (
          data.map((item, index) => (
            <InfluencerFactsCard
              key={index}
              name={item.influencerName}
              totalFactRequests={item.totalFactRequests}
              onClick={() => {
                navigate(`/facts/${item.influencerId}`, { state: { influencerName: item.influencerName } });
              }}
            />
          ))
        )}
      </Content>
      <InfiniteScrollMarker ref={loadMoreRef} />
    </Container>
  );
};

export default FactsPage;
