import { createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../../../api/services/user';

export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async () => {
  return await userService.fetchUserInfo();
});

export const fetchUsers = createAsyncThunk('user/fetchUsers', async ({ page, search }) => {
  return await userService.fetchUsers(page, search);
});

export const fetchUsersByInfluencer = createAsyncThunk('user/fetchUsersByInfluencer', async requestData => {
  const { influencerId, page, search } = requestData;
  return await userService.fetchUsersByInfluencer(influencerId, page, search);
});

export const fetchUserById = createAsyncThunk('user/fetchUserById', async userId => {
  return await userService.fetchUserById(userId);
});

export const changePassword = createAsyncThunk('user/changePassword', async ({ currentPassword, newPassword }) => {
  return await userService.changePassword(currentPassword, newPassword);
});
