import { login } from '../auth/asyncThunks';
import { changePassword, fetchUserById, fetchUserInfo, fetchUsers, fetchUsersByInfluencer } from './asyncThunks';

export const fetchUserInfoBuilder = builder => {
  builder.addCase(fetchUserInfo.pending, state => {
    state.fetchUserInfoPending = true;
  });
  builder.addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
    const { user } = payload;
    state.user = user;
    state.fetchUserInfoPending = false;
  });
  builder.addCase(fetchUserInfo.rejected, state => {
    state.fetchUserInfoPending = false;
  });
};

export const loginBuilder = builder => {
  builder.addCase(login.fulfilled, (state, { payload }) => {
    const { user } = payload;
    state.user = user;
  });
};

export const fetchUsersBuilder = builder => {
  builder.addCase(fetchUsers.pending, state => {
    state.fetchUsersInfo.pending = true;
  });
  builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchUsersInfo.pending = false;
    state.fetchUsersInfo.data = data;
    state.fetchUsersInfo.total = total;
  });
  builder.addCase(fetchUsers.rejected, state => {
    state.fetchUsersInfo.pending = false;
  });
};

export const fetchUserByIdBuilder = builder => {
  builder.addCase(fetchUserById.pending, state => {
    state.selectedUserInfo.pending = true;
  });
  builder.addCase(fetchUserById.fulfilled, (state, { payload }) => {
    state.selectedUserInfo.user = payload;
    state.selectedUserInfo.pending = false;
  });
  builder.addCase(fetchUserById.rejected, state => {
    state.selectedUserInfo.pending = false;
  });
};

export const fetchUsersByInfluencerBuilder = builder => {
  builder.addCase(fetchUsersByInfluencer.pending, state => {
    state.fetchUsersByInfluencerInfo.pending = true;
  });
  builder.addCase(fetchUsersByInfluencer.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchUsersByInfluencerInfo.pending = false;
    state.fetchUsersByInfluencerInfo.data = data;
    state.fetchUsersByInfluencerInfo.total = total;
  });
  builder.addCase(fetchUsersByInfluencer.rejected, state => {
    state.fetchUsersByInfluencerInfo.pending = false;
  });
};

export const changePasswordBuilder = builder => {
  builder.addCase(changePassword.pending, state => {
    state.pending.changePassword = true;
  });
  builder.addCase(changePassword.fulfilled, state => {
    state.pending.changePassword = false;
  });
  builder.addCase(changePassword.rejected, state => {
    state.pending.changePassword = false;
  });
};
