import styled from 'styled-components';
import { MainButton } from '../../../../components/utils/buttons/Button/Button';
import { Text20Bold } from '../../../../components/utils/texts/text.styles';
import Input from '../../../../components/utils/inputs/Input/Input';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 40rem;
  height: auto;
`;

export const StyledButton = styled(MainButton)`
  width: 20rem;
`;

export const InstantDMContainer = styled.div`
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
`;

export const Title = styled(Text20Bold)`
  margin-bottom: 2rem;
`;

export const StyledInput = styled(Input)`
  width: 40rem;
`;

export const StyledNumberInput = styled(Input)`
  width: 40rem;
`;
