import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createInfluencerStepBasicInfoFormSchema } from '../../../../../forms/schemas/influencer.schema';
import { CreateInfluencerForm, Footer, FooterRightButton } from '../../CreateInfluencerPage.styles';
import { ReactComponent as ForwardIcon } from '../../../../../assets/icons/forward.svg';
import {
  FormDatePicker,
  FormFileInput,
  FormInput,
  FormNumericInput,
  FormSelect,
} from '../../../../../forms/form.styles';
import {
  Container,
  ContainerLeftPart,
  ContainerRightPart,
  RatioContainer,
  TimePickersRow,
  UploadRow,
} from './CreateInfluencerPageStepBasicInfo.styles';
import {
  CHAT_TYPE,
  CHAT_TYPE_TITLE,
  PERSONA_STYLE,
  PERSONA_STYLE_TITLE,
  UPLOAD_FILE_CONSTRAINTS,
} from '../../../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchInfluencerVariablesInfoData } from '../../../../../store/slices/influencer/slice';
import { fetchInfluencerVariables } from '../../../../../store/slices/influencer/asyncThunks';
import CropImageModal from '../../../../../components/utils/modals/CropImageModal/CropImageModal';
import HandleVoiceProvidersModal from '../../../../../components/modals/HandleVoiceProvidersModal/HandleVoiceProvidersModal';
import Select from '../../../../../components/utils/selects/Select/Select';

function CreateInfluencerPageStepBasicInfo({ formData, onAfterSubmit, step, influencerRequest }) {
  const influencerVariables = useSelector(selectFetchInfluencerVariablesInfoData);
  const cropImageRef = useRef();
  const handleVoiceProvidersModalRef = useRef();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      name: '',
      nickname: '',
      username: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      dateOfBirth: null,
      profilePhoto: null,
      businessConnection: null,
      paymentRatio: 0.5,
      voiceResponseRatio: 0.5,
      subscriptionPrice: 400,
      starterThreshold: 10,
      telegramChannelUrl: '',
      voiceId: '',
      influencerRequestId: null,
      cartesiaId: '',
      promptGroup: null,
      personality: '',
      timezone: null,
      personaStyle: null,
      chatType: null,
      sleepingFrom: null,
      sleepingUntil: null,
    },
    delayError: 300,
    resolver: yupResolver(createInfluencerStepBasicInfoFormSchema),
  });

  const watchProfilePhoto = watch('profilePhoto');
  const watchVoiceProviders = watch('voiceProviders');
  const watchSleepingFrom = watch('sleepingFrom');

  useEffect(() => {
    if (!influencerVariables) {
      dispatch(fetchInfluencerVariables());
    }
  }, [dispatch, influencerVariables]);

  useEffect(() => {
    if (influencerRequest && !formData.basicInfo) {
      setValue('influencerRequestId', influencerRequest.id);
      setValue('name', influencerRequest.name);
      setValue('email', influencerRequest.email);
      setValue('profilePhoto', influencerRequest.profilePhoto);
      setValue('dateOfBirth', influencerRequest.dateOfBirth);
    }
  }, [influencerRequest, setValue, formData.basicInfo]);

  useEffect(() => {
    if (formData.basicInfo) {
      setValue('name', formData.basicInfo.name);
      setValue('nickname', formData.basicInfo.nickname);
      setValue('username', formData.basicInfo.username);
      setValue('email', formData.basicInfo.email);
      setValue('password', formData.basicInfo.password);
      setValue('passwordConfirmation', formData.basicInfo.passwordConfirmation);
      setValue('dateOfBirth', formData.basicInfo.dateOfBirth);
      setValue('profilePhoto', formData.basicInfo.profilePhoto);
      setValue('businessConnection', formData.basicInfo.businessConnection);
      setValue('paymentRatio', formData.basicInfo.paymentRatio);
      setValue('voiceResponseRatio', formData.basicInfo.voiceResponseRatio);
      setValue('subscriptionPrice', formData.basicInfo.subscriptionPrice);
      setValue('starterThreshold', formData.basicInfo.starterThreshold)
      if (formData.basicInfo.telegramChannelUrl) {
        setValue('telegramChannelUrl', formData.basicInfo.telegramChannelUrl);
      }
      setValue('cartesiaId', formData.basicInfo.cartesiaId);
      setValue('influencerRequestId', formData.basicInfo.influencerRequestId);
      setValue('promptGroup', formData.basicInfo.promptGroup);
      setValue('personality', formData.basicInfo.personality);
      setValue('timezone', formData.basicInfo.timezone);
      setValue('personaStyle', formData.basicInfo.personaStyle);
      setValue('chatType', formData.basicInfo.chatType);
    }
  }, [formData.basicInfo, setValue]);

  useEffect(() => {
    if (watchProfilePhoto) {
      if (typeof watchProfilePhoto !== 'string') {
        cropImageRef.current.show();
      }
    }
  }, [watchProfilePhoto]);

  const promptGroupOptions = useMemo(() => {
    if (!influencerVariables) return [];
    return influencerVariables.promptGroups.map(promptGroup => ({
      value: promptGroup.id,
      label: promptGroup.name,
    }));
  }, [influencerVariables]);

  const timezoneOptions = useMemo(() => {
    if (!influencerVariables) return [];
    return influencerVariables.timezones.map(timezone => ({
      value: timezone.id,
      label: timezone.name,
    }));
  }, [influencerVariables]);

  const chatTypeOptions = [
    {
      label: CHAT_TYPE_TITLE.FREE_CHAT,
      value: CHAT_TYPE.FREE_CHAT,
    },
    {
      label: CHAT_TYPE_TITLE.PAID_CHAT,
      value: CHAT_TYPE.PAID_CHAT,
    },
  ];

  const personaStyleOptions = [
    {
      label: PERSONA_STYLE_TITLE.BRAND_FRIENDLY,
      value: PERSONA_STYLE.BRAND_FRIENDLY,
    },
    {
      label: PERSONA_STYLE_TITLE.SEXUAL,
      value: PERSONA_STYLE.SEXUAL,
    },
  ];

  const onSubmit = data => {
    onAfterSubmit(data, step);
  };

  return (
    <>
      <CreateInfluencerForm>
        <Container>
          <ContainerLeftPart>
            <FormInput label="Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
            <FormInput
              label="Nickname"
              placeholder="Enter nickname"
              {...register('nickname')}
              error={errors.nickname?.message}
            />
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <FormInput
                  label="Username"
                  placeholder="Enter username"
                  onChange={e => field.onChange(e.target.value.toLowerCase())}
                  value={field.value}
                  error={errors.username?.message}
                />
              )}
            />
            <FormInput
              label="Email"
              disabled={!!influencerRequest}
              placeholder="Enter email"
              {...register('email')}
              error={errors.email?.message}
            />
            {!influencerRequest && (
              <>
                <FormInput
                  label="Password"
                  placeholder="Enter password"
                  {...register('password')}
                  error={errors.password?.message}
                  type="password"
                />
                <FormInput
                  label="Password confirmation"
                  placeholder="Enter password confirmation"
                  {...register('passwordConfirmation')}
                  error={errors.passwordConfirmation?.message}
                  type="password"
                />
              </>
            )}
            <Controller
              name="dateOfBirth"
              control={control}
              render={({ field }) => {
                return (
                  <FormDatePicker
                    label="Date of birth"
                    placeholder="Enter date of birth"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.dateOfBirth?.message}
                  />
                );
              }}
            />
            <TimePickersRow>
              <Controller
                name="sleepingFrom"
                control={control}
                render={({ field }) => {
                  return (
                    <FormDatePicker
                      label="Sleeping from"
                      placeholder="Enter sleeping from"
                      value={field.value}
                      onChange={value => {
                        if (!value) {
                          setValue('sleepingUntil', null);
                        }

                        field.onChange(value);
                      }}
                      error={errors.sleepingFrom?.message}
                      showTimeSelect
                      showTimeSelectOnly
                      dateFormat="HH:mm"
                    />
                  );
                }}
              />
              <Controller
                name="sleepingUntil"
                control={control}
                render={({ field }) => {
                  return (
                    <FormDatePicker
                      label="Sleeping until"
                      placeholder="Enter sleeping until"
                      value={field.value}
                      onChange={field.onChange}
                      error={errors.sleepingUntil?.message}
                      showTimeSelect
                      showTimeSelectOnly
                      dateFormat="HH:mm"
                      disabled={!watchSleepingFrom}
                    />
                  );
                }}
              />
            </TimePickersRow>
            <FormInput
              label="Personality"
              placeholder="Enter personality"
              {...register('personality')}
              error={errors.personality?.message}
              type="textarea"
            />
          </ContainerLeftPart>
          <ContainerRightPart>
            <UploadRow>
              <Controller
                name="profilePhoto"
                control={control}
                render={({ field }) => {
                  return (
                    <FormFileInput
                      label="Profile photo"
                      value={field.value}
                      onChange={field.onChange}
                      type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
                      error={errors.profilePhoto?.message}
                    />
                  );
                }}
              />
              <RatioContainer>
                <FormNumericInput
                  label="Payment ratio"
                  {...register('paymentRatio', {
                    valueAsNumber: true,
                  })}
                  error={errors.paymentRatio?.message}
                />
                <FormNumericInput
                  label="Voice Response ratio"
                  {...register('voiceResponseRatio', {
                    valueAsNumber: true,
                  })}
                  error={errors.voiceResponseRatio?.message}
                />
              </RatioContainer>
            </UploadRow>
            <Controller
              name="businessConnection"
              control={control}
              render={({ field }) => (
                <FormSelect
                  placeholder="Select business connection"
                  label="Business Connection"
                  options={
                    influencerVariables
                      ? influencerVariables.businessConnections.map(value => ({ value, label: value.name }))
                      : []
                  }
                  selectedOption={field.value}
                  handleChange={value => field.onChange(value)}
                  error={errors.businessConnection?.message}
                />
              )}
            />
            <FormInput
              label="Telegram Channel URL"
              placeholder="Enter Telegram Channel URL"
              {...register('telegramChannelUrl')}
              error={errors.telegramChannelUrl?.message}
            />
            <FormInput
              label="Cartesia ID"
              placeholder="Cartesia ID"
              {...register('cartesiaId')}
              error={errors.cartesiaId?.message}
            />
            <Controller
              name="timezone"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    label="Timezone"
                    selectedOption={field.value}
                    handleChange={field.onChange}
                    options={timezoneOptions}
                    error={errors.timezone?.message}
                  />
                );
              }}
            />
            <Controller
              name="personaStyle"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    label="Persona Style"
                    selectedOption={field.value}
                    handleChange={field.onChange}
                    options={personaStyleOptions}
                    error={errors.personaStyle?.message}
                  />
                );
              }}
            />
            <Controller
              name="chatType"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    label="Chat Type"
                    selectedOption={field.value}
                    handleChange={field.onChange}
                    options={chatTypeOptions}
                    error={errors.chatType?.message}
                  />
                );
              }}
            />
            <Controller
              name="promptGroup"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    label="Prompt Group"
                    selectedOption={field.value}
                    handleChange={field.onChange}
                    options={promptGroupOptions}
                    isClearable
                  />
                );
              }}
            />
            <FormNumericInput
              label="Subscription Price"
              {...register('subscriptionPrice', {
                valueAsNumber: true,
              })}
              error={errors.subscriptionPrice?.message}
            />
            <FormNumericInput
              label="Starter Threshold"
              {...register('starterThreshold', {
                valueAsNumber: true,
              })}
              error={errors.starterThreshold?.message}
            />
          </ContainerRightPart>
        </Container>
      </CreateInfluencerForm>
      <Footer>
        <div />
        <FooterRightButton
          title="Next Step"
          icon={<ForwardIcon fill="#fff" />}
          onClick={() => handleSubmit(onSubmit)()}
        />
      </Footer>
      <HandleVoiceProvidersModal
        modalRef={handleVoiceProvidersModalRef}
        onSetVoiceProviders={data => setValue('voiceProviders', data, { shouldValidate: true })}
        voiceProviders={watchVoiceProviders}
      />
      <CropImageModal
        ref={cropImageRef}
        onClose={() => {
          setValue('profilePhoto', null);
          cropImageRef.current.hide();
        }}
        onComplete={croppedImage => {
          setValue('profilePhoto', croppedImage);
          cropImageRef.current.hide();
        }}
        originalImage={getValues('profilePhoto')}
      />
    </>
  );
}

export default CreateInfluencerPageStepBasicInfo;
