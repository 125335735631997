import React from 'react';
import { SwitchButton, SwitchCircle, SwitchContainer, SwitchText } from './Switch.styles';

const Switch = ({ label, selectedValue, onChange, circleColor }) => {
  return (
    <SwitchContainer>
      <SwitchText>{label}</SwitchText>
      <SwitchButton onClick={() => onChange(!selectedValue)}>
        <SwitchCircle $circleColor={circleColor} $rightValueSelected={selectedValue} />
      </SwitchButton>
    </SwitchContainer>
  );
};

export default Switch;
