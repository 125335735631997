import { createPromptGroup, deletePromptGroup, editPromptGroup, fetchPromptGroups } from './asyncThunks';

export const fetchPromptGroupsBuilder = builder => {
  builder.addCase(fetchPromptGroups.pending, state => {
    state.fetchPromptGroupInfo.pending = true;
  });
  builder.addCase(fetchPromptGroups.fulfilled, (state, { payload }) => {
    const { data, total } = payload;
    state.fetchPromptGroupInfo.pending = false;
    state.fetchPromptGroupInfo.data = data;
    state.fetchPromptGroupInfo.total = total;
  });
  builder.addCase(fetchPromptGroups.rejected, state => {
    state.fetchPromptGroupInfo.pending = false;
  });
};

export const editPromptGroupBuilder = builder => {
  builder.addCase(editPromptGroup.pending, state => {
    state.pending.editPromptGroup = true;
  });
  builder.addCase(editPromptGroup.fulfilled, (state, { payload }) => {
    const { promptGroup } = payload;
    state.pending.editPromptGroup = false;
    state.fetchPromptGroupInfo.data = state.fetchPromptGroupInfo.data.map(pg => {
      if (pg.id === promptGroup.id) {
        return promptGroup;
      }
      return pg;
    });
  });
  builder.addCase(editPromptGroup.rejected, state => {
    state.pending.editPromptGroup = false;
  });
};

export const createPromptGroupBuilder = builder => {
  builder.addCase(createPromptGroup.pending, state => {
    state.pending.createPromptGroup = true;
  });
  builder.addCase(createPromptGroup.fulfilled, (state, { payload }) => {
    const { promptGroup } = payload;
    state.pending.createPromptGroup = false;
    state.fetchPromptGroupInfo.data = [...state.fetchPromptGroupInfo.data, promptGroup];
  });
  builder.addCase(createPromptGroup.rejected, state => {
    state.pending.createPromptGroup = false;
  });
};

export const deletePromptGroupBuilder = builder => {
  builder.addCase(deletePromptGroup.pending, state => {
    state.pending.deletePromptGroup = true;
  });
  builder.addCase(deletePromptGroup.fulfilled, (state, { payload }) => {
    const { promptGroupId } = payload;
    state.pending.deletePromptGroup = false;
    state.fetchPromptGroupInfo.data = state.fetchPromptGroupInfo.data.filter(pg => pg.id !== promptGroupId);
  });
  builder.addCase(deletePromptGroup.rejected, state => {
    state.pending.deletePromptGroup = false;
  });
};
