import styled from 'styled-components';
import { MainButton } from '../../utils/buttons/Button/Button';

export const ModalContainer = styled.div`
  display: flex;
  height: 70rem;
  flex-direction: column;
`;

export const PhotosContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const VideosContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 2rem;
`;

export const PhotosContent = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
`;
export const VideosContent = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
`;

export const MediaCardContainer = styled.div`
  width: 14rem;
  height: 14rem;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: ${({ $selected }) => ($selected ? '1px solid #843efe' : '1px solid transparent')};
  opacity: ${({ $disabled }) => ($disabled ? '0.5' : '1')};
  position: relative;
`;

export const Ribbon = styled.div`
  position: absolute;
  height: 2rem;
  transform: rotate(42.691deg);
  right: -2rem;
  top: 1.7rem;
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #843efe;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
`;

export const StarContainer = styled.div`
  width: 9rem;
  height: 3rem;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #00000080;
  border-radius: 1rem;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Spinner = styled.img`
  width: 20rem;
  height: 20rem;
`;

export const NextPageButton = styled(MainButton)`
  width: 4.4rem;
  height: 4.4rem;
  padding: unset;
  margin: auto 0 auto 1rem;
`;


export const TabContainer = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 3.2rem;
`;


export const TabButton = styled.div`
  border-bottom: ${({ $active }) => ($active ? '5px solid #843efe' : 'none')};
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 0.6px 1rem;
  cursor: pointer;
  font-size: 1.6rem;
  margin-right: 3rem;
`;

export const InfiniteScrollMarker = styled.div`
  height: 10rem;
`