import React, { Fragment } from 'react';
import { TableBodyItem, TableItemImageSmall } from '../../Table.styles';
import { useNavigate } from 'react-router-dom';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../../../utils/constants';
import { Row } from '../../../utils/utils.styles';

function UserTableItem({ userId, name, influencers, totalSpent }) {
  const navigate = useNavigate();
  return (
    <Fragment>
      <TableBodyItem>
        <p>{name || '-'}</p>
      </TableBodyItem>
      <TableBodyItem>
        <Row>
          {influencers.slice(0, 5).map(influencer => (
            <TableItemImageSmall
              key={influencer.username}
              src={influencer.profilePhoto}
              alt={influencer.username}
              title={`${influencer.name} (${influencer.username})`}
              onClick={e => {
                e.stopPropagation();
                navigate(`/influencers/${influencer.id}`);
              }}
            />
          ))}
          {influencers.length > 5 && <p>+{`${influencers.length - 5}`}</p>}
        </Row>
      </TableBodyItem>
      <TableBodyItem>
        <p>{`${totalSpent} ⭐️ = $${(totalSpent * VALUE_OF_ONE_STAR_IN_USD).toFixed(1)} ` || '-'}</p>
      </TableBodyItem>
    </Fragment>
  );
}

export default UserTableItem;
