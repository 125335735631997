import { createAsyncThunk } from '@reduxjs/toolkit';
import influencerService from '../../../api/services/influencer';
import { MEDIA_CATEGORY } from '../../../utils/constants';
import { base64ToFile } from '../../../utils/util';
import { format, toDate } from 'date-fns-tz';

export const fetchInfluencers = createAsyncThunk('influencer/fetchInfluencers', async ({ page, search }) => {
  return await influencerService.fetchInfluencers(page, search);
});

export const fetchInfluencerById = createAsyncThunk('influencer/fetchInfluencerById', async influencerId => {
  return await influencerService.fetchInfluencerById(influencerId);
});

export const createInfluencer = createAsyncThunk('influencer/createInfluencer', async requestData => {
  const {
    name,
    nickname,
    username,
    email,
    password,
    dateOfBirth,
    profilePhoto,
    businessConnection,
    paymentRatio,
    voiceResponseRatio,
    telegramChannelUrl,
    voiceId,
    influencerRequestId,
    cartesiaId,
    promptGroup,
    personality,
    timezone,
    chatType,
    personaStyle,
    subscriptionPrice,
    starterThreshold,
    sleepingFrom,
    sleepingUntil,
  } = requestData.basicInfo;

  const { prompt } = requestData.prompt;

  const formData = new FormData();

  // Basic influencer info
  if (profilePhoto && profilePhoto.startsWith('data:')) {
    const profilePhotoFile = base64ToFile(profilePhoto, 'profile-photo');
    formData.append('file', profilePhotoFile, profilePhotoFile.name);
  }

  if (influencerRequestId) {
    formData.append('influencerRequestId', influencerRequestId);
  }
  formData.append('name', name);
  formData.append('nickname', nickname);
  formData.append('username', username);
  formData.append('email', email);
  if (password) {
    formData.append('password', password);
  }
  formData.append('dateOfBirth', dateOfBirth);
  formData.append('voiceId', voiceId);
  formData.append('businessConnectionId', businessConnection.value.id);
  if (promptGroup) {
    formData.append('promptGroupId', promptGroup.value);
  }
  formData.append('personality', personality);
  formData.append('timezone', timezone.value);
  formData.append('personaStyle', personaStyle.value);
  formData.append('chatType', chatType.value);
  formData.append('voiceResponseRatio', voiceResponseRatio);
  formData.append('paymentRatio', paymentRatio);
  formData.append('pricing[subscription]', subscriptionPrice);
  formData.append('starterThreshold', starterThreshold);
  if (telegramChannelUrl) {
    formData.append('telegramChannelUrl', telegramChannelUrl);
  }

  formData.append('voiceProvider[cartesia][voiceId]', cartesiaId);

  // Main - male
  formData.append(`prompt[male][mainMessage][base][template]`, prompt.male.mainMessage.base.template);

  // Main - female
  formData.append(`prompt[female][mainMessage][base][template]`, prompt.male.mainMessage.base.template);

  // sleeping
  if (sleepingFrom && sleepingUntil) {
    formData.append(`sleepingPeriod[from][hour]`, sleepingFrom.getHours());
    formData.append(`sleepingPeriod[from][minute]`, sleepingFrom.getMinutes());
    formData.append(`sleepingPeriod[until][hour]`, sleepingUntil.getHours());
    formData.append(`sleepingPeriod[until][minute]`, sleepingUntil.getMinutes());
  }

  return await influencerService.createInfluencer(formData);
});

export const editInfluencer = createAsyncThunk('influencer/editInfluencer', async (requestData, { getState }) => {
  const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
  const {
    profilePhoto,
    name,
    nickname,
    email,
    dateOfBirth,
    paymentRatio,
    telegramChannelUrl,
    cartesiaId,
    voiceResponseRatio,
    promptGroup,
    personality,
    timezone,
    chatType,
    personaStyle,
    subscriptionPrice,
    starterThreshold,
    massDMEnabled,
    sleepingFrom,
    sleepingUntil,
    liveStreamFrom,
    liveStreamUntil,
    deleted,
  } = requestData;

  const formData = new FormData();
  if (profilePhoto && profilePhoto.startsWith('data:')) {
    const profilePhotoFile = base64ToFile(profilePhoto, 'profile-photo');
    formData.append('file', profilePhotoFile, profilePhotoFile.name);
  }

  formData.append('name', name);
  formData.append('nickname', nickname);
  formData.append('email', email);
  formData.append('dateOfBirth', dateOfBirth);
  formData.append('paymentRatio', paymentRatio);
  formData.append('voiceResponseRatio', voiceResponseRatio);
  formData.append('pricing[subscription]', subscriptionPrice);
  formData.append('starterThreshold', starterThreshold);
  if (promptGroup) {
    formData.append('promptGroupId', promptGroup.value);
  }
  formData.append('personality', personality);
  formData.append('timezone', timezone.value);
  formData.append('personaStyle', personaStyle.value);
  formData.append('chatType', chatType.value);
  if (telegramChannelUrl) {
    formData.append('telegramChannelUrl', telegramChannelUrl);
  }

  formData.append('voiceProvider[cartesia][voiceId]', cartesiaId);
  formData.append('massDM[enabled]', massDMEnabled);
  formData.append('deleted', deleted);

  // sleeping
  if (sleepingFrom && sleepingUntil) {
    formData.append(`sleepingPeriod[from][hour]`, sleepingFrom.getHours());
    formData.append(`sleepingPeriod[from][minute]`, sleepingFrom.getMinutes());
    formData.append(`sleepingPeriod[until][hour]`, sleepingUntil.getHours());
    formData.append(`sleepingPeriod[until][minute]`, sleepingUntil.getMinutes());
  }

  // live stream
  if (liveStreamFrom && liveStreamUntil) {
    const dateFrom = toDate(liveStreamFrom);
    const dateUntil = toDate(liveStreamUntil);

    const targetDateFrom = format(dateFrom, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: timezone.value });
    const targetDateUntil = format(dateUntil, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: timezone.value });

    formData.append(`liveStream[from]`, targetDateFrom);
    formData.append(`liveStream[until]`, targetDateUntil);
  }
  return await influencerService.editInfluencer(selectedInfluencerId, formData);
});

export const editPrompts = createAsyncThunk('influencer/editPrompts', async (requestData, { getState }) => {
  const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
  let body = requestData;
  body.prompt.female = body.prompt.male;
  return await influencerService.editPrompts(selectedInfluencerId, requestData);
});

export const fetchInfluencerAlbumMedia = createAsyncThunk('influencer/fetchInfluencerAlbumMedia', async requestData => {
  const { influencerId, mediaType, mediaCategory, page } = requestData;
  return await influencerService.fetchInfluencerAlbumMedia(influencerId, mediaType, mediaCategory, page || null);
});

export const editInfluencerAlbumMedia = createAsyncThunk(
  'influencer/editInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const {
      representativeAlbumMedia,
      albumMediaId,
      caption,
      mediaType,
      fileId,
      aiDescription,
      tag,
      cost,
      mediaCategory,
      representativeFileId,
      representativeFileType,
    } = requestData;

    const formData = new FormData();
    if (mediaCategory === MEDIA_CATEGORY.PREMIUM) {
      if (representativeAlbumMedia) {
        formData.append('representativeFile', representativeAlbumMedia.file);
      }
      formData.append('cost', cost);
      formData.append('caption', caption);
      formData.append('aiDescription', aiDescription);
      formData.append('tag', tag);
      if (representativeFileId) formData.append('representativeFileId', representativeFileId);
      if (representativeFileType) formData.append('representativeFileType', representativeFileType);
    }

    if (mediaCategory === MEDIA_CATEGORY.FREE) {
      formData.append('aiDescription', aiDescription);
      formData.append('caption', caption);
    }

    formData.append('fileId', fileId);

    return await influencerService.editInfluencerAlbumMedia(
      selectedInfluencerId,
      albumMediaId,
      mediaCategory,
      formData,
      mediaType,
    );
  },
);

export const uploadInfluencerAlbumMedia = createAsyncThunk(
  'influencer/uploadInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const {
      albumMedia,
      representativeAlbumMedia,
      cost,
      aiDescription,
      caption,
      mediaType,
      fileId,
      mediaCategory,
      tag,
      representativeFileId,
      representativeFileType,
    } = requestData;

    const formData = new FormData();
    formData.append('file', albumMedia.file);
    if (mediaCategory === MEDIA_CATEGORY.PREMIUM) {
      if (representativeAlbumMedia) {
        formData.append('representativeFile', representativeAlbumMedia.file);
      }
      formData.append('cost', cost);
      formData.append('caption', caption);
      formData.append('aiDescription', aiDescription);
      formData.append('tag', tag);
      if (representativeFileId) formData.append('representativeFileId', representativeFileId);
      if (representativeFileType) formData.append('representativeFileType', representativeFileType);
    }
    if (mediaCategory === MEDIA_CATEGORY.FREE) {
      formData.append('aiDescription', aiDescription);
      formData.append('caption', caption);
    }
    formData.append('fileId', fileId);

    return await influencerService.uploadInfluencerAlbumMedia(selectedInfluencerId, mediaCategory, formData, mediaType);
  },
);

export const fetchInfluencerVariables = createAsyncThunk('influencer/fetchInfluencerVariables', async () => {
  return await influencerService.fetchInfluencerVariables();
});

export const changeAlbumMediaPosition = createAsyncThunk('influencer/changeAlbumMediaPosition', async requestData => {
  const { influencerId, mediaType, mediaPositions } = requestData;
  return await influencerService.changeAlbumMediaPosition(influencerId, mediaType, mediaPositions);
});

export const fetchInfluencerBundleAlbumPhotos = createAsyncThunk(
  'influencer/fetchInfluencerBundleAlbumPhotos',
  async ({ page }, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.fetchInfluencerBundleAlbumPhotos(selectedInfluencerId, page);
  },
);

export const fetchInfluencerBundleAlbumVideos = createAsyncThunk(
  'influencer/fetchInfluencerBundleAlbumVideos',
  async ({ page }, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.fetchInfluencerBundleAlbumVideos(selectedInfluencerId, page);
  },
);

export const createMediaBundle = createAsyncThunk('influencer/createMediaBundle', async (requestData, { getState }) => {
  const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
  const {
    media,
    representativeAlbumMedia,
    cost,
    caption,
    aiDescription,
    representativeFileId,
    representativeFileType,
  } = requestData;

  const formData = new FormData();
  media.forEach((item, index) => {
    formData.append(`media[${index}][mediaId]`, item.mediaId);
    formData.append(`media[${index}][type]`, item.type);
  });
  if (representativeAlbumMedia) {
    formData.append('representativeFile', representativeAlbumMedia.file);
  }
  formData.append('cost', cost);
  formData.append('caption', caption);
  formData.append('aiDescription', aiDescription);
  if (representativeFileId) formData.append('representativeFileId', representativeFileId);
  if (representativeFileType) formData.append('representativeFileType', representativeFileType);

  return await influencerService.createMediaBundle(selectedInfluencerId, formData);
});

export const fetchInfluencerMediaBundles = createAsyncThunk(
  'influencer/fetchInfluencerMediaBundles',
  async (_, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.fetchInfluencerMediaBundles(selectedInfluencerId);
  },
);

export const fetchMediaBundleById = createAsyncThunk(
  'influencer/fetchMediaBundleById',
  async ({ mediaBundleId }, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.fetchMediaBundleById(selectedInfluencerId, mediaBundleId);
  },
);

export const editMediaBundle = createAsyncThunk('influencer/editMediaBundle', async (requestData, { getState }) => {
  const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
  const {
    caption,
    representativeAlbumMedia,
    aiDescription,
    mediaBundleId,
    cost,
    representativeFileId,
    representativeFileType,
  } = requestData;

  const formData = new FormData();
  if (representativeAlbumMedia) {
    formData.append('representativeFile', representativeAlbumMedia.file);
  }
  formData.append('cost', cost);
  formData.append('caption', caption);
  formData.append('aiDescription', aiDescription);
  if (representativeFileId) formData.append('representativeFileId', representativeFileId);
  if (representativeFileType) formData.append('representativeFileType', representativeFileType);

  return await influencerService.editMediaBundle(selectedInfluencerId, mediaBundleId, formData);
});

export const changeMediaBundlePosition = createAsyncThunk(
  'influencer/changeMediaBundlePosition',
  async (requestData, { getState }) => {
    const { mediaPositions } = requestData;
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;

    return await influencerService.changeMediaBundlePosition(selectedInfluencerId, mediaPositions);
  },
);

export const deleteInfluencerAlbumMedia = createAsyncThunk(
  'influencer/deleteInfluencerAlbumMedia',
  async (requestData, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    const { albumMediaId, mediaType, mediaCategory, mediaTag } = requestData;

    return await influencerService.deleteInfluencerAlbumMedia(
      selectedInfluencerId,
      albumMediaId,
      mediaType,
      mediaCategory,
      mediaTag,
    );
  },
);

export const deleteMediaBundle = createAsyncThunk(
  'influencer/deleteMediaBundle',
  async ({ mediaBundleId }, { getState }) => {
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.deleteMediaBundle(selectedInfluencerId, mediaBundleId);
  },
);
export const fetchCaptionRecommendations = createAsyncThunk(
  'influencer/fetchCaptionRecommendations',
  async (requestData, { getState }) => {
    const { mediaId, mediaType } = requestData;
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.fetchCaptionRecommendations(selectedInfluencerId, mediaId, mediaType);
  },
);

export const pingAdminsWithMedia = createAsyncThunk(
  'influencer/pingAdminsWithMedia',
  async (requestData, { getState }) => {
    const { fileId, mediaType, notify } = requestData;
    const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
    return await influencerService.pingAdminsWithMedia(selectedInfluencerId, fileId, mediaType, notify);
  },
);

export const sendInstantMassDM = createAsyncThunk('influencer/sendInstantMassDM', async (requestData, { getState }) => {
  const selectedInfluencerId = getState().influencerInfo.selectedInfluencerInfo.influencer.id;
  return await influencerService.sendInstantMassDM(selectedInfluencerId, requestData);
});
