import React, { useEffect, useState } from 'react';
import FormModal from '../../utils/modals/FormModal/FormModal';
import { useDispatch, useSelector } from 'react-redux';
import { notifyError } from '../../../utils/notify';
import { CaptionCard, Container, NoCaptionsText } from './InfluencerFactScoresModal.styles';
import MainLoader from '../../utils/loaders/MainLoader/MainLoader';
import { selectTestInfluencerFactRequestPending } from '../../../store/slices/influencerFact/slice';
import { testInfluencerFactRequest } from '../../../store/slices/influencerFact/asyncThunks';

const InfluencerFactScoresModal = ({ modalRef, influencerFactRequestId, setSelectedInfluencerFactRequestId }) => {
  const dispatch = useDispatch();
  const testInfluencerFactRequestPending = useSelector(selectTestInfluencerFactRequestPending);
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    if (influencerFactRequestId) {

      dispatch(testInfluencerFactRequest({ influencerFactRequestId: influencerFactRequestId }))
        .unwrap()
        .then((payload) => {
          setMatches(payload.matches);
        })
        .catch(err => {
          notifyError(err.message);
        });
    }
  }, [influencerFactRequestId, dispatch]);

  return (
    <FormModal
      ref={modalRef}
      title="Fact scores"
      onClose={() => {
        modalRef.current.hide();
      }}
      onAfterClose={() => {
        setSelectedInfluencerFactRequestId(null);
        setMatches([]);
      }}
      leftButtonText="Cancel"
      onLeftButtonClick={() => {
        modalRef.current.hide();
      }}
      shouldCloseOnOverlayClick={false}>
      <Container>
        {testInfluencerFactRequestPending ? (
          <MainLoader />
        ) : !!matches.length ? (
          matches.map((match, index) => (
            <CaptionCard
              key={index}
              ><b>{match.score.toFixed(4)}</b> - {match.text}
            </CaptionCard>
          ))
        ) : (
          <NoCaptionsText>No fact matches found.</NoCaptionsText>
        )}
      </Container>
    </FormModal>
  );
};

export default InfluencerFactScoresModal;
