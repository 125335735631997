import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import {
  addInfluencerToAgencyBuilder,
  createAgencyBuilder,
  createAgencyMemberBuilder,
  deleteAgencyBuilder,
  deleteAgencyMemberBuilder,
  deleteInfluencerFromAgencyBuilder,
  fetchAgenciesBuilder,
  fetchAgencyByIdBuilder,
  fetchAllInfluencersBuilder,
} from './builder';

const INIT_STATE = {
  fetchAgenciesInfo: {
    pending: false,
    data: [],
    total: 0,
  },
  agency: null,
  influencerOptions: [],
  pending: {
    fetchAgencyById: false,
    createAgency: false,
    deleteAgency: false,
    addInfluencerToAgency: false,
    deleteInfluencerFromAgency: false,
    createAgencyMember: false,
    deleteAgencyMember: false,
  },
};

export const agencySlice = createSlice({
  name: 'agency',
  initialState: INIT_STATE,
  reducers: {
    setAgency: (state, action) => {
      state.agency = action.payload;
    },
  },
  extraReducers: builder => {
    fetchAgenciesBuilder(builder);
    fetchAgencyByIdBuilder(builder);
    createAgencyBuilder(builder);
    deleteAgencyBuilder(builder);
    addInfluencerToAgencyBuilder(builder);
    deleteInfluencerFromAgencyBuilder(builder);
    createAgencyMemberBuilder(builder);
    deleteAgencyMemberBuilder(builder);
    fetchAllInfluencersBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const { setAgency } = agencySlice.actions;

export const selectFetchAgenciesInfo = state => state.agencyInfo.fetchAgenciesInfo;
export const selectAgency = state => state.agencyInfo.agency;
export const selectInfluencerOptions = state => state.agencyInfo.influencerOptions;
export const selectFetchAgencyByIdPending = state => state.agencyInfo.pending.fetchAgencyById;
export const selectCreateAgencyPending = state => state.agencyInfo.pending.createAgency;
export const selectDeleteAgencyPending = state => state.agencyInfo.pending.deleteAgency;
export const selectAddInfluencerToAgencyPending = state => state.agencyInfo.pending.addInfluencerToAgency;
export const selectDeleteInfluencerFromAgencyPending = state => state.agencyInfo.pending.deleteInfluencerFromAgency;
export const selectCreateAgencyMemberPending = state => state.agencyInfo.pending.createAgencyMember;
export const selectDeleteAgencyMemberPending = state => state.agencyInfo.pending.deleteAgencyMember;

export default agencySlice.reducer;
