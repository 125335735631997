import React, { useState } from 'react';
import { AttachedMediaWrapper, CostOverlay } from '../UserInfluencerDetailsPage.styles';
import SpinnerGIF from '../../../../assets/images/spinner.gif';
import { AttachedPhoto, LoaderContainer, Spinner } from './PhotoDisplay.styles';

const PhotoDisplay = ({ id, src, cost }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <AttachedMediaWrapper id={id}>
      {!loaded && (
        <LoaderContainer>
          <Spinner src={SpinnerGIF} />
        </LoaderContainer>
      )}
      <AttachedPhoto
        src={src}
        onLoad={() => setLoaded(true)}
        style={{ display: loaded ? 'block' : 'none' }} // Toggle visibility
      />

      {cost === 0 ? <CostOverlay>FREE</CostOverlay> : <CostOverlay>{cost} ⭐️</CostOverlay>}
    </AttachedMediaWrapper>
  );
};

export default PhotoDisplay;
