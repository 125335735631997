import styled from 'styled-components';

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  letter-spacing: 0px;
  font-size: 1.6rem;
  opacity: ${props => (props.disabled ? 0.8 : 1)};
  width: fit-content;
`;

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: fit-content;
  > svg {
    min-width: 2.4rem;
  }
`;

export const StyledHiddenInput = styled.input`
  visibility: hidden;
  width: 0;
  height: 0;
`;

export const CheckBoxContent = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  align-self: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-left: 0.5rem;

  > a {
    color: #71c8c6;
    margin: 0 0.3rem;
  }
`;
