import styled from 'styled-components';

export const BundleContainer = styled.div`
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: ${({ $columns }) => `repeat(${$columns}, 1fr)`}; /* Dynamically set columns */
  gap: 0.5rem;
  max-width: 50%;
  border-radius: 0.8rem;
  min-height: ${({ rows }) => rows * 40}rem;
`;

export const BundleItem = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1; /* Ensures each media item is square */
  overflow: hidden;
  border-radius: 0.5rem;
  height: 100%;
`;

export const BundlePhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures media fits nicely */
  border-radius: 0.5rem; /* Rounded corners for aesthetics */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px; /* Subtle shadow */
  border: 1px solid #e0e0e0;
`;

export const BundleVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures media fits nicely */
  border-radius: 0.8rem; /* Rounded corners for aesthetics */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px; /* Subtle shadow */
  border: 1px solid #e0e0e0;
`;
