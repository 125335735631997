import { createAsyncThunk } from '@reduxjs/toolkit';
import withdrawalService from '../../../api/services/withdrawal';

export const fetchWithdrawals = createAsyncThunk('withdrawal/fetchWithdrawals', async ({ page, search }) => {
  return await withdrawalService.fetchWithdrawals(page, search);
});

export const createWithdrawal = createAsyncThunk('withdrawal/createWithdrawal', async ({ influencerId, amount }) => {
  return await withdrawalService.createWithdrawal(influencerId, amount);
});

export const fetchAvailableAmountsForWithdrawal = createAsyncThunk(
  'withdrawal/fetchAvailableAmountsForWithdrawal',
  async () => {
    return await withdrawalService.fetchAvailableAmountsForWithdrawal();
  },
);

export const declineWithdrawal = createAsyncThunk('withdrawal/declineWithdrawal', async ({ withdrawalId }) => {
  return await withdrawalService.declineWithdrawal(withdrawalId);
});

export const acceptWithdrawal = createAsyncThunk('withdrawal/acceptWithdrawal', async ({ withdrawalId }) => {
  return await withdrawalService.acceptWithdrawal(withdrawalId);
});
